import { AuthTokenReceivedData } from '@partoohub/api-client';
import { useMutation } from 'react-query';

import { authTokenApiClient } from 'app/api/v2/api_calls/apiResources';

/**
 * Used to login with JWT, only called from IFRAME
 * ⚠️ Will create a session cookie
 */
export const useJwtLogin = (
    onSuccess: (result: AuthTokenReceivedData) => void = () => undefined,
    onError: () => void = () => undefined,
) => {
    return useMutation(
        (headers: Record<string, any> = {}) => authTokenApiClient.getAuthToken(headers),
        {
            onSuccess,
            onError,
        },
    );
};
