import styled from '@emotion/styled';

export const TCModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 468px;
    padding: 24px;
    text-align: center;
`;

export const WarningImage = styled.img`
    width: 320px;
    margin-bottom: 16px;
`;

export const ModalTitle = styled.div`
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 8px;
`;

export const ModalDescription = styled.a`
    color: ${({ theme }) => theme.colors.secondary.initial};
    margin-bottom: 24px;

    &:hover {
        color: ${({ theme }) => theme.colors.secondary.initial};
    }
`;

export const ModalButtons = styled.div`
    display: flex;
    width: 100%;

    > * {
        flex-basis: 100%;
    }
`;
