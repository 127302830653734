import { ANALYTICS_REVIEW_PATH } from 'app/routing/routeIds';

import applyDataFiltersSaga from './applyDataFilters';
import applyEvolutionFiltersSaga from './applyEvolutionFilters';
import searchReviewsEvolutionSaga from './getReviewsEvolution';
import getAverageGradeSaga from './getReviewStats';
import initialReviewAnalyticsDataLoadSaga from './initialReviewAnalyticsDataLoad';

const pageSagas = {
    [ANALYTICS_REVIEW_PATH]: [
        applyDataFiltersSaga,
        applyEvolutionFiltersSaga,
        searchReviewsEvolutionSaga,
        getAverageGradeSaga,
        initialReviewAnalyticsDataLoadSaga,
    ],
};

export default pageSagas;
