import { Outlet, useLocation } from 'react-router';

import EnvBanner from 'app/common/components/EnvBanner';
import { SidebarWithContext } from 'app/common/components/SidebarWithContext';
import IS_IFRAME from 'app/common/data/iframe';
import { useRealtimeUpdates } from 'app/common/hooks/useRealtimeUpdates';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';
import { CONVERSATIONS_MESSAGING_PATH } from 'app/routing/routeIds';

import { OffsetContainer, OutletContainer } from './Layout.styled';

export const Layout = () => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);
    const { pathname } = useLocation();
    const isMessagingPage = pathname.startsWith(CONVERSATIONS_MESSAGING_PATH);
    useRealtimeUpdates();

    return (
        <>
            {!IS_IFRAME && <SidebarWithContext />}
            <OffsetContainer
                isBelowTablet={isBelowProvidedDevices}
                isMessagingPage={isMessagingPage}
            >
                <EnvBanner />
                <OutletContainer isMessagingPage={isMessagingPage} id="page-content">
                    <Outlet />
                </OutletContainer>
            </OffsetContainer>
        </>
    );
};
