import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const SsoSignInContainer = styled.div`
    display: flex;
    height: 56px;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: ${HEX_COLORS.white};
    font-weight: bold;
    border-radius: 8px;
    margin-bottom: 16px;
    font-size: 18px;
    letter-spacing: inherit;
    padding-right: 25px;
    text-align: center;

    > img {
        margin-right: auto;
    }

    > span {
        margin-right: auto;
    }

    color: ${({ theme }) => theme.colors.primary.initial};
    border: solid 1px ${({ theme }) => theme.colors.primary.initial};
    padding-left: 25px;

    > i {
        margin-right: auto;
    }

    &:hover {
        cursor: pointer;
    }
`;
