import React, { FC, ReactNode, useContext, useMemo, useState } from 'react';

interface LoginContextType {
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    email: string;
    setEmail: (email: string) => void;
    password: string;
    setPassword: (password: string) => void;
    passwordModal: boolean;
    setPasswordModal: (passwordModal: boolean) => void;
}

export const LoginContext = React.createContext<LoginContextType>({
    isLoading: false,
    setIsLoading: () => undefined,
    email: '',
    setEmail: () => undefined,
    password: '',
    setPassword: () => undefined,
    passwordModal: false,
    setPasswordModal: () => undefined,
});

interface Props {
    children: ReactNode;
}

/**
 * Provider to handle hardcoded groups data
 */
export const LoginProvider: FC<Props> = ({ children }) => {
    // This isLogin is used to check if the user is logged in or not
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordModal, setPasswordModal] = useState<boolean>(false);

    const value = useMemo(
        () => ({
            isLoading,
            setIsLoading,
            email,
            setEmail,
            password,
            setPassword,
            passwordModal,
            setPasswordModal,
        }),
        [
            isLoading,
            setIsLoading,
            email,
            setEmail,
            password,
            setPassword,
            passwordModal,
            setPasswordModal,
        ],
    );

    return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>;
};

export const useLoginContext = () => useContext(LoginContext);
