import { UserData, V2OrgData } from '@partoohub/api-client';

import { ENTERPRISE } from 'app/common/reducers/orgs';
import env from 'app/common/services/getEnvironment';
import { isScriptLoaded, loadScript, removeScript } from 'app/common/services/scripts/handleScript';
import { STATIC_BASE_URL } from 'app/config';

const GOOGLE_TAG_MANAGER_SCRIPT_ID = 'partooGTMScriptId';
const GOOGLE_TAG_MANAGER_NOSCRIPT_ID = 'partooGTMNoScriptId';

const loadGTMIFrame = () => {
    const noScript = document.createElement('noscript');
    const iFrame = document.createElement('iframe');
    iFrame.id = 'partooGtmIframeId';
    iFrame.src = 'https://www.googletagmanager.com/ns.html?id=GTM-P22H5X';
    iFrame.height = '0';
    iFrame.width = '0';
    iFrame.style.display = 'none';
    iFrame.style.visibility = 'hidden';
    noScript.id = GOOGLE_TAG_MANAGER_NOSCRIPT_ID;
    noScript.appendChild(iFrame);
    window.document.head.prepend(noScript);
};

const provideUserInfo = (user: UserData, organization: V2OrgData): void => {
    const utmSource = new URLSearchParams(window.location.search).get('utm_source');

    window.dataLayer = [];
    const isEnterprise = organization.organization_type === ENTERPRISE;
    const isEnterpriseStr = isEnterprise.toString();

    // Provide user info to Google Tag Manager by global dataLayer object.
    window.dataLayer.push({
        userId: user.id,
        is_enterprise: isEnterpriseStr.charAt(0).toUpperCase() + isEnterpriseStr.slice(1),
        provider: user.provider,
        role: user.role,
        org_id: user.org_id.toString(),
        shadow_user_id: user.shadow_user_id || 'NULL',
        app_type: 'web_app',
        language: user.lang,
        utm_source: utmSource || '',
    });
};

const loadGoogleTagManagerScript = (user: UserData, organization: V2OrgData) => {
    if (!env.isDev() && !isScriptLoaded(GOOGLE_TAG_MANAGER_SCRIPT_ID)) {
        provideUserInfo(user, organization);
        loadScript(
            `${STATIC_BASE_URL}/javascript/3p/partoo-gtm.js`,
            GOOGLE_TAG_MANAGER_SCRIPT_ID,
            loadGTMIFrame,
        );
    }
};

export const handleGoogleTagManager = (me: UserData, organization: V2OrgData) => {
    return new Promise((resolve, reject) => {
        try {
            loadGoogleTagManagerScript(me, organization);
            resolve('[Google Tag Manager] Script loaded successfully');
        } catch (error) {
            reject(new Error('[Google Tag Manager] Error loading the script'));
        }
    });
};

export const removeGoogleTagManagerScript = () => {
    window.dataLayer = [];
    removeScript(GOOGLE_TAG_MANAGER_SCRIPT_ID);
    removeScript(GOOGLE_TAG_MANAGER_NOSCRIPT_ID);
};
