import { useMutation } from 'react-query';

import { LoginData, LoginHeaders, LoginWithTokenData } from 'app/api/types/connection';
import { connectionApiClient } from 'app/api/v2/api_calls/apiResources';

type Props = {
    params?: LoginData | LoginWithTokenData;
    headers?: LoginHeaders;
};

/**
 * Used to login the user (with email and password, token or google id_token).
 * ⚠️ Will create a session cookie
 */
export const useLogin = (
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) => {
    return useMutation(
        ({ params = {}, headers = {} }: Props) => connectionApiClient.login(params, headers),
        {
            onSuccess,
            onError,
        },
    );
};
