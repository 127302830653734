import { useState } from 'react';

import { Button, Stack } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { PasswordChecks } from 'app/common/components/PasswordChecks/PasswordChecks';
import { PasswordField } from 'app/common/designSystem/components/atoms/PasswordField/PasswordField';
import isPasswordValid, { passWordChecker } from 'app/common/services/passwordChecker';
import { LoginFormContainer, LoginFormMessage } from 'app/pages/Login/forms/loginForm.styled';

import { useCompleteInvitation } from 'app/pages/Login/hooks/mutations/useCompleteInvitation';
import { useLoginRedirect } from 'app/pages/Login/hooks/useLoginRedirect';
import { PATH_PARAM_TOKEN } from 'app/routing/routeIds';

export const Invitation = () => {
    const { t } = useTranslation();

    const token = useParams()[PATH_PARAM_TOKEN] ?? '';
    const loginRedirect = useLoginRedirect();

    const completeInvitation = useCompleteInvitation(() => {
        loginRedirect();
    });

    const [showPasswordChecker, setShowPasswordChecker] = useState<boolean>(false);

    const [password, setPassword] = useState<string>('');
    const [confirmationPassword, setConfirmationPassword] = useState<string>('');

    const disabled = !(password === confirmationPassword && isPasswordValid(password));

    const errorPassword = completeInvitation.isError;
    const pwdNotEquals = confirmationPassword.length > 5 && confirmationPassword !== password;
    const errorConfirmValue = pwdNotEquals || errorPassword;

    // @ts-expect-error
    const passwordError = completeInvitation.error?.response?.data?.errors?.json?.password;
    // @ts-expect-error
    const globalErrors = completeInvitation.error?.response?.data?.errors?.json;

    const noticeMessage = t(
        pwdNotEquals
            ? 'password and its confirmation are not equal'
            : passwordError || globalErrors,
    );

    const variant = disabled ? 'secondary' : 'primary';

    const handleSubmit = () => {
        completeInvitation.mutate({
            token,
            password,
            password_confirmation: confirmationPassword,
        });
    };

    return (
        <LoginFormContainer>
            <LoginFormMessage>
                <i className="fa-solid fa-info-circle" />
                {t('invitation__explanation')}
            </LoginFormMessage>
            <Stack>
                <Stack gap={showPasswordChecker ? '16px' : '0px'}>
                    <PasswordField
                        dataTrackId="password"
                        label={t('password')}
                        value={password}
                        onChange={setPassword}
                        onFocus={() => setShowPasswordChecker(true)}
                        onBlur={() => setShowPasswordChecker(false)}
                        error={errorPassword}
                    />
                    <PasswordChecks
                        displayChecker={showPasswordChecker}
                        {...passWordChecker(password ?? '')}
                    />
                </Stack>
                <PasswordField
                    dataTrackId="confirm_password"
                    label={t('confirm_password')}
                    value={confirmationPassword}
                    onChange={setConfirmationPassword}
                    error={errorConfirmValue}
                    notice={noticeMessage}
                />
                <Button
                    variant={variant}
                    shape="cube"
                    size="large"
                    full
                    onClick={handleSubmit}
                    dataTrackId="invitation__submit_button"
                    type="submit"
                    disabled={variant === 'secondary'}
                >
                    {t('create_an_account')}
                </Button>
            </Stack>
        </LoginFormContainer>
    );
};
