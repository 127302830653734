import { put, select, takeEvery } from 'redux-saga/effects';

import { testPath } from 'app/common/data/routeIds';
import { Saga } from 'app/common/types';
import { ANALYTICS_REVIEW_PATH } from 'app/routing/routeIds';
import { locationSelector } from 'app/states/router';

import updateBucketSaga from './updateBucketSaga';
import {
    GET_REVIEWS_PERIOD_TOTAL_REQUEST,
    UPDATE_IS_QUALITATIVE,
    UPDATE_REVIEWS_EVOLUTION_BUCKET,
    getReviewsEvolutionRequestAction,
    resetEvolutionPeriodAnalyticsAction,
    resetEvolutionTotalAnalyticsAction,
} from '../actions';
import { ReviewAnalyticsState } from '../reducers';
import {
    isPeriodSelector,
    isQualitativeSelector,
    reviewAnalyticsSelector,
} from '../selectors';

type ActionType =
    | typeof UPDATE_IS_QUALITATIVE
    | typeof GET_REVIEWS_PERIOD_TOTAL_REQUEST
    | typeof UPDATE_REVIEWS_EVOLUTION_BUCKET;

export function* applyEvolutionFilters({ type }: { type: ActionType }): Saga {
    const { pathname } = yield select(locationSelector);

    if (testPath(ANALYTICS_REVIEW_PATH, pathname)) {
        // if the bucket has changed, we reset all evolution values
        if (type === UPDATE_REVIEWS_EVOLUTION_BUCKET) {
            yield put(resetEvolutionTotalAnalyticsAction());
            yield put(resetEvolutionPeriodAnalyticsAction());
        }

        const reviewAnalyticsState = yield select(
            reviewAnalyticsSelector,
        ) as any as ReviewAnalyticsState;

        // evolution period button selected
        const isPeriod = isPeriodSelector(reviewAnalyticsState);

        // evolution average selected
        const isQualitative = isQualitativeSelector(reviewAnalyticsState);

        // we re-evaluate the seasonality if the evolution period has changed
        if (type === GET_REVIEWS_PERIOD_TOTAL_REQUEST) {
            yield* updateBucketSaga();
            return;
        }

        yield put(getReviewsEvolutionRequestAction(isQualitative, isPeriod));
    }
}

function* applyEvolutionFiltersSaga(): Saga {
    yield takeEvery(UPDATE_IS_QUALITATIVE, applyEvolutionFilters);
    yield takeEvery(GET_REVIEWS_PERIOD_TOTAL_REQUEST, applyEvolutionFilters);
    yield takeEvery(UPDATE_REVIEWS_EVOLUTION_BUCKET, applyEvolutionFilters);
}

export default applyEvolutionFiltersSaga;
