import { LoginHeaders } from 'app/api/types/connection';
import { useLogin } from 'app/pages/Login/hooks/mutations/useLogin';
import { useLoginContext } from 'app/pages/Login/hooks/useLoginContext';
import { useLoginRedirect } from 'app/pages/Login/hooks/useLoginRedirect';

export const useApiKeyConnexionTokenLoginMethod = (headers: LoginHeaders) => {
    const { setIsLoading } = useLoginContext();
    const loginRedirect = useLoginRedirect();

    const login = useLogin(loginRedirect, () => {
        setIsLoading(false);
    });

    return () => {
        setIsLoading(true);
        login.mutate({
            headers,
        });
    };
};
