import { useEffect } from 'react';

import { useMe } from 'app/pages/app/AppDataProvider/hooks/useMe';
import { useMyOrganization } from 'app/pages/app/AppDataProvider/hooks/useMyOrganization';

import { handleGoogleMap, removeGoogleMapScript } from './handleGoogleMap';
import { handleGoogleTagManager, removeGoogleTagManagerScript } from './handleGoogleTagManager';
import { handleIntercomPostLogin, removeIntercomPostLogin } from './handleIntercomPostLogin';
import { removeSurvicateScript, useHandleSurvicate } from './handleSurvicate';
import { handleWhatsappEmbeddedSignup } from './handleWhatsappEmbeddedSignupScript';

const useSetupPostLoginScripts = () => {
    const me = useMe();
    const org = useMyOrganization();

    const handleSurvicate = useHandleSurvicate(me, org);

    return () =>
        Promise.allSettled([
            handleGoogleMap(),
            handleWhatsappEmbeddedSignup(),
            handleSurvicate(),
            handleGoogleTagManager(me, org),
            handleIntercomPostLogin(me),
        ]);
};

const useRemovePostLoginScripts = () => {
    return () => {
        removeGoogleMapScript();
        removeSurvicateScript();
        removeGoogleTagManagerScript();
        removeIntercomPostLogin();
    };
};

export const useHandlePostLoginScripts = () => {
    const setupPostLoginScripts = useSetupPostLoginScripts();
    const removePostLoginScripts = useRemovePostLoginScripts();

    // Handle post login scripts
    useEffect(() => {
        setupPostLoginScripts();
        return () => {
            removePostLoginScripts();
        };
    }, []);
};
