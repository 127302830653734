import { useEffect, useRef } from 'react';

import { MainPublisherEnum, Publisher } from '@partoohub/iconography';
import { Button, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import env from 'app/common/services/getEnvironment';
import { STATIC_BASE_URL } from 'app/config';
import { useLogin } from 'app/pages/Login/hooks/mutations/useLogin';
import { useLoginContext } from 'app/pages/Login/hooks/useLoginContext';
import { useLoginRedirect } from 'app/pages/Login/hooks/useLoginRedirect';
import { LoginQueryKeys } from 'app/pages/Login/utils/enums';

import {
    GoogleSigninButton,
    GoogleSigninButtonContainer,
    GoogleSigninError,
    OrSeparator,
} from './GoogleSigninButton.styled';

const CLIENT_ID = '583584673263-n1gcbk83m86i3mg25lafb5pa4cdp6bii.apps.googleusercontent.com';
const GOOGLE_LOGIN_PROXY_URI = 'https://sso-proxy.slates.partoo.co/oauth2/callback';

export const GoogleSignInButton = () => {
    const { t } = useTranslation();

    const { setIsLoading } = useLoginContext();
    const [googleIdToken] = useStateQueryParams(LoginQueryKeys.GOOGLE_ID_TOKEN);

    const loginRedirect = useLoginRedirect();
    const login = useLogin(loginRedirect, () => {
        setIsLoading(false);
    });

    const googleSigninRef = useRef<HTMLDivElement>(null);

    const googleLogin = (id_token: string) => {
        setIsLoading(true);
        login.mutate({
            params: {
                id_token,
            },
        });
    };

    // Handle Google autologin if Google token is in url, only used in slate envs
    useEffect(() => {
        if (googleIdToken) {
            googleLogin(googleIdToken);
        }
    }, []);

    // Handle Google callback, called everywhere but slate envs
    const handleCallbackResponse = (response: any) => {
        if (!response?.credential) return;
        googleLogin(response.credential);
    };

    useEffect(() => {
        // If in slate env, we need to redirect to the proxy to handle the Google login
        const envSpecificGoogleConfig = env.isSlate()
            ? {
                  login_uri: GOOGLE_LOGIN_PROXY_URI,
                  state: JSON.stringify({
                      next_url: window.location.href,
                  }),
                  ux_mode: 'redirect',
              }
            : {
                  callback: handleCallbackResponse,
                  prompt: true,
              };

        if (window.google?.accounts?.id) {
            window.google.accounts.id.initialize({
                client_id: CLIENT_ID,
                ...envSpecificGoogleConfig,
            });
        }
    }, [window.google?.accounts?.id]);

    useEffect(() => {
        if (googleSigninRef.current && window.google?.accounts?.id) {
            window.google.accounts.id.renderButton(googleSigninRef.current, {
                theme: 'outline',
                size: 'large',
                width: '400px', // Max width allowed by Google
            });
        }
    }, [googleSigninRef, window.google?.accounts?.id]);

    if (!window.google?.accounts?.id) {
        return null;
    }

    return (
        <div>
            <OrSeparator>
                <Text color="secondary">{t('login_options_or')}</Text>
            </OrSeparator>
            <GoogleSigninButtonContainer>
                <Button
                    dataTrackId="login_google_sign_in_button"
                    variant="theme"
                    size="large"
                    full
                    icon={
                        <Publisher
                            dataTrackId="login_google_sign_in_button_logo"
                            name={MainPublisherEnum.Google}
                            width="16"
                            height="16"
                        />
                    }
                >
                    {t('google_sign_in')}
                </Button>
                <GoogleSigninButton ref={googleSigninRef} />
            </GoogleSigninButtonContainer>
            {login.isError && (
                <GoogleSigninError>
                    <img src={`${STATIC_BASE_URL}/images/common/custom-svg/error.svg`} alt="" />
                    <span>{t('google_sign_in_error')}</span>
                </GoogleSigninError>
            )}
        </div>
    );
};
