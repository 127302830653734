import env from 'app/common/services/getEnvironment';
import { removeScript } from 'app/common/services/scripts/handleScript';

const SCRIPT_ID = 'liveroom';

export const handleLiveroom = () => {
    return new Promise((resolve, reject) => {
        if (env.isSandbox()) {
            const script = document.createElement('script');
            script.id = SCRIPT_ID;
            script.type = 'module';
            script.src =
                'https://cdn.jsdelivr.net/npm/liveroom-client-element@latest/dist/main.min.js';

            window.document.head.appendChild(script);
            script.onload = () => {
                resolve('[Liveroom] Script loaded successfully');
            };

            script.onerror = () => {
                reject(new Error('[Liveroom] Error loading the script'));
            };
        }
        resolve('');
    });
};

export const removeLiveroomScript = () => {
    removeScript(SCRIPT_ID);
};
