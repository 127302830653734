import { useEffect, useState } from 'react';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import env from 'app/common/services/getEnvironment';

const PROVIDER_URL_KEY = 'provider';

export const useProviderFromUrl = () => {
    const [provider, setProvider] = useState('partoo');
    const [providerUrl] = useStateQueryParams(PROVIDER_URL_KEY);

    useEffect(() => {
        const currentDomain = window.location.hostname;

        if (currentDomain.includes('mavisibiliteweb')) {
            setProvider('mediapost');
        } else if (providerUrl && !env.isProd()) {
            setProvider(providerUrl);
        }
    }, []);

    return provider;
};
