import styled from '@emotion/styled';
import { Button } from '@partoohub/ui';

export const ChangePasswordModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
    text-align: center;

    width: 620px;
`;

export const ChangePasswordModalTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    padding: 32px 32px 16px 32px;
`;

export const UpdatePasswordButton = styled(Button)`
    border-radius: 0;
`;
