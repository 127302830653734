import { FC, useEffect, useState } from 'react';

import { Banner } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

import ProviderLogo from 'app/common/components/ProviderLogo/ProviderLogo';
import { DEFAULT_LANGUAGE, getBrowserLang } from 'app/common/services/getLang';
import i18n from 'app/common/translations/i18n';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';
import { isBrowserOutdated } from 'app/pages/Login/utils/isBrowserOutdated';
import { USE_DARK_THEME, useIsDarkTheme, usePartooThemeContext } from 'app/Theme';

import { useIsWhitelistProvider } from './hooks/loginModals/useIsWhitelistProvider';
import { useMobileAppBanner } from './hooks/loginModals/useMobileAppBanner';
import { LoginProvider } from './hooks/useLoginContext';
import { useProviderFromUrl } from './hooks/useProviderFromUrl';
import {
    ConnectionPageBulb,
    ConnectionPageContainer,
    ConnectionPageLeftPart,
    ConnectionPageLeftPartContent,
    ConnectionPageRightPart,
} from './Login.styled';
import { DownloadMobileApp } from './loginModals/DownloadMobileApp';
import { ResponsiveSettingsModal } from './loginModals/ResponsiveSettingsModal';
import { LoginRightPartContent } from './LoginRightPartContent';

export const Login: FC = () => {
    const provider = useProviderFromUrl();
    const isWhiteList = useIsWhitelistProvider();

    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);

    // We have to put this here because both components need the same data
    // useLocalStorage is not sync between components, we need to use a shared state, it needs a refacto
    const [visibleMobileApp, setVisibleMobileApp] = useMobileAppBanner();

    const { toggleTheme } = usePartooThemeContext();
    const isDarkTheme = useIsDarkTheme();

    useEffect(() => {
        const browserLang = getBrowserLang() ?? DEFAULT_LANGUAGE;
        if (i18n.language !== browserLang) {
            i18n.changeLanguage(browserLang);
        }
    }, []);

    const hasRightPart = isWhiteList && !isBelowProvidedDevices;

    return (
        <div>
            <DownloadMobileApp visible={visibleMobileApp} setVisible={setVisibleMobileApp} />
            <ResponsiveSettingsModal visibleMobileApp={visibleMobileApp} />

            {USE_DARK_THEME && (
                <ConnectionPageBulb
                    aria-label="Toggle Theme"
                    icon={['fa-lightbulb']}
                    dataTrackId="toggle_theme_button"
                    onClick={toggleTheme}
                    appearance="outlined"
                    isDarkTheme={isDarkTheme}
                >
                    Toggle Theme
                </ConnectionPageBulb>
            )}

            <ConnectionPageContainer>
                <ConnectionPageLeftPart hasBackgroundColor={!hasRightPart}>
                    <ConnectionPageLeftPartContent>
                        <ProviderLogo providerLabel={provider} />
                        <BrowserOutdatedBanner />

                        {/* Shared context between all the login pages */}
                        <LoginProvider>
                            {/* Used to render the nested routes */}
                            <Outlet />
                        </LoginProvider>
                    </ConnectionPageLeftPartContent>
                </ConnectionPageLeftPart>

                {hasRightPart && (
                    <ConnectionPageRightPart>
                        <LoginRightPartContent />
                    </ConnectionPageRightPart>
                )}
            </ConnectionPageContainer>
        </div>
    );
};

const BrowserOutdatedBanner = () => {
    const { t } = useTranslation();
    const [isShown, setIsShown] = useState<boolean>(isBrowserOutdated());

    if (!isShown) return null;

    const onClose = () => {
        setIsShown(false);
    };

    return (
        <Banner dataTrackId="browser_outdated_banner" variant="warning" onClose={onClose}>
            {t('warning_update_your_browser')}
        </Banner>
    );
};
