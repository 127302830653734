import { useEffect } from 'react';

import getLang from 'app/common/services/getLang';
import {
    handleLiveroom,
    removeLiveroomScript,
} from 'app/common/services/scripts/preLogin/handleLiveroom';

import { handleIntercomPreLogin, removeIntercomScript } from './handleIntercomPreLogin';

export const setupPreLoginScripts = async () => {
    document.documentElement.lang = getLang();
    document.documentElement.dir = 'ltr';

    return await Promise.allSettled([handleIntercomPreLogin(), handleLiveroom()]);
};

export const removePreLoginScripts = () => {
    removeIntercomScript();
    removeLiveroomScript();
};

export const useHandlePreLoginScripts = () => {
    return useEffect(() => {
        const loadScripts = async () => {
            const results = await setupPreLoginScripts();
            results.forEach(result => {
                if (result.status === 'rejected') {
                    console.error(result.reason.message);
                } else {
                    result?.value && console.log(result.value);
                }
            });
        };

        loadScripts();

        return () => {
            removePreLoginScripts();
        };
    }, []);
};
