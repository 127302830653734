import { useMutation } from 'react-query';

import { ConnectionLogoutData } from 'app/api/types/connection';
import api from 'app/api/v2/api_calls';

/**
 * Destroy the session cookie
 */
export const useLogout = (
    onSuccess: (data: ConnectionLogoutData) => void = () => undefined,
    onError: () => void = () => undefined,
) => {
    return useMutation(() => api.connection.logout(), {
        onSuccess,
        onError,
    });
};
