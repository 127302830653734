import { Button } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { STATIC_BASE_URL } from 'app/config';
import {
    LoginFormContainer,
    LoginFormSuccessImage,
    LoginFormSuccessMessage,
} from 'app/pages/Login/forms/loginForm.styled';
import { CONNECTION_PATH } from 'app/routing/routeIds';

export const ResetPasswordSuccess = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToConnection = () => {
        navigate(CONNECTION_PATH);
    };

    return (
        <LoginFormContainer>
            <LoginFormSuccessImage src={`${STATIC_BASE_URL}/images/app/success.svg`} alt="" />
            <LoginFormSuccessMessage>
                <img src={`${STATIC_BASE_URL}/images/common/custom-svg/success.svg`} alt="" />
                {t('reset_password_success')}
            </LoginFormSuccessMessage>

            <Button
                variant="primary"
                shape="cube"
                size="large"
                full
                onClick={navigateToConnection}
                dataTrackId="reset_password_success_button"
                type="submit"
            >
                {t('connection_to_app')}
            </Button>
        </LoginFormContainer>
    );
};
