import { useMutation } from 'react-query';

import { PasswordData } from 'app/api/types/connection';
import api from 'app/api/v2/api_calls';

/**
 * Used to reset the password of a user.
 */
export const useResetPassword = (
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) => {
    return useMutation((params: PasswordData) => api.connection.resetPassword(params), {
        onSuccess,
        onError,
    });
};
