import { UserData, V2OrgData } from '@partoohub/api-client';
import {
    getSurvicateInstance,
    initSurvicate,
} from '@survicate/survicate-web-surveys-wrapper/widget_wrapper';

import { differenceInDays } from 'date-fns';

import { NPS_WHITELIST_PROVIDERS } from 'app/common/data/providers';
import { useHasMessagePage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasMessagePage';
import { useHasDiffusionPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasDiffusionPage';
import { useHasReviewInvitePage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewInvitePage';
import { useHasReviewManagementPage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewManagementPage';
import env from 'app/common/services/getEnvironment';

export const useHandleSurvicate = (me: UserData, organization: V2OrgData) => {
    const hasPresenceManagement = useHasDiffusionPage();
    const hasReviewManagement = useHasReviewManagementPage();
    const hasReviewBooster = useHasReviewInvitePage();
    const hasBusinessMessages = useHasMessagePage();

    return async () => {
        try {
            if (!NPS_WHITELIST_PROVIDERS.includes(me.provider)) return;

            const key = '6f1c1a6b82242e68067aaaf9407aebc1';
            const createdAt = (me.created_at && new Date(me.created_at * 1000)) || null;
            const subscriptionDate = createdAt?.toISOString().split('T')[0] ?? '';
            const daysSinceSub = (createdAt && differenceInDays(new Date(), createdAt)) || 0;

            const traits = {
                env: env.getEnv(),
                userId: me.id,
                email: me.email,
                name: `${me.first_name} ${me.last_name}`,
                orgType: organization.organization_type,
                role: me.role,
                hasPresenceManagement,
                hasReviewManagement,
                hasReviewBooster,
                hasBusinessMessages,
                subscriptionDate: subscriptionDate,
                daysSinceSubscription: String(daysSinceSub),
                language: me.lang,
                isShadowUser: !!me.shadow_user_id,
            };

            // The traits object is not typed correctly in survicate.
            // It does allows Record<string, string|boolean|number> but wrongly declares only Record<string, string>.
            await initSurvicate({
                workspaceKey: key,
                traits: traits as unknown as Record<string, string>,
            });
            console.log('[Survicate] Initialized.');
        } catch (error) {
            console.error('[Survicate] Error initializing.', error);
        }
    };
};

export const removeSurvicateScript = (): void => {
    const survicate = getSurvicateInstance();
    if (survicate) survicate.destroyVisitor();
};
