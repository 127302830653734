import axios from 'axios';

/**
 * Inject JWT token for all requests made with Axios
 * @param token {string} JWT token to inject into headers
 */
class JwtToken {
    static injectJwtToken(token: string) {
        axios.defaults.headers.common.Authorization = `bearer ${token}`;
    }
}

export default JwtToken;
