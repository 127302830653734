import { StyledContent } from 'app/pages/privacyPolicy/PrivacyPolicy.styled';
import { MessagingPrivacyPolicyData } from 'app/pages/privacyPolicy/types';

type Props = {
    data: MessagingPrivacyPolicyData;
};

const PrivacyPolicyTemplateEn = ({ data }: Props) => (
    <StyledContent>
        <h1>
            <strong>PRIVACY POLICY</strong>
        </h1>
        <br />
        Personal data protection and respect for privacy is of utmost importance to us. The aim of
        this Privacy Policy is to inform you of the way in which we collect, use and transmit your
        personal data when you communicate with us via the Chat (the « <strong>Chat</strong> »), in
        accordance with the regulations in force in France, such as those pursuant to Law no. 78-17
        of 6 January 1978 relating to IT, files and freedoms as amended (« <strong>LIL</strong> »)
        and EU Regulation 16/679 of 27 April 2016 on personal data protection («{' '}
        <strong>RGPD</strong> »).
        <br />
        <br /> As a reminder, the use of your Personal Data is also governed by the Privacy Policy
        of the Chat used (example for Google:{' '}
        <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
        ).
        <br />
        <br /> Any amendment or update to this Privacy Policy will be brought to your attention.
        <br />
        <br />
        <h3>
            <strong>1. Definitions </strong>
        </h3>
        « <strong>Chat</strong> » means the instant messaging service you use to communicate with
        us.
        <br />
        <br /> « <strong>Data</strong> » means any information provided by the User to the Company
        via the Chat, including Personal Data.
        <br />
        <br /> « <strong>Personal Data</strong> » means any information about a natural person,
        either identified or identifiable, directly or indirectly, by reference to an identification
        number or one or more factors specific to that person.
        <br />
        <br /> « <strong>Sensitive Personal Data</strong> » means any information about race or
        ethnic origin, political, philosophical or religious opinions, trade union membership,
        health or sex life as well as genetic or biometric data. In principle, the processing of
        Sensitive Data is prohibited unless the data subject has given their express consent.
        <br />
        <br /> « <strong>Data Controller</strong> » means the natural or legal person who determines
        the purposes and means of Processing the data, in this case, the Company.
        <br />
        <br /> « <strong>Data Processor</strong> » means the natural or legal person who processes
        Personal Data on our behalf and in accordance with our instructions, when using the Chat.
        <br />
        <br /> « <strong>Processing</strong> » means any operation, or set of operations, involving
        Personal Data, regardless of the procedure used (collection, recording, organisation,
        retention, adaptation, modification, extraction, consultation, use, communication by
        transmission or dissemination or any other form of provision, matching) performed by the
        Company when using the Chat.
        <br />
        <br /> « <strong>User</strong> » ou « <strong>You/your</strong> » means any natural person
        in contact with the Company via the Chat.
        <br />
        <br />
        <h3>
            <strong>2. Who is the Data Controller for your personal data?</strong>
        </h3>
        The Data Controller, who collects and manages your data, is the company {data.company_name},
        a {data.company_type} company with capital of {data.share_capital} euros, whose registered
        office is located at {data.company_address}, registered on the Trade and Companies Register
        of {data.company_registration_city}, under number {data.company_registration_number} (the «{' '}
        <strong>Company</strong> »), represented by its Chairman.
        <br />
        <br />
        <h3>
            <strong>3. Categories of Personal Data collected</strong>
        </h3>
        When using the Chat, we may collect the following categories of Personal Data:
        <br />
        <ul>
            <li>
                <strong>Civil Status</strong> : Last name, First name,
            </li>
            <li>
                <strong>Image</strong> (your account profile photo for the Chat used).
            </li>
        </ul>
        We may also collect any Personal Data you communicate to us under your own initiative,
        during our discussions via the Chat (order number, postal address and/or telephone number,
        photographs, etc.). Please note that we will never ask you to communicate Sensitive Personal
        Data via the Chat and we strongly recommend that you do not communicate this data
        voluntarily to ensure the utmost security over the information exchanged between us.
        <br />
        <br /> You undertake to provide up-to-date and valid Personal Data and warrant that you have
        not made any false declaration or provided erroneous information. You must not provide any
        Personal Data about third parties via the Chat.
        <br />
        <br />
        <h3>
            <strong>4. Mandatory and optional nature of the Personal Data collected</strong>
        </h3>
        Certain Data is required to process your request. The mandatory nature of the information
        requested is indicated at the time of collection. If you fail to provide this Data, we will
        be unable to respond to your request adequately.
        <br />
        <br /> Any Data not indicated as mandatory is optional. You are free to provide this data or
        not. However, this data may enable us to respond to your request in a more precise manner
        and enable us to improve your experience during our discussions.
        <br />
        <br />
        <h3>
            <strong>5. Purposes of collecting Personal Data </strong>
        </h3>
        The Company collects your Personal Data, when using the Chat, for the following purposes:
        <br />
        <ul>
            <li>Reading messages sent and responding to them in an optimal manner,</li>
            <li>Enabling access to the conversation log between the Company and Users,</li>
            <li>Improving the service we provide via the Chat (decreased response times, etc.).</li>
            <li>Compiling statistics and audience measurement information,</li>
            <li>Combating fraud and ensuring the security of the Data,</li>
            <li>Executing current legal, regulatory and administrative provisions.</li>
        </ul>
        Depending on the case, the Company processes your Personal Data based on one of the
        following legal grounds:
        <br />
        <ul>
            <li>Your prior consent if you have agreed to the processing of your Data,</li>
            <li>Fulfilment of the contract between us, </li>
            <li>Our legal obligations, </li>
            <li>
                Our “legitimate interest” pursuant to the legislation that applies to Personal Data
                protection. In this case, we will take into account your interests and fundamental
                rights to determine if the processing operation is legitimate and lawful.
            </li>
        </ul>
        <br />
        <h3>
            <strong>6. Who has access to your Personal Data?</strong>
        </h3>
        <h4>
            <strong>6.1. Our staff</strong>
        </h4>
        The Personal Data collected is processed by authorised individuals within the Company to
        respond to your requests via the Chat. <br />
        <br />
        <h4>
            <strong>6.2. Our Data Processors</strong>
        </h4>
        When using the Chat, the Company uses Data Processors, who process Personal Data on its
        behalf and in accordance with its instructions. To ensure the security and confidentiality
        of your Data and in accordance with the provisions of the GDPR, we require our Data
        Processors to present sufficient and appropriate technical and organisational guarantees.
        <br />
        <br /> The Company uses Data Processors located in countries that offer an adequate level of
        protection due to the fact that they are covered by the provisions of the GDPR, or in
        accordance with Article 45 thereof, they are covered by an adequacy decision from the
        European Commission. Failing this, the Company undertakes to implement all appropriate
        mechanisms to ensure adequate protection of the Data, including standard contractual clauses
        drawn up by the European Commission.
        <br />
        <br /> You can get more information on the guarantees offered by our Data Processors upon
        request at the following address: {data.dpo_address}
        .<br />
        <br />
        <h4>
            <strong>6.3. Other possible recipients</strong>
        </h4>
        The Company, if necessary, may be required to transfer your Personal Data to organisations,
        third parties, and administrative or legal authorities in the following cases:
        <br />
        <ul>
            <li>To comply with legal and regulatory obligations,</li>
            <li>
                To identify, avoid or deal with fraudulent activities, security flaws or any other
                technical problem,
            </li>
            <li>To assert a legal right.</li>
        </ul>
        Rest assured that the Company will not transfer your Personal Data to third-party partners
        for commercial operations without your prior consent.
        <br />
        <br />
        <h3>
            <strong>7. What is the retention period for your Personal Data?</strong>
        </h3>
        The Company retains your Personal Data, in a form enabling you to be identified, in an
        active database, for the period strictly necessary to achieve the purposes for which the
        data was collected and processed, i.e. a maximum of 1 year from our last discussion via the
        Chat.
        <br />
        <br /> Your Personal Data may also be subject to archiving, involving regulated, limited and
        justified access, for the time required (i) to comply with the Company’s legal, tax,
        accounting and regulatory obligations and/or (ii) to enable us to assert a legal right,
        before being permanently erased.
        <br />
        <br />
        <h3>
            <strong>8. How do we ensure the security and privacy of your Personal Data?</strong>
        </h3>
        The Company undertakes to process your Personal Data in a lawful, fair, transparent,
        proportionate and appropriate manner within the strict framework of the purposes required
        and announced, for the time required for the processing operation, in a secure manner.
        <br />
        <br /> The Company implements and updates appropriate technical and organisational measures
        to ensure the security and privacy of your Personal Data, preventing the data from being
        distorted, destroyed, damaged or disclosed to unauthorised third parties. As such, the
        Company has taken physical, electronic and organisational protection measures to prevent any
        loss, improper use, unauthorised access or distribution, modification or destruction of
        Personal Data. As all Personal Data is confidential, access is restricted to employees,
        service providers and Data Processors of the Company who have a strict need to be aware of
        this data as part of their assignments. All those with access to your Personal Data are
        bound by a confidentiality obligation and risk disciplinary measures and/or sanctions in the
        event of a breach of these obligations.
        <br />
        <br /> However, it is important that you try to prevent any unauthorised access to your
        Personal Data by only disclosing the Data strictly required to process your request via the
        Chat.
        <br />
        <br />
        <h3>
            <strong>9. What rights do you have over your Personal Data?</strong>
        </h3>
        Upon written request, sent to the following address: {data.dpo_address}, you can access your
        Personal Data, request that the data be rectified, restricted or erased, request data
        portability, or request to be removed from the Company’s database.
        <br />
        <br /> Regarding the <strong>right of access</strong>, you are authorised, in accordance
        with Article 15 of the GDPR, to ask us to confirm whether or not your Personal Data is
        subject to a Processing operation, as well as to obtain information about the processing (in
        particular the purposes, categories of Personal Data processed and the recipients to whom
        the Personal Data is disclosed, the Data retention period or the criteria used to determine
        this period, your rights over your Personal Data and the right to lodge a complaint with a
        supervisory authority).
        <br />
        <br />
        In accordance with Article 16 of the GDPR, the <strong>right to rectify</strong> grants you
        the right to require us to rectify, complete or update your Personal Data if this data is
        inaccurate, incomplete, ambiguous or outdated.
        <br />
        <br /> Under the conditions set out in Article 17 of the GDPR, you may also request the{' '}
        <strong>erasure</strong> of your Personal Data.
        <br />
        <br />
        In addition, you may request that the processing of your Personal Data be{' '}
        <strong>limited</strong>, under the conditions set out in Article 18 of the GDPR, i.e. to
        ensure that your Personal Data is only retained for the purposes of:
        <br />
        <ul>
            <li>verifying the accuracy of the Personal Data that has been challenged,</li>
            <li>
                serving you in the acknowledgement, exercise or defence of your legal rights, even
                though the Company no longer has a use for the data,
            </li>
            <li>
                verifying if the legitimate grounds pursued by the Company prevail over yours in the
                event you object to the processing operation based on the legitimate interest of the
                Company,
            </li>
            <li>
                responding to your request to restrict the use of your data - rather than deleting
                the data - in the event the processing of `your data is unlawful.
            </li>
        </ul>
        Pursuant to your <strong>right to data portability</strong>, and under the circumstances
        outlined in Article 20 of the GDPR, you may retrieve from the Company the Personal Data you
        have disclosed, in a structured, commonly used and machine readable format, to transfer this
        Data to another Data Controller.
        <br />
        <br />
        In accordance with Article 21 of the GDPR, you have the right <strong>to object</strong>, at
        any time, to the processing of your Personal Data for commercial prospecting purposes. To
        exercise your rights, please send your request either: <br />
        <ul>
            <li>by email to the following address: {data.dpo_email},</li>
            <li>or by letter to the following address: {data.dpo_address}.</li>
        </ul>
        The Company will respond to your request within 30 days of receipt.
        <br />
        <br /> Exercising your rights is free of charge. However, in the event of a request that is
        manifestly unfounded or excessive, in particular due to their repetitive nature, the Company
        has the right (i) to request the payment of fees to cover administrative costs, or (ii) to
        refuse to process the request.
        <br />
        <br />
        <h3>
            <strong>10. What happens in the event of a breach of your Personal Data?</strong>
        </h3>
        In the event of a breach of your Personal Data likely to pose a risk to your rights and
        freedoms, the Company will notify the CNIL (French Data Protection Agency) of the breach as
        soon as possible, and, if possible, within 72 hours of becoming aware thereof. If a breach
        of your Personal Data is likely to pose a heightened risk to the rights and freedoms of a
        User, the Company will inform the User, as soon as possible, subject to the exceptions
        outlined in Article 34 of the GDPR. <br />
        <br /> Without prejudice to any other administrative or legal action, if you believe that
        the processing of your Personal Data has breached the provisions of the legislation in
        force, you may lodge a complaint with a relevant supervisory authority, such as the CNIL, at
        the following address:
        <br /> Commission Nationale de l’Informatique et des Libertés, 3 Place de Fontenoy - TSA
        80715 - 75334 Paris Cedex 07, France.
        <br />
        <br />
        <h3>
            <strong>11. Do you have any questions?</strong>
        </h3>
        If you have any questions about the Processing of your Personal Data, you may contact the
        Company’s Data Protection Officer, or DPO, at any time: {data.dpo_name}, for any questions
        related to the processing of your Personal Data and exercising your rights, at the following
        address: {data.dpo_email}.
        <br />
        <br />
    </StyledContent>
);

export default PrivacyPolicyTemplateEn;
