import { useTranslation } from 'react-i18next';

import { QUICK_ACTION_LINKS, QuickActionLink } from 'app/addon/QuickAction/links';
import { ADMIN, BUSINESS_MANAGER, GROUP_MANAGER, ORG_ADMIN } from 'app/common/data/roles';
import { useHasApiKeyManagerPage } from 'app/common/hooks/accessesAndPermissions/pages/general/useHasApiKeyManagerPage';
import { useHasChatbotPage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasChatbotPage';
import { useHasMessagePage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasMessagePage';
import { useHasDiffusionPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasDiffusionPage';
import { useHasPostsPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasPostsPage';
import { useHasPresenceAnalyticsPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasPresenceAnalyticsPage';
import { useHasReviewAnalyticsPage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewAnalyticsPage';
import { useHasReviewInvitePage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewInvitePage';
import { useHasReviewManagementPage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewManagementPage';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useGetAvailableActions = (): Array<QuickActionLink> => {
    const { data: me } = useMe();
    const { t } = useTranslation();

    if (!me) return [];

    const actions: QuickActionLink[] = [];

    useAddVisibilityActions(actions);
    useAddMessagesActions(actions);
    useAddReviewsActions(actions);
    useAddReviewBoosterActions(actions);
    useAddSettingsActions(actions);

    return actions.map(({ title, subtitle, ...remaining }) => {
        return {
            title: t(title),
            subtitle: t(subtitle),
            ...remaining,
        };
    });
};

const useAddVisibilityActions = (actions: QuickActionLink[]) => {
    const hasDiffusionPage = useHasDiffusionPage();
    const hasPresenceAnalyticsPage = useHasPresenceAnalyticsPage();
    const hasPostsPage = useHasPostsPage();

    actions.push(QUICK_ACTION_LINKS.BUSINESS_LIST);

    if (hasDiffusionPage) {
        actions.push(QUICK_ACTION_LINKS.DIFFUSION_STATUS);
    }

    if (hasPresenceAnalyticsPage) {
        actions.push(QUICK_ACTION_LINKS.VISIBILITY_STATS);
    }

    if (hasPostsPage) {
        actions.push(QUICK_ACTION_LINKS.POSTS);
    }
};

const useAddMessagesActions = (actions: QuickActionLink[]) => {
    const { data: me } = useMe();

    const hasMessagePage = useHasMessagePage();
    const hasChatbotPage = useHasChatbotPage();

    if (hasMessagePage) {
        actions.push(QUICK_ACTION_LINKS.MESSAGES);

        if (![GROUP_MANAGER, BUSINESS_MANAGER].includes(me?.role ?? '')) {
            actions.push(QUICK_ACTION_LINKS.MESSAGE_REPLY_TEMPLATES);
            actions.push(QUICK_ACTION_LINKS.MESSAGE_CONVERSATION_STARTERS);
            actions.push(QUICK_ACTION_LINKS.MESSAGE_WHATSAPP);
        }
    }

    if (hasChatbotPage) {
        actions.push(QUICK_ACTION_LINKS.MESSAGE_JIM);
    }
};

const useAddReviewsActions = (actions: QuickActionLink[]) => {
    const { data: me } = useMe();

    const hasReviewManagementPage = useHasReviewManagementPage();
    const hasReviewAnalyticsPage = useHasReviewAnalyticsPage();

    if (hasReviewManagementPage) {
        actions.push(QUICK_ACTION_LINKS.REVIEWS, QUICK_ACTION_LINKS.NOTIFICATIONS_SETTINGS);

        if (![GROUP_MANAGER, BUSINESS_MANAGER].includes(me?.role ?? ''))
            actions.push(QUICK_ACTION_LINKS.REVIEW_TEMPLATES);

        if (me?.role === ORG_ADMIN) actions.push(QUICK_ACTION_LINKS.REVIEW_AUTO_REPLY);
    }

    if (hasReviewAnalyticsPage) {
        actions.push(QUICK_ACTION_LINKS.REVIEWS_STATS);
    }
};

const useAddReviewBoosterActions = (actions: QuickActionLink[]) => {
    const hasReviewInvitePage = useHasReviewInvitePage();

    if (hasReviewInvitePage) {
        actions.push(QUICK_ACTION_LINKS.REVIEW_BOOSTER);
    }
};

const useAddSettingsActions = (actions: QuickActionLink[]) => {
    const { data: me } = useMe();
    const hasApiKeyManagerPage = useHasApiKeyManagerPage();

    actions.push(
        QUICK_ACTION_LINKS.PROFILE_SETTINGS,
        QUICK_ACTION_LINKS.BUSINESS_CONNECTIONS,
        QUICK_ACTION_LINKS.USER_LIST,
        QUICK_ACTION_LINKS.CREATE_USER,
        QUICK_ACTION_LINKS.HELP_CENTER,
        QUICK_ACTION_LINKS.VERIFY_LISTINGS,
    );

    if (![GROUP_MANAGER, BUSINESS_MANAGER, ADMIN].includes(me?.role ?? '')) {
        actions.push(QUICK_ACTION_LINKS.USER_PERMISSIONS);
    }

    if (me?.role === ORG_ADMIN) {
        actions.push(QUICK_ACTION_LINKS.CUSTOM_FIELDS);
    }

    if (hasApiKeyManagerPage) {
        actions.push(QUICK_ACTION_LINKS.API_KEYS);
    }
};
