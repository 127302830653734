import { Button, FontAwesomeIconsPartooUsed } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router';

import { CONNECTION_LOGIN_SSO_PATH } from 'app/routing/routeIds';

export const SsoSignInButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Button
            dataTrackId="sso-sign-in-button"
            size="large"
            appearance="outlined"
            variant="secondary"
            onClick={() => navigate(CONNECTION_LOGIN_SSO_PATH)}
            full
            iconPosition="left"
            icon={[FontAwesomeIconsPartooUsed.faStore]}
        >
            {t('sso_sign_in')}
        </Button>
    );
};
