import { Location, Path, createBrowserHistory, parsePath } from 'history';

import { parse, stringify } from 'app/utils/queryString';

export type LocationType = {
    pathname: string;
    search: string;
    hash: string;
    state?: any;
};

export const browserHistory = createBrowserHistory();

export const compareLocations = (to1: LocationType, to2: LocationType): boolean => {
    // calling qs.stringify(qs.parse(...)) to handle cases were search parameters are not in the same order
    // in this case we consider both urls to be the same still
    const search1 = stringify(parse(to1.search));
    const search2 = stringify(parse(to2.search));
    return to1.pathname === to2.pathname && search1 === search2 && to1.hash === to2.hash;
};

const maybePreserveSearchParams = (
    to: LocationType,
    ignoreOldQueryParams = false,
): LocationType => ({
    ...to,
    search: stringify({
        // preserve search params only if we're staying on the same pathname
        ...((to.pathname === undefined || to.pathname === browserHistory.location.pathname) &&
        !ignoreOldQueryParams
            ? parse(browserHistory.location.search)
            : {}),
        ...parse(to.search),
    }),
});

// This patch of browserHistory allows search parameters merging :
// Example :
//  - being on /review-management/reviews?status=treated
//  - navigating to /review-management/reviews?date_from=2020-01-01
//  - leads to /review-management/reviews?status=treated&date_from=2020-01-01
// It merges search parameters when source and target paths are the same, otherwise, it keeps
// only target search parameters.
//
// `browserHistory` definition :
// https://github.com/ReactTraining/history/blob/28c89f4091ae9e1b0001341ea60c629674e83627/packages/history/index.ts
const { push } = browserHistory;

browserHistory.push = (to: Path | Location, state?) => {
    const targetTo = maybePreserveSearchParams(
        typeof to === 'string' ? parsePath(to) : to,
        !!state?.ignoreOldQueryParams,
    );
    // Don't push to history if we're going to the same url
    // This fixes this react-router issue : https://github.com/ReactTraining/react-router/issues/5362
    const { pathname, search, hash } = window.location;

    if (
        compareLocations(targetTo, {
            pathname,
            search,
            hash,
        })
    ) {
        return;
    }

    push.apply(browserHistory, [targetTo, state]);
};

const { replace } = browserHistory;

// @ts-ignore
browserHistory.replace = (to, state) =>
    replace.apply(browserHistory, [
        maybePreserveSearchParams(typeof to === 'string' ? parsePath(to) : to),
        state,
    ]);
