import React, { useLayoutEffect } from 'react';

import { ReduxRouter, ReduxRouterSelector } from '@lagunovsky/redux-react-router';
import { useDispatch } from 'react-redux';

import { RouterProvider } from 'app/routing/context/RouterContext';
import { browserHistory } from 'app/states/history';

const routerSelector: ReduxRouterSelector = state => state.router;

type Props = {
    children: React.ReactNode;
};

export const CustomRouter = ({ children }: Props) => {
    const dispatch = useDispatch();
    useLayoutEffect(() => {
        dispatch({
            type: '@@router/ON_LOCATION_CHANGED',
            payload: { location },
        });
    }, []);
    return (
        <ReduxRouter history={browserHistory} routerSelector={routerSelector}>
            <RouterProvider>{children}</RouterProvider>
        </ReduxRouter>
    );
};
