import React from 'react';

import { Text } from '@partoohub/ui';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import { STATIC_BASE_URL } from 'app/config';
import { useIsMobileNotApp } from 'app/pages/Login/hooks/loginModals/useIsMobileNotApp';
import { useIsWhitelistProvider } from 'app/pages/Login/hooks/loginModals/useIsWhitelistProvider';
import DURATIONS from 'app/styles/constants/durations';

import {
    BackgroundContainer,
    BackgroundImageContainer,
    DownloadButton,
    DownloadButtonContainer,
    LaterButton,
    ParagraphText,
} from './DownloadMobileApp.styled';

import { AppleStore } from './Icons/AppleStore';
import { GoogleStore } from './Icons/GoogleStore';

const IOS_APP_DOWNLOAD_LINK = 'https://apps.apple.com/fr/app/partoo/id1580048834';
const ANDROID_APP_DOWNLOAD_LINK = 'https://play.google.com/store/apps/details?id=com.partoo.mobile';

type Props = {
    visible: boolean;
    setVisible: (value: boolean) => void;
};

/**
 * Full page screen for downloading the mobile app
 * Is displayed every 3 days for users on mobile devices
 */
export const DownloadMobileApp = ({ visible, setVisible }: Props) => {
    const isMobileNotApp = useIsMobileNotApp();
    const isWhiteListProvider = useIsWhitelistProvider();

    const { t } = useTranslation();

    const nodeRef = React.useRef(null);

    const handleClickLater = () => {
        setVisible(false);
    };

    if (!isWhiteListProvider || !isMobileNotApp) {
        return null;
    }

    return ReactDOM.createPortal(
        <CSSTransition nodeRef={nodeRef} in={visible} timeout={DURATIONS.short} unmountOnExit>
            <BackgroundContainer
                ref={nodeRef}
                url={`${STATIC_BASE_URL}/images/app/background-mobile-app.png`}
            >
                <Text variant="heading1" color="white">
                    {t('web_banner_download_mobile_app_small_devices_modal_title')}
                </Text>
                <ParagraphText>
                    <Text variant="bodyLRegular" as="span" color="white">
                        {t('web_banner_download_mobile_app_small_devices_modal_desc')}
                    </Text>
                </ParagraphText>
                <DownloadButtonContainer>
                    <DownloadButton
                        href={IOS_APP_DOWNLOAD_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <AppleStore />
                        <Text variant="bodySMedium" as="span" color="white">
                            {t('app_store')}
                        </Text>
                    </DownloadButton>
                    <DownloadButton
                        href={ANDROID_APP_DOWNLOAD_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <GoogleStore />
                        <Text variant="bodySMedium" as="span" color="white">
                            {t('google_play')}
                        </Text>
                    </DownloadButton>
                </DownloadButtonContainer>
                <BackgroundImageContainer>
                    <img src={`${STATIC_BASE_URL}/images/app/mobile-responsive.png`} alt="" />
                </BackgroundImageContainer>
                <LaterButton onClick={handleClickLater}>
                    <Text variant="bodyMBold" as="span" color="white">
                        {t('web_banner_download_mobile_app_small_devices_modal_continue_button')}
                    </Text>
                </LaterButton>
            </BackgroundContainer>
        </CSSTransition>,
        document.body,
    );
};
