import { StyledContent } from 'app/pages/privacyPolicy/PrivacyPolicy.styled';
import { MessagingPrivacyPolicyData } from 'app/pages/privacyPolicy/types';

type Props = {
    data: MessagingPrivacyPolicyData;
};

const PrivacyPolicyTemplateIt = ({ data }: Props) => (
    <StyledContent>
        <h1>
            <strong>INFORMATIVA SULLA PRIVACY</strong>
        </h1>
        <br />
        La protezione dei dati personali e il rispetto della vita privata rivestono una grande
        importanza per noi. La presente Informativa sulla privacy ha lo scopo di informare l’Utente
        del modo in cui raccogliamo, utilizziamo e trasmettiamo i dati personali quando comunica con
        noi <i>tramite</i>
        la Chat (la « <strong>Chat</strong>»), ai sensi della normativa vigente in Francia prevista
        dalla legge n°78-17 del 6 gennaio 1978 sull'informatica, i file e le libertà modificata («{' '}
        <strong>LIL</strong> ») e dal Regolamento UE 2016/679 del 27 aprile 2016 sulla protezione
        dei dati personali (« <strong>RGPD</strong> »). <br />
        <br /> Si ricorda che anche l’uso dei Dati personali dell’Utente è disciplinato
        dall’Informativa sulla privacy della Chat utilizzata (esempio per Google:{' '}
        <a href="https://policies.google.com/privacy?gl=IT&hl=it">
            https://policies.google.com/privacy
        </a>
        ).
        <br />
        <br /> L’Utente verrà informato in merito ad ogni eventuale modifica o aggiornamento della
        presente Informativa sulla privacy.
        <br />
        <br />
        <h3>
            <strong>1. Definizioni</strong>
        </h3>
        « <strong>Chat</strong> » indica il servizio di messaggistica istantanea che l’Utente
        utilizza per comunicare.
        <br />
        <br /> « <strong>Dati</strong> » indica ogni eventuale informazione trasmessa dall’Utente
        alla Società <i>tramite</i> la Chat, Dati personali inclusi.
        <br />
        <br /> « <strong>Dati personali</strong> » indica qualsiasi informazione inerente ad una
        persona fisica identificata o identificabile, direttamente o indirettamente, in riferimento
        ad un numero di identificazione o a uno o più elementi che la contraddistinguono.
        <br />
        <br /> « <strong>Dati personali sensibili</strong> » indica qualsiasi informazione inerente
        all'origine razziale o etnica, alle opinioni politiche, filosofiche o religiose,
        all’appartenenza ad un sindacato, alla salute o alla vita sessuale nonché ai dati genetici o
        biometrici. Di norma, il trattamento dei Dati sensibili è vietato salvo, in particolare, se
        la persona interessata ha dato il suo esplicito consenso.
        <br />
        <br /> « <strong>Responsabile del trattamento</strong> » indica la persona fisica o
        giuridica che stabilisce le finalità e i mezzi di un Trattamento dati e in questo caso
        specifico la Società.
        <br />
        <br /> « <strong>Subappaltatore</strong> » indica la persona fisica o giuridica che tratta i
        Dati personali per nostro conto e in base alle nostre istruzioni, nell’ambito dell’utilizzo
        della Chat.
        <br />
        <br /> « <strong>Trattamento</strong> » indica ogni eventuale operazione o gruppo di
        operazioni inerenti ai Dati personali, indipendentemente dal procedimento utilizzato
        (raccolta, registrazione organizzazione, conservazione adattamento, modifica, estrazione
        consultazione, utilizzo, comunicazione mediante trasmissione o diffusione o qualsiasi altra
        forma di messa a disposizione e fornitura) svolta dalla Società nell'ambito dell’utilizzo
        della Chat.
        <br />
        <br /> « <strong>Utente</strong> » o « <strong>Suo/suoi</strong> » indica qualunque persona
        fisica che entra in contatto con la Società <i>tramite</i> la Chat.
        <br />
        <br />
        <h3>
            <strong>2. Chi è responsabile del trattamento dei dati personali?</strong>
        </h3>
        Il Responsabile del trattamento, che raccoglie e gestisce i dati, è la società{' '}
        {data.company_name}, società {data.company_type} capitale {data.share_capital} euros con
        sede legale a {data.company_address}, numero di iscrizione al Registro del Commercio di{' '}
        {data.company_registration_city} {data.company_registration_number} (la «{' '}
        <strong>Società</strong> »), rappresentata dal suo Presidente.
        <br />
        <br />
        <h3>
            <strong>3. Categorie di Dati personali raccolti </strong>
        </h3>
        Quando l’Utente usa la Chat, noi possiamo raccogliere le seguenti categorie di Dati
        personali: <br />
        <ul>
            <li>
                <strong>Stato civile</strong> : Cognome, nome,
            </li>
            <li>
                <strong>Immagine</strong> (foto del profilo dell’account della Chat utilizzata).
            </li>
        </ul>
        Inoltre possiamo raccogliere ogni eventuale Dato personale che l’Utente fornisce di sua
        iniziativa durante le conversazioni <i>in</i> Chat (numero d’ordine, indirizzo postale e/o
        recapito telefonico, fotografie, ecc.). Si noti che non chiederemo mai all’Utente di
        comunicare Dati personali sensibili <i>in</i> Chat e si consiglia vivamente di non
        comunicarli di propria iniziativa in modo da garantire una maggiore sicurezza delle
        informazioni scambiate.
        <br />
        <br /> L’Utente si impegna a fornire Dati personali aggiornati e validi e garantisce di non
        fare nessuna falsa dichiarazione e di non fornire informazioni errate. L’Utente non deve
        comunicare <i>in</i> Chat nessun Dato personale su terzi.
        <br />
        <br />
        <h3>
            <strong>4. Dati personali obbligatori e facoltativi raccolti</strong>
        </h3>
        Alcuni Dati sono indispensabili per l’evasione della richiesta dell’Utente. Le informazioni
        obbligatorie richieste sono segnalate al momento della loro raccolta. La mancata fornitura
        dei suddetti Dati ci impedirà di rispondere in modo adeguato alla richiesta dell’Utente.
        <br />
        <br /> Tutti i Dati che non vengono contrassegnati come obbligatori sono facoltativi.
        Quindi, l’Utente è libero di inserirli o meno. Tuttavia, ci possono consentire di rispondere
        in modo più preciso alla richiesta e ci permettono di migliorare l’esperienza dell’Utente
        durante lo scambio di messaggi.
        <br />
        <br />
        <h3>
            <strong>5. Finalità della raccolta dei Dati personali</strong>
        </h3>
        La Società raccoglie i Dati personali dell’Utente, nell’ambito dell’utilizzo della Chat, per
        le seguenti finalità:
        <br />
        <ul>
            <li>Leggere i messaggi inviati e rispondere in modo ottimale,</li>
            <li>
                Permettere l'accesso alla cronologia delle conversazioni tra la Società e gli
                Utenti,
            </li>
            <li>
                Migliorare il servizio che forniamo all’Utente <i>tramite</i> la Chat (riduzione dei
                tempi di risposta, ecc.),
            </li>
            <li>Redigere delle statistiche e rilevare lo share,</li>
            <li>Contrastare le frodi e garantire la sicurezza dei Dati,</li>
            <li>Ottemperare alle disposizioni legali, normative e amministrative vigenti.</li>
        </ul>
        A seconda dei casi, la Società tratta i Dati personali dell’Utente secondo una delle
        seguenti basi giuridiche:
        <br />
        <ul>
            <li>Il previo consenso dell’Utente quando autorizza il trattamento dei propri Dati,</li>
            <li>La stipula di un contratto che vincola a noi l’Utente, </li>
            <li>I nostri obblighi legali, </li>
            <li>
                Il nostro “legittimo interesse” ai sensi della legge applicabile in materia di
                protezione dei Dati personali. In questo caso, terremo conto degli interessi e dei
                diritti fondamentali dell’Utente per stabilire se il trattamento è legittimo e
                lecito.
            </li>
        </ul>
        <br />
        <h3>
            <strong>6. Chi ha accesso ai Dati personali dell’Utente?</strong>
        </h3>
        <h4>
            <strong>6.1. Il nostro personale</strong>
        </h4>
        I Dati personali raccolti vengono trattati da soggetti debitamente abilitati all’interno
        della Società al fine di rispondere alle richieste dell’Utente <i>tramite</i> la Chat.
        <br />
        <br />
        <h4>
            <strong>6.2. I nostri subappaltatori</strong>
        </h4>
        Nell’ambito della Chat, la Società ricorre a dei Subappaltatori che trattano i Dati
        personali per suo conto e nel rispetto delle sue istruzioni. Al fine di garantire la
        sicurezza e la riservatezza dei Dati dell’Utente e ai sensi delle disposizione del GDPR,
        pretendiamo che i nostri Subappaltatori offrano delle garanzie sufficienti e idonee sia sul
        piano tecnico che organizzativo.
        <br />
        <br /> La Società ricorre a dei Subappaltatori con sede in paesi che offrono un livello di
        protezione idoneo perché sono soggetti alle disposizioni del GDPR oppure, ai sensi
        dell’articolo 45 del GDPR, perché godono di una decisione di adeguatezza della Commissione
        europea. In caso contrario, la Società si impegna ad attuare ogni eventuale meccanismo
        appropriato al fine di garantire un livello di protezione idoneo dei Dati e in particolare
        le clausole contrattuali tipo stabilite dalla Commissione europea.
        <br />
        <br /> Per avere maggiori informazioni sulle garanzie offerte dai nostri Subappaltatori
        basta inviare una richiesta al seguente indirizzo:
        {data.dpo_address}.<br />
        <br />
        <h4>
            <strong>6.3. Altri eventuali destinatari</strong>
        </h4>
        La Società, se del caso, può essere tenuta a trasmettere i Dati personali dell’Utente a
        organizzazioni, soggetti terzi, autorità amministrative o giudiziarie nei seguenti casi:
        <br />
        <ul>
            <li>Per ottemperare agli obblighi legali e normativi che le spettano,</li>
            <li>
                Identificare, evitare o gestire attività fraudolente, falle di sicurezza o altri
                problemi tecnici,
            </li>
            <li>Per far valere un diritto in giudizio.</li>
        </ul>
        Si assicura che la società non trasmetterà i Dati personali dell’Utente a partner terzi per
        effettuare operazioni commerciali senza la previa autorizzazione dell’Utente stesso.
        <br />
        <br />
        <h3>
            <strong>7. Per quanto tempo vengono conservati i Dati personali dell’Utente?</strong>
        </h3>
        La Società conserva i Dati personali dell’Utente, in una forma che le consente di
        identificare l’Utente, su di un database attivo il tempo strettamente necessario
        all’espletamento delle finalità perseguite e per le quali vengono raccolti e trattati, vale
        a dire massimo un anno a decorrere dall’ultima conversazione <i>in</i> Chat.
        <br />
        <br />
        Inoltre i Dati personali dell’Utente possono essere oggetto di archiviazione ad accesso
        controllato, limitato e giustificato, per il tempo necessario (i) al rispetto degli obblighi
        legali, fiscali, contabili e normativi della Società e/o (ii) per permetterci di far valere
        un diritto in giudizio, prima di essere eliminati definitivamente.
        <br />
        <br />
        <h3>
            <strong>
                8. In che modo garantiamo la sicurezza e la riservatezza dei Dati personali
                dell’Utente?{' '}
            </strong>
        </h3>
        La Società di impegna a trattare i Dati personali dell’Utente in modo lecito, leale,
        trasparente, proporzionato, pertinente, rigorosamente nel quadro delle finalità perseguite e
        comunicate, per la durata necessaria ai trattamenti posti in essere e in modo sicuro.
        <br />
        <br /> La Società mette in atto e aggiorna le misure tecniche e organizzative idonee a
        garantire la sicurezza e la riservatezza dei Dati personali dell’Utente impedendo che
        vengano alterati, distrutti, danneggiati o comunicati a soggetti terzi non autorizzati. A
        questo titolo, la Società ha adottato delle misure di protezione fisiche, elettroniche e
        organizzative per impedire ogni eventuale smarrimento, uso improprio, accesso o
        distribuzione non autorizzata, modifica o distruzione dei Dati personali. Dal momento che
        tutti i Dati personali sono riservati, l’accesso è limitato ai dipendenti, fornitori di
        servizi e Subappaltatori della Società che hanno necessità di conoscerli per lo svolgimento
        delle loro funzioni. Tutti i soggetti che hanno accesso ai Dati personali dell’Utente sono
        vincolati da un obbligo di riservatezza e rischiano provvedimenti disciplinari e/o sanzioni
        in caso di mancato rispetto di questi obblighi.
        <br />
        <br /> Tuttavia, è importante che l’Utente faccia tutto il possibile per impedire ogni
        eventuale accesso non autorizzato ai suoi Dati personali comunicando solo i Dati
        strettamente necessari all’evasione della sua richiesta <i>tramite</i> Chat.
        <br />
        <br />
        <h3>
            <strong>9. Che diritti ha l’Utente su suoi Dati personali? </strong>
        </h3>
        Con una semplice richiesta scritta inviata al seguente indirizzo: {data.dpo_address},
        l’Utente può accedere ai suoi Dati personali, chiederne la rettifica o la limitazione, la
        cancellazione o la portabilità o pretendere di non essere più presente nel database della
        Società.
        <br />
        <br /> In merito al <strong>diritto di accesso</strong>, ai sensi dell’articolo 15 del GDPR,
        l’Utente è autorizzato a chiederci sia conferma che i suoi Dati personali siano o non siano
        più oggetto di Trattamento sia che gli vengano comunicate informazioni sul trattamento (in
        particolare le finalità, le categorie di Dati personali trattati e i destinatari ai quali i
        Dati personali vengono comunicati, il periodo di conservazione dei Dati o i criteri
        utilizzati per stabilire tale periodo, i suoi diritti sui Dati personali e il diritto di
        presentare un reclamo a un’autorità di controllo).
        <br />
        <br />
        Ai sensi dell’articolo 16 del GDPR, il <strong>diritto di rettifica</strong> conferisce
        all’Utente il diritto di pretendere da noi che i suoi Dati personali vengano rettificati,
        completati o aggiornati se inesatti, incompleti, equivoci od obsoleti.
        <br />
        <br /> Alle condizioni previste all'articolo 17 del GDPR, l’Utente può anche chiedere{' '}
        <strong>la cancellazione</strong> dei suoi Dati personali.
        <br />
        <br />
        Inoltre, l’Utente può chiedere che il trattamento dei suoi Dati personali sia{' '}
        <strong>limitato</strong> alle condizioni dell’articolo 18 del GDPR, vale a dire che i suoi
        Dati personali siano conservati unicamente al fine di:
        <br />
        <ul>
            <li>verificare l’esattezza dei Dati personali oggetto della contestazione,</li>
            <li>
                essere utilizzati all’Utente nel quadro della constatazione, dell’esercizio o della
                difesa dei suoi diritti in giudizio, benché non servano più alla società,
            </li>
            <li>
                verificare se i motivi legittimi perseguiti dalla Società prevalgono su quelli
                dell’Utente qualora l’Utente si opponga al trattamento fondato sul legittimo
                interesse della Società,{' '}
            </li>
            <li>
                soddisfare la richiesta dell’Utente di limitazione dell’uso dei suoi dati, invece
                della loro cancellazione, qualora il trattamento dei suoi dati sia illegale.
            </li>
        </ul>
        In virtù del <strong>diritto di portabilità</strong> dell’Utente e nei casi di cui
        all’articolo 20 del GDPR, l’Utente può recuperare presso la Società i Dati personali
        forniti, in un formato strutturato, di uso comune e leggibile elettronicamente, al fine di
        trasmetterli ad un altro responsabile del trattamento.
        <br />
        <br />
        Ai sensi dell’articolo 21 del GDPR, l’Utente può <strong>opporsi</strong>, in qualsiasi
        momento, al trattamento dei suoi Dati personali a fini di promozione commerciale. Per
        esercitare i propri diritti, l’Utente deve inviare una richiesta a scelta: <br />
        <ul>
            <li>per posta elettronica al seguente indirizzo: {data.dpo_email},</li>
            <li>per posta ordinaria al seguente indirizzo: {data.dpo_address}.</li>
        </ul>
        La Società risponderà alla richiesta entro 30 giorni dalla ricezione.
        <br />
        <br /> L’esercizio di questi diritti è gratuito. Tuttavia, in caso di richiesta palesemente
        infondata o eccessiva, in particolare in caso di ripetitività, la Società si riserva il
        diritto (i) di esigere il pagamento delle spese che includono i costi amministrativi, o (ii)
        di rifiutarsi di dar seguito alla richiesta.
        <br />
        <br />
        <h3>
            <strong>10. Cosa succede in caso di violazione dei Dati personali dell’Utente?</strong>
        </h3>
        In caso di una violazione dei Dati personali dell’Utente che potrebbe comportare un rischio
        per i suoi diritti e le sue libertà, la Società comunica la violazione alla CNIL
        (Commissione Nazionale dell'Informatica e delle Libertà) quanto prima e, se possibile, entro
        72 ore dopo averne preso conoscenza. Se una violazione dei Dati personali dell’Utente può
        comportare un rischio elevato per i diritti e le libertà dell’Utente, la Società informa
        quanto prima l’Utente, fatte salve le eccezioni di cui all’articolo 34 del GDPR.
        <br />
        <br /> Fatto salvo ogni altro eventuale ricorso amministrativo o giuridico, se l’Utente
        ritiene che il trattamento dei suoi Dati personali costituisca una violazione delle
        disposizioni legislative vigenti, può presentare un reclamo all’autorità di controllo
        competente, come la CNIL al seguente indirizzo:
        <br />
        Commission Nationale de l'Informatique et des Libertés, 3 Place de Fontenoy - TSA 80715 -
        75334 Paris Cedex 07.
        <br />
        <br />
        <h3>
            <strong>11. L’Utente a chi può fare delle domande?</strong>
        </h3>
        In caso di domande sul Trattamento dei suoi Dati personali, l’Utente può contattare in
        qualsiasi momento il referente dei dati personali o il responsabile della protezione dei
        dati della società: {data.dpo_name}, per tutte le domande sul trattamento dei suoi Dati
        personali e sull’esercizio dei suoi diritti, al seguente indirizzo: {data.dpo_email}.
        <br />
        <br />
    </StyledContent>
);

export default PrivacyPolicyTemplateIt;
