import styled from '@emotion/styled';

export const OrSeparator = styled.div`
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 20px;
    gap: 16px;

    &::before,
    &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid ${({ theme }) => theme.colors.secondary.alpha};
    }
`;

export const GoogleSigninButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const GoogleSigninButton = styled.div`
    position: absolute;
    top: 10px;
    width: 100%;
    height: 60px;
    opacity: 0;
    overflow: hidden;
`;

export const GoogleSigninError = styled.div`
    display: flex;
    align-items: center;
    min-height: 50px;
    background-color: ${({ theme }) => theme.colors.danger.alpha};
    color: ${({ theme }) => theme.colors.danger.initial};
    width: 100%;
    margin-top: 16px;
    border-radius: 8px;
    padding: 0 16px;

    > img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
`;
