import { useMemo } from 'react';

import { Sidebar } from '@partoohub/modular-components';
import { useTranslation } from 'react-i18next';

import { useSidebar } from 'app/common/contexts/sidebar';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';

import { useGetSidebarMenus } from './hooks';
import { SidebarLogo } from '../SidebarLogo/SidebarLogo';

export const SidebarWithContext = () => {
    const { t } = useTranslation();
    const { extended, setExtended } = useSidebar();
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);

    const sidebarMenus = useGetSidebarMenus();

    const showSidebar = useMemo(
        () => !isBelowProvidedDevices || extended,
        [isBelowProvidedDevices, extended],
    );

    if (!showSidebar) {
        return null;
    }

    return (
        <Sidebar
            moreText={t('more')}
            extended={extended}
            setExtended={setExtended}
            logo={<SidebarLogo />}
            routes={sidebarMenus}
        />
    );
};
