import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

import { LoginSso } from './LoginSso';

export const LoginSsoPage = () => {
    const { t } = useTranslation();
    return (
        <>
            <DynamicPageHeader title={t('send_reset_password_link')} />
            <LoginSso />
        </>
    );
};
