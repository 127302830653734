import React from 'react';

export const AppleStore = () => {
    return (
        <svg
            width="19"
            height="23"
            viewBox="0 0 19 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.6702 12.6062C15.6919 10.9162 16.5959 9.31707 18.0301 8.4319C17.1254 7.13468 15.6099 6.3122 14.0332 6.26268C12.3516 6.08548 10.7213 7.27288 9.86434 7.27288C8.99081 7.27288 7.67141 6.28027 6.25064 6.30962C4.39872 6.36968 2.67227 7.42669 1.77128 9.05208C-0.165508 12.4184 1.27916 17.3658 3.13444 20.0869C4.06267 21.4193 5.14751 22.9077 6.56701 22.8549C7.95606 22.7971 8.47485 21.9657 10.1516 21.9657C11.8127 21.9657 12.2994 22.8549 13.7477 22.8213C15.2383 22.7971 16.1774 21.483 17.073 20.1379C17.74 19.1885 18.2532 18.1392 18.5936 17.0289C16.842 16.2851 15.6722 14.5155 15.6702 12.6062Z"
                fill="white"
            />
            <path
                d="M12.9346 4.47316C13.7473 3.49374 14.1477 2.23486 14.0507 0.963867C12.8091 1.09478 11.6622 1.69051 10.8385 2.63235C10.0331 3.55258 9.61391 4.78931 9.69316 6.01173C10.9352 6.02456 12.1563 5.44498 12.9346 4.47316Z"
                fill="white"
            />
        </svg>
    );
};
