import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';
import { OVER_NPS_Z_INDEX } from '@partoohub/ui';

type Props = {
    url: string;
};

export const BackgroundContainer = styled.div<Props>`
    background: url('${props => props.url}');
    background-size: cover;
    color: ${HEX_COLORS.white};
    width: 100vw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    overflow: hidden;
    text-align: center;
    z-index: ${OVER_NPS_Z_INDEX};
    padding: 40px;
    top: 0;
`;

export const ParagraphText = styled.div`
    margin: 16px 0;
`;

export const DownloadButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

export const DownloadButton = styled.a`
    height: 40px;
    background: rgba(255, 255, 255, 0.12);
    border: 0.659524px solid rgba(255, 255, 255, 0.27);
    box-shadow:
        inset 73.3171px -73.3171px 73.3171px rgba(194, 194, 194, 0.12),
        inset -73.3171px 73.3171px 73.3171px rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(11.8385px);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0 10px;
    cursor: pointer;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transition: all 0.2s;
    &:hover,
    &:active,
    &:focus {
        border-radius: 8px;
        border: 1px solid ${HEX_COLORS.white};
        text-decoration: none;
    }
`;

export const BackgroundImageContainer = styled.div`
    width: 100%;
    margin: 12px 0;
    img {
        max-width: 375px;
        width: 100%;
        height: 100%;
    }
`;

export const LaterButton = styled.button`
    background-color: transparent;
    padding: 0 24px;
    color: ${HEX_COLORS.white};
    height: 40px;
    border: 1px solid ${HEX_COLORS.white};
    border-radius: 50px;
    transition: all 0.2s;
    &:hover,
    &:active,
    &:focus {
        // cursor: pointer;
        border: 1px solid ${HEX_COLORS.white};
        background-color: rgba(255, 255, 255, 0.1);
    }

    &:hover {
        cursor: pointer;
    }
`;
