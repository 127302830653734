import styled from '@emotion/styled';

import { isMobile } from 'app/styles/utils/breakpoints';

export const StyledContent = styled.div`
    margin: auto;
    max-width: 848px;
    font-size: 16px;
    padding: 50px 20px;
    text-align: justify;

    ${isMobile} {
        font-size: 14px;
        text-align: left;
    }
`;
