export const register = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .register('/sw.js', { scope: './' })
            .then(() => {
                if (navigator.serviceWorker.controller) {
                    console.warn('[Service Worker] Successfully Registered');
                } else {
                    console.error('[Service Worker] Failed to Register.');
                }
            })
            .catch(error => {
                console.error('[Service Worker] Unable to Register.', error);
            });
    }
};

export const unregister = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .getRegistrations()
            .then(registrations => {
                for (const registration of registrations) {
                    registration.unregister();
                }
            })
            .catch(error => {
                console.error('[Service Worker] Unable to Unregister.', error);
            });
    }
};
