import { useMutation } from 'react-query';

import { userApiClient } from 'app/api/v2/api_calls/apiResources';

export const useCheckLogin = (
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) => {
    return useMutation(() => userApiClient.getMe(), {
        onSuccess,
        onError,
    });
};
