/**
 * Check if a JS Script file is in DOM.
 *
 * @param {string} scriptId script id
 */
export const isScriptLoaded = (scriptId: string) =>
    !!document.getElementById(scriptId);

/**
 * Dynamically load JS Script file in DOM.
 *
 * @param {string} scriptUrl src URL
 * @param {string} scriptId script id
 * @param {method} callback
 */
export const loadScript = (
    scriptUrl: string,
    scriptId: string,
    callback?: () => void,
) => {
    if (!isScriptLoaded(scriptId)) {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.id = scriptId;
        window.document.head.appendChild(script);

        script.onload = () => {
            if (callback) callback();
        };

        script.onerror = error => {
            console.error(error);
        };
    } else if (callback) {
        callback();
    }
};

/**
 * Dynamically remove JS Script file in DOM.
 *
 * @param {string} scriptId script id
 */
export const removeScript = (scriptId: string) => {
    const existingScript = document.getElementById(scriptId);

    if (existingScript) {
        existingScript.remove();
    }
};
