import { UserData, V2OrgData } from '@partoohub/api-client';

import { PushNotificationsWrapper } from '../../pushNotifications/pushNotificationsWrapper';

export const handleIdentifyUserOnLogin = (
    me: UserData,
    organization: V2OrgData,
    subscriptions: {
        orgPmSubscribed: boolean;
        orgRmSubscribed: boolean;
        orgRbSubscribed: boolean;
        orgBmSubscribed: boolean;
    },
) => {
    return new Promise(resolve => {
        if (me.shadow_user_id) return;

        const { id, first_name, last_name, email, lang, role, created_at, org_id } = me;
        const { organization_type, name, provider, last_org_api_call } = organization;
        const createdAt = new Date(created_at * 1000).toString();

        const pushNotificationsWrapper = PushNotificationsWrapper.getInstance();
        pushNotificationsWrapper.updateProfile({
            firstName: first_name,
            lastName: last_name,
            email,
            userId: id,
            lang,
            role,
            createdAt,
            groupId: undefined, // not handled anymore with new groups
            orgId: org_id,
            organizationType: organization_type,
            orgName: name,
            orgProvider: provider,
            orgPmSubscribed: subscriptions.orgPmSubscribed,
            orgRmSubscribed: subscriptions.orgRmSubscribed,
            orgRbSubscribed: subscriptions.orgRbSubscribed,
            orgBmSubscribed: subscriptions.orgBmSubscribed,
            lastOrgApiCall: last_org_api_call,
        });

        resolve('User identified on login');
    });
};
