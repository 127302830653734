import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

import LoginView from './LoginView';

export const LoginPage = () => {
    const { t } = useTranslation();
    return (
        <>
            <DynamicPageHeader title={t('login')} />
            <LoginView />
        </>
    );
};
