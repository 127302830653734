import { useState } from 'react';

import { Button, Modal, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useIsMobileNotApp } from 'app/pages/Login/hooks/loginModals/useIsMobileNotApp';
import { useIsWhitelistProvider } from 'app/pages/Login/hooks/loginModals/useIsWhitelistProvider';

import { ResponsiveHeaderBanner } from './ResponsiveHeaderBanner';
import {
    EditSettingsContainer,
    EditSettingsItem,
    ResponsiveBody,
    ResponsiveFooter,
    ResponsiveHeader,
    ResponsiveModalContainer,
} from './ResponsiveSettingsModal.styled';

type Props = {
    visibleMobileApp: boolean;
};

/**
 * Modal for displaying that the user is on a small device and the website is in degraded mode
 */
export const ResponsiveSettingsModal = ({ visibleMobileApp }: Props) => {
    const { t } = useTranslation();

    const isMobileNotApp = useIsMobileNotApp();
    const isWhiteListProvider = useIsWhitelistProvider();

    const [showModal, setShowModal] = useState(true);

    const closeModal = () => {
        setShowModal(false);
    };

    if (!isMobileNotApp || (isWhiteListProvider && visibleMobileApp)) {
        return null;
    }

    return (
        <Modal isOpen={showModal} closeModal={closeModal} hideOnClickOutside={false}>
            <ResponsiveModalContainer>
                <ResponsiveHeader>
                    <ResponsiveHeaderBanner />
                </ResponsiveHeader>
                <ResponsiveBody>
                    <Text variant="heading4" as="h4">
                        {t('web_banner_degraded_mode_small_devices_modal_title')}
                    </Text>
                    <Text variant="bodyLRegular" as="p" color={'secondary'}>
                        {t('web_banner_degraded_mode_small_devices_modal_desc')}
                    </Text>
                    <EditSettingsContainer>
                        <EditSettingsItem>
                            <i className="fa-solid fa-circle-check"></i>
                            <Text variant="bodyLRegular" as="p" color={'secondary'}>
                                {t('web_banner_degraded_mode_small_devices_modal_steps_go')}
                            </Text>
                        </EditSettingsItem>
                        <EditSettingsItem>
                            <i className="fa-solid fa-circle-check"></i>
                            <Text variant="bodyLRegular" as="p" color={'secondary'}>
                                {t('web_banner_degraded_mode_small_devices_modal_steps_set')}
                            </Text>
                        </EditSettingsItem>
                    </EditSettingsContainer>
                </ResponsiveBody>
                <ResponsiveFooter>
                    <Button
                        dataTrackId="web_banner_degraded_mode_small_devices_modal_confirm__button"
                        onClick={closeModal}
                        full
                    >
                        {t('web_banner_degraded_mode_small_devices_modal_confirm_button')}
                    </Button>
                </ResponsiveFooter>
            </ResponsiveModalContainer>
        </Modal>
    );
};
