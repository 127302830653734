import { useLoginContext } from 'app/pages/Login/hooks/useLoginContext';
import { useLoginRedirect } from 'app/pages/Login/hooks/useLoginRedirect';

import { useCheckLogin } from './useCheckLogin';

export const useCheckAlreadyLoggedInMethod = () => {
    const { setIsLoading } = useLoginContext();
    const loginRedirect = useLoginRedirect();
    const checkLogin = useCheckLogin(loginRedirect, () => {
        setIsLoading(false);
    });

    return () => {
        setIsLoading(true);
        checkLogin.mutate();
    };
};
