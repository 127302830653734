import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const GoogleSigninButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

// Create Fake Google button to hide button created by Google Identity Services
// Why : we can not customize it like expected design
export const GoogleSigninButtonFake = styled.div`
    display: flex;
    width: 100%;
    height: 56px;
    align-items: center;
    justify-content: center;
    background-color: ${HEX_COLORS.white};
    font-weight: bold;
    color: ${HEX_COLORS.black};
    border-radius: 8px;
    border: solid 1px ${HEX_COLORS.blackberry};
    font-size: 18px;
    letter-spacing: inherit;
    padding-left: 20px; // Google logo is a bit wider compared to FA icon for SSO sign-on
    padding-right: 25px;
    text-align: center;
    &:hover {
        cursor: pointer;
    }
    > img {
        margin-right: auto;
    }
`;

export const GoogleSigninTextFake = styled.span`
    font-size: 18px;
    font-weight: 700;
    color: ${HEX_COLORS.blackberry};
    margin-right: auto;
`;

export const GoogleSigninButton = styled.div`
    position: absolute;
    top: 10px;
    width: 100%;
    height: 60px;
    opacity: 0;
    overflow: hidden;
`;

export const GoogleSigninError = styled.div`
    display: flex;
    align-items: center;
    min-height: 50px;
    background-color: ${({ theme }) => theme.colors.danger.alpha};
    color: ${({ theme }) => theme.colors.danger.initial};
    width: 100%;
    margin-top: 16px;
    border-radius: 8px;
    padding: 0 16px;

    > img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
`;
