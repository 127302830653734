import styled from '@emotion/styled';

export const LOGIN_PAGE_MEDIA_BREAKPOINT = 'max-height: 680px';

export const LoginRightPartContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-top: 104px;
    gap: 8px;
    padding-inline: 48px;
    text-align: center;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    @media (${LOGIN_PAGE_MEDIA_BREAKPOINT}) {
        padding-top: 56px;
    }
`;

export const DescriptionContainer = styled.div`
    max-width: 510px;
`;
