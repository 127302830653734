import { FC, useEffect, useState } from 'react';

import { Banner } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

import ProviderLogo from 'app/common/components/ProviderLogo/ProviderLogo';
import getLang from 'app/common/services/getLang';
import i18n from 'app/common/translations/i18n';
import { STATIC_BASE_URL } from 'app/config';
import { isBrowserOutdated } from 'app/pages/Login/utils/isBrowserOutdated';

import { useIsWhitelistProvider } from './hooks/loginModals/useIsWhitelistProvider';
import { useMobileAppBanner } from './hooks/loginModals/useMobileAppBanner';
import { LoginProvider } from './hooks/useLoginContext';
import { useProviderFromUrl } from './hooks/useProviderFromUrl';
import {
    ConnectionPageContainer,
    ConnectionPageLeftPart,
    ConnectionPageLeftPartContent,
    ConnectionPageRightPart,
} from './Login.styled';
import { DownloadMobileApp } from './loginModals/DownloadMobileApp';
import { ResponsiveSettingsModal } from './loginModals/ResponsiveSettingsModal';

export const Login: FC = () => {
    const provider = useProviderFromUrl();
    const isWhiteList = useIsWhitelistProvider();

    // We have to put this here because both components need the same data
    // useLocalStorage is not sync between components, we need to use a shared state, it needs a refacto
    const [visibleMobileApp, setVisibleMobileApp] = useMobileAppBanner();

    useEffect(() => {
        const browserLang = getLang();
        if (i18n.language !== browserLang) {
            i18n.changeLanguage(browserLang);
        }
    }, []);

    return (
        <div>
            <DownloadMobileApp visible={visibleMobileApp} setVisible={setVisibleMobileApp} />
            <ResponsiveSettingsModal visibleMobileApp={visibleMobileApp} />

            <ConnectionPageContainer>
                <ConnectionPageLeftPart>
                    <ConnectionPageLeftPartContent>
                        <ProviderLogo providerLabel={provider} />
                        <BrowserOutdatedBanner />

                        {/* Shared context between all the login pages */}
                        <LoginProvider>
                            {/* Used to render the nested routes */}
                            <Outlet />
                        </LoginProvider>
                    </ConnectionPageLeftPartContent>
                </ConnectionPageLeftPart>
                {isWhiteList && (
                    <ConnectionPageRightPart>
                        <img
                            src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/partoo.svg`}
                            alt="partoo"
                        />
                    </ConnectionPageRightPart>
                )}
            </ConnectionPageContainer>
        </div>
    );
};

const BrowserOutdatedBanner = () => {
    const { t } = useTranslation();
    const [isShown, setIsShown] = useState<boolean>(isBrowserOutdated());

    if (!isShown) return null;

    const onClose = () => {
        setIsShown(false);
    };

    return (
        <Banner dataTrackId="browser_outdated_banner" variant="warning" onClose={onClose}>
            {t('warning_update_your_browser')}
        </Banner>
    );
};
