import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { LoginQueryKeys } from 'app/pages/Login/utils/enums';
import { useLogout } from 'app/pages/Logout/hooks/useLogout';
import { CONNECTION_PATH } from 'app/routing/routeIds';
import { sdkBridge } from 'app/SDKBridge';
import { logoutResetReducersData } from 'app/states/configureStore';
import queryClient from 'app/states/queryClient';

// TODO: move to scripts
const removeGoogleGapi = () => {
    // @ts-expect-error
    if (window.gapi) {
        // @ts-expect-error
        const auth2 = window.gapi.auth2.getAuthInstance();

        if (auth2) {
            auth2
                .signOut()
                .then(() => {
                    auth2
                        .disconnect()
                        .then(() => {
                            console.warn('Google OAuth Logout');
                        })
                        .catch((err: any) => console.error(err));
                })
                .catch((err: any) => console.error(err));
        }
    }
};

/**
 * Handle the logout process
 * Empty the store and redirect to the connection page
 */
export const useHandleLogout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // nextUrl can be provided by the axios interceptor
    // So we can redirect the user to the current page after login
    const [nexUrl] = useStateQueryParams(LoginQueryKeys.NEXT_PAGE);

    const logout = useLogout(
        ({ next_url: provider_next_url }) => {
            queryClient.clear();
            sdkBridge.sendUserIsLoggedOut();
            dispatch(logoutResetReducersData());
            removeGoogleGapi();

            const redirectUrl = `${CONNECTION_PATH}?${LoginQueryKeys.NEXT_PAGE}=${nexUrl}`;

            if (!provider_next_url) {
                navigate(redirectUrl);
            } else {
                // Redirect to specific url for some providers
                window.location.href = provider_next_url;
            }
        },
        () => {
            console.error('Something went wrong during the logout');
        },
    );

    useEffect(() => {
        logout.mutate();
    }, []);
};
