import { useEffect, useState } from 'react';

import { Trans } from 'react-i18next';
import { Navigate, useLocation } from 'react-router';

import LoadingView from 'app/common/components/LoadingView';
import { SUPPORTED_LANGUAGES } from 'app/common/services/languages';
import i18n from 'app/common/translations/i18n';
import { StyledContent } from 'app/pages/privacyPolicy/PrivacyPolicy.styled';

import { NOT_FOUND_PATH } from 'app/routing/routeIds';

import { useMessagingPrivacyPolicy } from './hook/useMessagingPrivacyPolicy';

const LANG_PARAM_KEY = 'lang';

export const MessagingPrivacyPolicy = () => {
    const { data, isLoading, isError } = useMessagingPrivacyPolicy();
    const [isI18nInitializing, setIsI18nInitializing] = useState(true);
    const [languageLoaded, setLanguageLoaded] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const lang = searchParams.get(LANG_PARAM_KEY);

    useEffect(() => {
        if (i18n.isInitializing) {
            setIsI18nInitializing(true);
        } else {
            setIsI18nInitializing(false);
        }
    }, [i18n.isInitializing]);

    useEffect(() => {
        const targetLang = lang && SUPPORTED_LANGUAGES.includes(lang) ? lang : 'en';

        i18n.changeLanguage(targetLang)
            .then(() => {
                setIsI18nInitializing(false);
                setLanguageLoaded(true);
            })
            .catch(err => {
                console.error('Erreur lors du changement de langue:', err);
            });
    }, [location.search]);

    const content = () => {
        return (
            data && (
                <Trans
                    values={{
                        id: data.id,
                        company_name: data.company_name,
                        company_address: data.company_address,
                        company_registration_city: data.company_registration_city,
                        company_registration_number: data.company_registration_number,
                        company_type: data.company_type,
                        dpo_name: data.dpo_name,
                        dpo_email: data.dpo_email,
                        dpo_address: data.dpo_address,
                        share_capital: data.share_capital,
                    }}
                    i18nKey="privacy_template"
                    components={{
                        h1: <h1 />,
                        h3: <h3 />,
                        h4: <h4 />,
                        strong: <strong />,
                        a: <a />,
                        ul: <ul />,
                        li: <li />,
                        br: <br />,
                        i: <i />,
                    }}
                />
            )
        );
    };

    if (isLoading || isI18nInitializing || !languageLoaded) {
        return <LoadingView />;
    }

    if (isError || !data) {
        return <Navigate replace to={NOT_FOUND_PATH} />;
    }

    return <StyledContent>{content()}</StyledContent>;
};
