import { useEffect, useMemo } from 'react';

import { Snackbar } from '@partoohub/ui';
import { Helmet } from 'react-helmet';

import QuickAction from 'app/addon/QuickAction';
import { FEATURE_ENUM } from 'app/api/types/plans_features';
import ReactPage from 'app/common/components/ReactPage';
import IS_IFRAME from 'app/common/data/iframe';
import WHITELIST_PROVIDERS from 'app/common/data/providers';
import { useHasBusinessWithFeature } from 'app/common/hooks/accessesAndPermissions/businessSubcriptions/useHasBusinessWithFeature';
import { useCanSendClevertapEvents } from 'app/common/hooks/queries/useCanSendClevertapEvents';
import { PushNotifsEvent } from 'app/common/services/pushNotifications/events';
import { PushNotificationsWrapper } from 'app/common/services/pushNotifications/pushNotificationsWrapper';
import { useHandlePostLoginScripts } from 'app/common/services/scripts/postLogin';
import { handleIdentifyUserOnLogin } from 'app/common/services/scripts/postLogin/handleIdentifyUserOnLogin';
import { STATIC_BASE_URL } from 'app/config';
import { useMe } from 'app/pages/app/AppDataProvider/hooks/useMe';
import { useMyOrganization } from 'app/pages/app/AppDataProvider/hooks/useMyOrganization';
import { TCModal } from 'app/pages/app/TCModal';
import { AppRoutes } from 'app/routing/App';

export const AppPage = () => {
    const canSendClevertapEvents = useCanSendClevertapEvents();

    const me = useMe();
    const organization = useMyOrganization();

    const subscriptions = {
        orgPmSubscribed: useHasBusinessWithFeature(FEATURE_ENUM.DIFFUSION),
        orgRmSubscribed: useHasBusinessWithFeature(FEATURE_ENUM.REVIEW_MANAGEMENT),
        orgRbSubscribed: useHasBusinessWithFeature(FEATURE_ENUM.REVIEW_INVITE),
        orgBmSubscribed: useHasBusinessWithFeature(FEATURE_ENUM.MESSAGES),
    };
    // TODO Move this one in useHandlePostLoginScripts ?
    useEffect(() => {
        handleIdentifyUserOnLogin(me, organization, subscriptions);
    }, []);

    useHandlePostLoginScripts();

    // TODO Move this one in useHandlePostLoginScripts ?
    useEffect(() => {
        if (canSendClevertapEvents) {
            PushNotificationsWrapper.getInstance().sendEvent(PushNotifsEvent.LAUNCH_WEB_APP);
        }
    }, [canSendClevertapEvents]);

    const faviconPath = useMemo(
        () =>
            me
                ? `${STATIC_BASE_URL}/images/providers_asset/${me.provider?.toLowerCase()}/${me.provider?.toLowerCase()}_favicon.png`
                : `${STATIC_BASE_URL}/images/providers_asset/partoo/partoo_favicon.png`,
        [me],
    );
    const appleTouchIconPath = useMemo(
        () =>
            me?.provider_name && WHITELIST_PROVIDERS.includes(me?.provider_name)
                ? `${STATIC_BASE_URL}/images/apple-touch-icon.png`
                : null,
        [me],
    );

    // ReactPage contains the SideBar and the app content once the user is logged in
    return (
        <ReactPage>
            <Helmet>
                <link rel="icon" type="image/png" href={faviconPath} />
                {appleTouchIconPath && <link rel="apple-touch-icon" href={appleTouchIconPath} />}
            </Helmet>
            {!IS_IFRAME && <TCModal />}
            <AppRoutes />
            <Snackbar dataTrackId="app__snackbar" />
            {!IS_IFRAME && <QuickAction />}
        </ReactPage>
    );
};
