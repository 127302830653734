import { put } from 'redux-saga/effects';

import { resetBusinessFilter } from 'app/common/components/businessModalDeprecated/reducers/filterByBusiness';
import { Saga } from 'app/common/types';
import { reInitReviewFilters } from 'app/states/reviews/filters';

const initialReviewAnalyticsDataLoadSaga = function* (): Saga {
    yield put(resetBusinessFilter());
    yield put(reInitReviewFilters());
};

export default initialReviewAnalyticsDataLoadSaga;
