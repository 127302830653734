import { useState } from 'react';

import { Banner } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ADMIN } from 'app/common/data/roles';
import env from 'app/common/services/getEnvironment';
import { DeviceType, useDeviceType } from 'app/hooks';
import { useMe } from 'app/pages/app/AppDataProvider/hooks/useMe';
import { useMyOrganization } from 'app/pages/app/AppDataProvider/hooks/useMyOrganization';

import { EnvBannerContainer } from './EnvBanner.styled';

const EnvBanner = () => {
    const { t } = useTranslation();
    const { device } = useDeviceType();
    const me = useMe();
    const org = useMyOrganization();

    const meOrgInfos = `${org.org_id} - ${org.name}`;
    const [isClosed, setIsClosed] = useState<boolean>(false);

    const canDisplayBanner =
        !isClosed &&
        !!me &&
        env.isProdEnv() &&
        device !== DeviceType.Mobile &&
        (me.role === ADMIN || me.shadow_user_role === ADMIN);

    const email = me?.email ?? '';
    const translation = t('connectas_banner');
    const text = `${translation} ${email} - (${meOrgInfos})`;

    const onClose = () => {
        setIsClosed(true);
    };

    if (!canDisplayBanner) return null;

    return (
        <EnvBannerContainer isDesktop={device === DeviceType.Desktop}>
            <Banner dataTrackId="env_banner" onClose={onClose}>
                {text}
            </Banner>
        </EnvBannerContainer>
    );
};

export default EnvBanner;
