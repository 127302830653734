import { Suspense, lazy } from 'react';

import { ErrorBoundary } from '@partoohub/ui';
import { Navigate, Route, Routes, useLocation } from 'react-router';

import PageErrorPlaceholder from 'app/common/components/errors/PageErrorPlaceholder';
import { Layout } from 'app/common/components/Layout';
import LoadingView from 'app/common/components/LoadingView';
import IS_IFRAME from 'app/common/data/iframe';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useCanAccessCustomerExperiencePage } from 'app/pages/customerExperience/useCanAccessCustomerExperiencePage';
import { NotFoundPage } from 'app/pages/notFoundPage/NotFoundPage';

import {
    ANALYTICS_PATH,
    CONVERSATIONS_PATH,
    CUSTOMER_EXPERIENCE_PATH_TOKEN,
    DEFAULT_PAGE_PATH,
    HOMEPAGE_PATH,
    POSTS_PATH,
    SETTINGS_PATH,
    VISIBILITY_PATH,
} from 'app/routing/routeIds';

const AnalyticsRoutes = lazy(() => import('app/routing/subRoutes/Analytics'));
const ConversationsRoutes = lazy(() => import('app/routing/subRoutes/Conversations'));
const CustomerExperienceRoutes = lazy(() => import('app/routing/subRoutes/CustomerExperience'));
const HomeRoutes = lazy(() => import('app/routing/subRoutes/Home'));
const SettingsRoutes = lazy(() => import('app/routing/subRoutes/settings'));
const VisibilityRoutes = lazy(() => import('app/routing/subRoutes/Visibility'));
const PostsRoutes = lazy(() => import('app/routing/subRoutes/Posts'));

const renderWithSuspense = (component: JSX.Element) => (
    <Suspense fallback={<LoadingView />}>{component}</Suspense>
);

const CommonRoutes = (
    <>
        {/* DEFAULT PAGE */}
        <Route index element={<Navigate replace to={DEFAULT_PAGE_PATH} />} />
        <Route path={`${HOMEPAGE_PATH}/*`} element={renderWithSuspense(<HomeRoutes />)} />
        <Route path={`${VISIBILITY_PATH}/*`} element={renderWithSuspense(<VisibilityRoutes />)} />
        <Route
            path={`${CONVERSATIONS_PATH}/*`}
            element={renderWithSuspense(<ConversationsRoutes />)}
        />
        <Route
            path={`${CUSTOMER_EXPERIENCE_PATH_TOKEN}/*`}
            element={renderWithSuspense(<CustomerExperienceRoutes />)}
        />
        <Route path={`${ANALYTICS_PATH}/*`} element={renderWithSuspense(<AnalyticsRoutes />)} />
        <Route path={`${POSTS_PATH}/*`} element={renderWithSuspense(<PostsRoutes />)} />
        <Route path={`${SETTINGS_PATH}/*`} element={renderWithSuspense(<SettingsRoutes />)} />
        {/* NOT FOUND ROUTES */}
        <Route path="*" element={<NotFoundPage />} />
    </>
);

export const App = () => {
    const { data: me } = useMe();
    // Make sure the data is here before loading the app

    const { isLoading: customerExperiencePageAccessIsLoading } =
        useCanAccessCustomerExperiencePage();

    const isDataLoading = !me || customerExperiencePageAccessIsLoading;

    if (isDataLoading) {
        return <LoadingView />;
    }

    return (
        <ErrorBoundary dataTrackId="" FallbackComponent={PageErrorPlaceholder}>
            <Routes>
                <Route path="/" element={<Layout />}>
                    {CommonRoutes}
                </Route>
            </Routes>
        </ErrorBoundary>
    );
};

export const AppRoutes = App;
