import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

type LoginFormInputProps = {
    isLoading?: boolean;
};

export const LoginFormContainer = styled.div<LoginFormInputProps>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 16px;

    ${({ isLoading = false }) =>
        isLoading &&
        css`
            // We don't want display: none because it causes issues with the animations
            opacity: 0;
            position: absolute;
        `}
`;

export const LoginFormSuccessImage = styled.img`
    margin-bottom: 16px;
    width: 100%;
`;

export const LoginFormSuccessMessage = styled.div`
    display: flex;
    align-items: center;
    min-height: 50px;
    background-color: ${({ theme }) => theme.colors.success.alpha};
    color: ${({ theme }) => theme.colors.success.initial};
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 16px;

    > img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
`;

export const LoginFormMessage = styled.div`
    display: flex;
    align-items: first baseline;
    margin-bottom: 24px;
    color: ${({ theme }) => theme.colors.secondary.initial};

    > i {
        margin-right: 4px;
    }
`;
