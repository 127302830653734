import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';

export const useLostPassword = (
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) => {
    return useMutation((email: string) => api.connection.lostPassword({ email }), {
        onSuccess,
        onError,
    });
};
