import { useEffect } from 'react';

import IS_IFRAME from 'app/common/data/iframe';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { LoginQueryKeys } from 'app/pages/Login/utils/enums';

import { generateLoginHeaders } from 'app/pages/Login/utils/generateLoginHeaders';

import { useApiKeyConnexionTokenLoginMethod } from './autoLoginMethods/useApiKeyConnexionTokenLoginMethod';
import { useCheckAlreadyLoggedInMethod } from './autoLoginMethods/useCheckAlreadyLoggedInMethod';
import { useJwtLoginMethod } from './autoLoginMethods/useJwtLoginMethod';
import { useSsoOidcLoginMethod } from './autoLoginMethods/useSsoOidcLoginMethod';

/**
 * Automatically log the user in if the necessary parameters are present in the URL
 * or if the user is already logged in
 */
export const useAutoLogin = () => {
    const [ssoAlias] = useStateQueryParams(LoginQueryKeys.SSO_ALIAS);
    const [apiKey] = useStateQueryParams(LoginQueryKeys.API_KEY);
    const [connectionToken] = useStateQueryParams(LoginQueryKeys.CONNECTION_TOKEN);
    const [googleIdToken] = useStateQueryParams(LoginQueryKeys.GOOGLE_ID_TOKEN);

    const headers = generateLoginHeaders(apiKey, connectionToken);

    const ssoOidcLoginMethod = useSsoOidcLoginMethod(ssoAlias);
    const apiKeyConnexionTokenLoginMethod = useApiKeyConnexionTokenLoginMethod(headers);
    const jwtLoginMethod = useJwtLoginMethod(headers);

    const checkAlreadyLoggedInMethod = useCheckAlreadyLoggedInMethod();

    useEffect(() => {
        // For Google login, we let the GoogleSignInButton component handle the Google autologin
        // Only used in slate envs
        if (googleIdToken) {
            return;
        }

        // Handle SSO OIDC login (SAML is handled by the server, so it goes it the checkAlreadyLoggedIn)
        else if (ssoAlias) {
            ssoOidcLoginMethod();
        }

        // Handle apiKey and connectionToken (connect as)
        else if (apiKey || connectionToken) {
            if (IS_IFRAME) {
                jwtLoginMethod();
            } else {
                apiKeyConnexionTokenLoginMethod();
            }
        }

        // Check if the user is not already logged in, and if so, redirect to the next page
        else {
            checkAlreadyLoggedInMethod();
        }
    }, []);
};
