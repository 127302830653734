import { browserTracingIntegration } from '@sentry/browser';
import * as Sentry from '@sentry/react';

import { ENV, SENTRY_DSN } from 'app/config';

if (SENTRY_DSN !== '') {
    // https://docs.sentry.io/platforms/javascript/guides/react/
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: ENV,
        integrations: [browserTracingIntegration()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
