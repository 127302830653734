import { LoginHeaders } from 'app/api/types/connection';
import { API_KEY, CONNECTION_TOKEN } from 'app/common/data/headers';

export const generateLoginHeaders = (apiKey: string, connectionToken: string): LoginHeaders => {
    const headers: LoginHeaders = {};

    if (apiKey) {
        headers[API_KEY] = apiKey;
    } else {
        headers[CONNECTION_TOKEN] = connectionToken;
    }

    return headers;
};
