import styled from '@emotion/styled';

import { LOGIN_PAGE_MEDIA_BREAKPOINT } from 'app/pages/Login/LoginRightPartContent/LoginRightPartContent.styled';

export const MobileSvgContainer = styled.div`
    margin-top: 48px;
    width: 100%;

    > svg {
        width: 100%;
        max-width: min(100%, 750px);
    }

    @media (${LOGIN_PAGE_MEDIA_BREAKPOINT}) {
        > svg {
            width: calc(100dvh * 0.85);
        }
    }
`;
