import { format, subMonths } from 'date-fns';
import { isEqual, omit } from 'lodash-es';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import api from 'app/api/v2/api_calls';
import { filterByBusinessSelector } from 'app/common/components/businessModalDeprecated/reducers';
import camelCasify from 'app/common/services/CamelCasify';
import { Saga } from 'app/common/types';
import {
    ReviewFiltersState,
    activeReviewFiltersSelector as activeFilters,
    dateFilterSelector,
    reviewFiltersSelector,
} from 'app/states/reviews/filters';

import { ISO_DATETIME_FORMAT_WITH_TIMEZONE } from 'app/utils/dates';

import {
    GET_REVIEW_STATS_PERIOD_REQUEST,
    GET_REVIEW_STATS_TOTAL_REQUEST,
    getReviewStatsPeriodFailureAction,
    getReviewStatsPeriodSuccessAction,
    getReviewStatsTotalFailureAction,
    getReviewStatsTotalSuccessAction,
} from '../actions';
import { ReviewAnalyticsState } from '../reducers';
import { reviewAnalyticsSelector } from '../selectors';
import { filterQueryParams } from '../services';

function* getReviewStats(period: boolean) {
    try {
        const reviewFiltersState = yield select(reviewFiltersSelector) as any as ReviewFiltersState;

        const reviewAnalyticsState = yield select(
            reviewAnalyticsSelector,
        ) as any as ReviewAnalyticsState;

        const filterByBusiness = yield select(filterByBusinessSelector);

        const filters = filterQueryParams(
            period
                ? reviewFiltersState
                : {
                      ...reviewFiltersState,
                      date: {
                          from: null,
                          to: null,
                      },
                  },
            reviewAnalyticsState,
            filterByBusiness,
        );

        const date = dateFilterSelector(reviewFiltersState);

        if (!(date.from || date.to) && period) {
            filters.update_date__gte = format(
                subMonths(new Date(), 6),
                ISO_DATETIME_FORMAT_WITH_TIMEZONE,
            );
        }

        const {
            average,
            satisfied,
            neutral,
            dissatisfied,
            answerTime,
            notTreated,
            total,
            treated,
        } = camelCasify(
            yield call(api.review.getReviewStats, omit(filters, ['cumulative', 'bucket'])),
        );

        const newReviewFiltersState = yield select(
            reviewFiltersSelector,
        ) as any as ReviewFiltersState;

        if (isEqual(activeFilters(reviewFiltersState), activeFilters(newReviewFiltersState))) {
            if (period) {
                yield put(
                    getReviewStatsPeriodSuccessAction(
                        average,
                        satisfied,
                        neutral,
                        dissatisfied,
                        answerTime,
                        notTreated,
                        total,
                        treated,
                        total ? Math.round((treated * 100) / total) : 0,
                    ),
                );
            } else {
                yield put(
                    getReviewStatsTotalSuccessAction(
                        average,
                        satisfied,
                        neutral,
                        dissatisfied,
                        answerTime,
                        notTreated,
                        total,
                        treated,
                        total ? Math.round((treated * 100) / total) : 0,
                    ),
                );
            }
        }
    } catch (error) {
        if (period) {
            yield put(getReviewStatsPeriodFailureAction(error));
        } else {
            yield put(getReviewStatsTotalFailureAction(error));
        }
    }
}

function* getAverageGradeSaga(): Saga {
    yield takeLatest(GET_REVIEW_STATS_TOTAL_REQUEST, getReviewStats, false);
    yield takeLatest(GET_REVIEW_STATS_PERIOD_REQUEST, getReviewStats, true);
}

export default getAverageGradeSaga;
