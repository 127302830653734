import { ReviewAnalyticsState, evolutionNameMaker } from '../reducers';

export const reviewAnalyticsSelector = (state: any): ReviewAnalyticsState =>
    state.reviewAnalytics;

export const totalReviewsSelector = (state: ReviewAnalyticsState): number =>
    state.ratings.totalReviews;

export const periodReviewsSelector = (state: ReviewAnalyticsState): number =>
    state.ratings.periodReviews;

export const totalAnswerRateSelector = (state: ReviewAnalyticsState): number =>
    state.answerRate.totalAnswerRate;

export const periodAnswerRateSelector = (state: ReviewAnalyticsState): number =>
    state.answerRate.periodAnswerRate;

export const totalAnswerTimeSelector = (state: ReviewAnalyticsState): number =>
    state.answerRate.estimatedTotalTimeAnswerRate;

export const periodAnswerTimeSelector = (state: ReviewAnalyticsState): number =>
    state.answerRate.estimatedPeriodTimeAnswerRate;

export const averageGradeTotalSelector = (
    state: ReviewAnalyticsState,
): number => state.averageGrade.total.value;

export const averageGradeTotalLabelsSelector = (
    state: ReviewAnalyticsState,
): Array<string> => Object.keys(state.averageGrade.total.labels);

export const averageGradePeriodSelector = (
    state: ReviewAnalyticsState,
): number => state.averageGrade.period.value;

export const averageGradePeriodLabelsSelector = (
    state: ReviewAnalyticsState,
): Array<string> => Object.keys(state.averageGrade.period.labels);

export const averageGradeTotalLabelValueSelector = (
    state: ReviewAnalyticsState,
    label: string,
): number => state.averageGrade.total.labels[label];

export const averageGradePeriodLabelValueSelector = (
    state: ReviewAnalyticsState,
    label: string,
): number => state.averageGrade.period.labels[label];

export const reviewEvolutionAnalyticsSelector = (
    state: ReviewAnalyticsState,
    isQualitative: boolean,
    isPeriod: boolean,
): Array<Record<string, any>> =>
    state.evolution.analytics[evolutionNameMaker(isQualitative, isPeriod)];

export const reviewEvolutionFetchingSelector = (
    state: ReviewAnalyticsState,
): boolean => state.evolution.analytics.evolutionFetching;

export const isFetchingPeriodSelector = (
    state: ReviewAnalyticsState,
): boolean => state.isFetchingPeriod;

export const isFetchingTotalSelector = (state: ReviewAnalyticsState): boolean =>
    state.isFetchingTotal;

export const isPeriodSelector = (state: ReviewAnalyticsState): boolean =>
    state.evolution.isPeriod;

export const isQualitativeSelector = (state: ReviewAnalyticsState): boolean =>
    state.evolution.isQualitative;

export const bucketSelector = (state: ReviewAnalyticsState): string =>
    state.evolution.bucket;
