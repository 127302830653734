import { useMutation } from 'react-query';

import { AuthTokenReceivedData } from 'app/api/types/auth_token';
import api from 'app/api/v2/api_calls';

/**
 * Used to login with JWT, only called from IFRAME
 * ⚠️ Will create a session cookie
 */
export const useJwtLogin = (
    onSuccess: (result: AuthTokenReceivedData) => void = () => undefined,
    onError: () => void = () => undefined,
) => {
    return useMutation((headers: Record<string, any> = {}) => api.authToken.getAuthToken(headers), {
        onSuccess,
        onError,
    });
};
