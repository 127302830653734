import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

import { ResetPassword } from './ResetPassword';

export const ResetPasswordPage = () => {
    const { t } = useTranslation();
    return (
        <>
            <DynamicPageHeader title={t('reset_password')} />
            <ResetPassword />
        </>
    );
};
