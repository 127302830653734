import useMe from 'app/common/hooks/queries/useMeUncamel';
import { STATIC_COMMON_ASSETS_DIR } from 'app/config';

import { usePartooThemeContext } from 'app/Theme';

import { ProviderLogoLinkWithInnerSvg, ProviderLogoWithBackground } from './SidebarLogo.styled';

export const SidebarLogo = () => {
    const { data: me } = useMe();
    const { theme } = usePartooThemeContext();
    const logoSrc = `${STATIC_COMMON_ASSETS_DIR}/logos/partoo/partoo_logo_${theme}.svg`;

    if (!me?.provider_logo) {
        return (
            <ProviderLogoLinkWithInnerSvg to="/" data-partoorobot="product-sidebar-logo">
                <img src={logoSrc} alt="logo" />
            </ProviderLogoLinkWithInnerSvg>
        );
    }
    return (
        <ProviderLogoWithBackground>
            <ProviderLogoLinkWithInnerSvg
                to="/"
                data-partoorobot="product-sidebar-logo"
                {...{
                    dangerouslySetInnerHTML: {
                        __html: me.provider_logo,
                    },
                }}
            />
        </ProviderLogoWithBackground>
    );
};
