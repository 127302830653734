import { useLocation, useNavigate } from 'react-router';

import { useReduxEffect } from 'app/common/hooks/useReduxEffect';
import { LoginQueryKeys } from 'app/pages/Login/utils/enums';
import { LOG_OUT_PATH } from 'app/routing/routeIds';

const AXIOS_INTERCEPTOR_401 = 'AXIOS_INTERCEPTOR_401';
export const axiosInterceptor401 = () => ({ type: AXIOS_INTERCEPTOR_401 });

export const useHandleAxiosInterceptor = () => {
    const navigate = useNavigate();

    // We also provide the current URL as a query parameter to the logout page
    // It will be used to redirect the user to the current page after login
    const { pathname, search, hash } = useLocation();
    const url = `${pathname}${search}${hash}`;

    const params = new URLSearchParams({
        [LoginQueryKeys.NEXT_PAGE]: url,
    });

    const redirectUrl = `${LOG_OUT_PATH}?${params.toString()}`;

    // Redirect to logout page on 401 error
    useReduxEffect(() => {
        navigate(redirectUrl);
    }, [AXIOS_INTERCEPTOR_401]);
};
