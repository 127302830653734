import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

import { Invitation } from './Invitation';

export const InvitationPage = () => {
    const { t } = useTranslation();
    return (
        <>
            <DynamicPageHeader title={t('create_an_account')} />
            <Invitation />
        </>
    );
};
