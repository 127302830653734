import { Button, ButtonVariants } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useLoginContext } from 'app/pages/Login/hooks/useLoginContext';

type Props = {
    handleSubmit: () => void;
    isSuccess: boolean;
    fieldsErrors: any;
    globalErrors: any;
};

const LoginButton = ({ handleSubmit, isSuccess, fieldsErrors, globalErrors }: Props) => {
    const { t } = useTranslation();
    const { email, password, isLoading } = useLoginContext();

    const getStatusObj = (): {
        variant: ButtonVariants;
        label: string;
    } => {
        if (isSuccess) {
            return {
                variant: 'success',
                label: 'connection_to_app__success',
            };
        }

        // Should never happen because there is the loading placeholder ?
        if (isLoading) {
            return {
                variant: 'secondary',
                label: 'loading',
            };
        }

        if (email === '' || password === '') {
            return {
                variant: 'secondary',
                label: 'connection_to_app',
            };
        }

        if (fieldsErrors || globalErrors) {
            let label;
            if (fieldsErrors?.email) {
                label = 'Email not valid';
            } else if (fieldsErrors?.password) {
                label = 'Password too short';
            } else if (globalErrors === 'admin_must_sign_in_with_google') {
                label = 'admin_must_sign_in_with_google';
            } else if (globalErrors === 'Too many login attempts') {
                label = 'too_many_login_attempts';
            } else {
                label = 'invalid credentials';
            }
            return {
                variant: 'danger',
                label,
            };
        }

        return {
            variant: 'primary',
            label: 'connection_to_app',
        };
    };

    const { variant, label } = getStatusObj();

    const onClick = () => {
        if (!['secondary', 'success'].includes(variant)) {
            handleSubmit();
        }
    };

    return (
        <Button
            type="submit"
            size="large"
            shape="cube"
            full
            variant={variant}
            dataTrackId="login__submit_button"
            onClick={onClick}
            disabled={variant === 'secondary'}
        >
            {t(label)}
        </Button>
    );
};

export default LoginButton;
