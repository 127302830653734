import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const ProviderLogoLinkWithInnerSvg = styled(Link)<{ isPartooProvider?: boolean }>`
    height: 40px;
    svg {
        height: 40px;
    }
    ${({ isPartooProvider }) =>
        !isPartooProvider &&
        css`
            width: 40px;
            svg {
                width: 40px;
            }
        `}
`;

export const ProviderLogoWithBackground = styled.div`
    height: 40px;
    width: 40px;
    background-color: ${({ theme }) => theme.colors.default.alpha};
    border-radius: ${({ theme }) => theme.radius.large};
`;
