import { StyledContent } from 'app/pages/privacyPolicy/PrivacyPolicy.styled';
import { MessagingPrivacyPolicyData } from 'app/pages/privacyPolicy/types';

type Props = {
    data: MessagingPrivacyPolicyData;
};

const PrivacyPolicyTemplateDe = ({ data }: Props) => (
    <StyledContent>
        <h1>
            <strong>DATENSCHUTZERKLÄRUNG</strong>
        </h1>
        <br />
        Wir legen größten Wert auf den Schutz personenbezogener Daten und die Achtung des
        Privatlebens. Mit dieser Datenschutzerklärung möchten wir Sie informieren, auf welche Weise
        wir Ihre personenbezogenen Daten erheben, verwenden und übermitteln, wenn Sie mit uns über
        den Chat (der „<strong>Chat</strong>“) kommunizieren. Dabei halten wir die in Frankreich
        geltenden Gesetze wie das Datenschutzgesetz Nr. 78-17 vom 6. Januar 1978 zu Informatik,
        Dateien und Freiheiten, geänderte Fassung („
        <strong>LIL</strong>“) und die Verordnung (EU 2016/679) vom 27. April 2016 zum Schutz
        personenbezogener Daten („<strong>DSGVO</strong>”) ein.
        <br />
        <br /> Ferner wird darauf hingewiesen, dass die Verwendung Ihrer personenbezogenen Daten
        auch der Datenschutzrichtlinie des verwendeten Chats unterliegt (zum Beispiel für Google:{' '}
        <a href="https://policies.google.com/privacy?gl=IT&hl=de">
            {' '}
            https://policies.google.com/privacy{' '}
        </a>
        ).
        <br />
        <br /> Jede Änderung oder Aktualisierung dieser Datenschutzerklärung wird Ihnen
        ordnungsgemäß zur Kenntnis gebracht.
        <br />
        <br />
        <h3>
            <strong>1. Definitionen </strong>
        </h3>
        „<strong>Chat</strong>“ bezeichnet den Instant Messaging-Dienst, den Sie für die
        Kommunikation mit uns verwenden.
        <br />
        <br />„<strong>Daten</strong>“ bezeichnet alle Informationen, die vom Nutzer über den Chat
        an das Unternehmen übermittelt werden, einschließlich personenbezogener Daten
        <br />
        <br />„<strong>Personenbezogene Daten</strong>“ oder „<strong>persönliche Daten</strong>“
        bezeichnet alle Informationen in Bezug auf eine natürliche Person, die durch Verweis auf
        eine Identifizierungsnummer oder ein Element oder mehrere Elemente, die ihr eigen sind,
        direkt oder indirekt identifiziert oder identifizierbar ist.
        <br />
        <br />„<strong>Sensible personenbezogene Daten</strong>“ bezeichnet alle Informationen
        bezüglich der rassischen oder ethnischen Herkunft, politischer Meinungen, Weltanschauung
        oder Religion, Gewerkschaftszugehörigkeit, des Gesundheitszustands oder der sexuellen
        Orientierung sowie genetische oder biometrische Daten. Grundsätzlich ist die Verarbeitung
        sensibler Daten untersagt, außer insbesondere wenn die betroffene Person ihre ausdrückliche
        Einwilligung erklärt hat.
        <br />
        <br />„<strong>Verantwortlicher</strong>“ bezeichnet die natürliche oder juristische Person,
        die die Zwecke und Mittel einer Datenverarbeitung festlegt, und in diesem besonderen Fall
        das Unternehmen.
        <br />
        <br />„<strong>Auftragsverarbeiter</strong>“ bezeichnet die natürliche oder juristische
        Person, die personenbezogene Daten für uns und gemäß unseren Weisungen im Rahmen der Nutzung
        des Chats verarbeitet.
        <br />
        <br />„<strong>Verarbeitung</strong>“ bezeichnet jeden Vorgang oder eine Gesamtheit von
        Vorgängen bezüglich personenbezogener Daten, unabhängig vom verwendeten Verfahren (Erhebung,
        Registrierung, Organisation, Speicherung, Anpassung, Änderung, Auslesen, Abfragen,
        Verwendung, Kommunikation durch Übermittlung oder Verbreitung oder jede andere Form der
        Bereitstellung oder des Abgleichs), die vom Unternehmen im Rahmen der Nutzung des Chats
        durchgeführt werden.
        <br />
        <br />„<strong>Nutzer</strong>“ oder „<strong>Sie/Ihr/Ihre</strong>“ bezeichnet jede
        natürliche Person, die über den Chat Kontakt zum Unternehmen aufnimmt.
        <br />
        <br />
        <h3>
            <strong>
                2. Wer ist der Verantwortliche für die Verarbeitung Ihrer personenbezogenen Daten?
            </strong>
        </h3>
        Der Verantwortliche, der Ihre Daten erhebt und verwaltet, ist das Unternehmen{' '}
        {data.company_name}, Rechtsform {data.company_type} mit einem Kapital von{' '}
        {data.share_capital} Euro und dem Geschäftssitz in {data.company_address}, eingetragen im
        Handelsregister {data.company_registration_city} unter der Nummer{' '}
        {data.company_registration_number} (das „<strong>Unternehmen</strong>“), vertreten durch
        seinen Geschäftsführer oder Präsidenten.
        <br />
        <br />
        <h3>
            <strong>3. Kategorien von erhobenen personenbezogenen Daten</strong>
        </h3>
        Während der Nutzung des Chats können wir die folgenden Kategorien von personenbezogenen
        Daten erheben: <br />
        <ul>
            <li>
                <strong>Familienstand</strong> : Name, Vorname,
            </li>
            <li>
                <strong>Bild</strong> (Profilfoto Ihres Kontos im verwendeten Chat).
            </li>
        </ul>
        Wir können ferner alle personenbezogenen Daten erheben, die Sie uns auf Ihre eigene
        Initiative während unseres Austauschs über den Chat bereitstellen (Auftragsnummer,
        Postanschrift und/oder Telefonnummern, Fotos usw.). Beachten Sie, dass wir Sie niemals um
        die Mitteilung von sensiblen personenbezogenen Daten über den Chat bitten werden, und wir
        empfehlen Ihnen dringend, keine solchen Daten von sich aus zu übermitteln, um eine größere
        Sicherheit der ausgetauschten Informationen zu gewährleisten.
        <br />
        <br /> Sie verpflichten sich, aktualisierte und gültige personenbezogene Daten mitzuteilen,
        und Sie gewährleisten, dass Sie keine falschen Angaben machen und keine falschen
        Informationen bereitstellen. Sie dürfen keine personenbezogenen Daten zu dritten Personen
        über den Chat mitteilen.
        <br />
        <br />
        <h3>
            <strong>
                4. Obligatorischer oder fakultativer Charakter der erhobenen personenbezogenen Daten
            </strong>
        </h3>
        Für die Verarbeitung Ihrer Anfrage sind bestimmte Daten notwendig. Der obligatorische
        Charakter von angeforderten Daten wird Ihnen bei der Erhebung angezeigt. Eine unterlassene
        Mitteilung dieser Daten verhindert, dass wir Ihre Anfrage angemessen beantworten können.
        <br />
        <br /> Alle Daten, die nicht als obligatorisch angezeigt sind, sind fakultativ. Es steht
        Ihnen deshalb frei, sie anzugeben oder nicht. Sie können es uns allerdings erlauben, Ihre
        Anfrage auf präzisere Weise zu beantworten, und sie ermöglichen es, Ihre Nutzererfahrung bei
        unserem Austausch zu verbessern.
        <br />
        <br />
        <h3>
            <strong>5. Zwecke der Erhebung von personenbezogenen Daten</strong>
        </h3>
        Das Unternehmen erhebt Ihre personenbezogenen Daten im Rahmen des Chats zu den folgenden
        Zwecken:
        <br />
        <ul>
            <li>Lesen der eingesandten Mitteilungen und ihre optimale Beantwortung,</li>
            <li>
                Ermöglichung des Zugangs zum Chatverlauf zwischen dem Unternehmen und den Nutzern,
            </li>
            <li>
                Verbesserung des Dienstes, den wir Ihnen über den Chat bereitstellen (Verkürzung der
                Antwortzeiten usw.),
            </li>
            <li>Erstellung der Statistiken und Webanalysen,</li>
            <li>Bekämpfung von Betrug und Gewährleistung der Datensicherheit,</li>
            <li>
                Ausführung der geltenden gesetzlichen, regulatorischen und verwaltungsrechtlichen
                Bestimmungen.
            </li>
        </ul>
        Je nach Fall verarbeitet das Unternehmen Ihre personenbezogenen Daten auf der Basis einer
        der folgenden Rechtsgrundlagen:
        <br />
        <ul>
            <li>
                Ihre vorherige Einwilligung, als Sie Ihre Zustimmung zur Verarbeitung Ihrer Daten
                erklärt haben,
            </li>
            <li>die Ausführung eines Vertrags, der Sie an uns bindet, </li>
            <li>unsere gesetzlichen Pflichten, </li>
            <li>
                unser „berechtigtes Interesse“ im Sinne der geltenden Datenschutzgesetze. In diesem
                Fall werden wir bei der Bestimmung, ob die Verarbeitung gerechtfertigt und
                rechtmäßig ist, Ihre Interessen und Grundrechte berücksichtigen.
            </li>
        </ul>
        <br />
        <h3>
            <strong>6. Wer hat Zugang zu Ihren personenbezogenen Daten?</strong>
        </h3>
        <h4>
            <strong>6.1. Unser Personal</strong>
        </h4>
        Die erhobenen personenbezogenen Daten werden durch Personen verarbeitet, die im Unternehmen
        ordnungsgemäß bevollmächtigt sind, um Ihre Anfragen über den Chat zu beantworten.
        <br />
        <br />
        <h4>
            <strong>6.2. Unsere Auftragsverarbeiter</strong>
        </h4>
        Im Rahmen des Chats beauftragt das Unternehmen Auftragsverarbeiter, die die
        personenbezogenen Daten in seinem Auftrag und gemäß seinen Weisungen verarbeiten. Zur
        Gewährleistung der Sicherheit und Vertraulichkeit Ihrer Daten gemäß den Bestimmungen der
        DSGVO verlangen wir von unseren Auftragsverarbeitern, dass sie ausreichende und angemessene
        Garantien sowohl in technischer wie in organisatorische Hinsicht vorlegen.
        <br />
        <br /> Das Unternehmen beauftragt Auftragsverarbeiter, die in Ländern ansässig sind, die ein
        angemessenes Schutzniveau bieten, entweder weil sie den Bestimmungen der DSGVO unterliegen
        oder weil für sie ein Angemessenheitsbeschluss der Europäischen Kommission nach Artikel 45
        DSGVO vorliegt. Das Unternehmen verpflichtet sich, andernfalls alle geeigneten Mechanismen
        einzurichten, um ein angemessenes Schutzniveau für die Daten zu gewährleisten, insbesondere
        die von der Europäischen Kommission erstellten Standard-Vertragsklauseln.
        <br />
        <br /> Weitere Informationen zu den Garantien unserer Auftragsverarbeiter können Sie durch
        einfache Anfrage an die folgende Adresse erhalten: {data.dpo_address}.<br />
        <br />
        <h4>
            <strong>6.3. Andere mögliche Empfänger</strong>
        </h4>
        Gegebenenfalls kann das Unternehmen veranlasst sein, in den folgenden Fällen Ihre
        personenbezogenen Daten an Organisationen, Dritte, Verwaltungs- und Justizbehörden zu
        übermitteln:
        <br />
        <ul>
            <li>Ausführung der gesetzlichen und regulatorischen Pflichten, die uns obliegen,</li>
            <li>
                Identifizierung, Vermeidung oder Bearbeitung von betrügerischen Aktivitäten,
                Sicherheitslücken oder aller anderen technischen Probleme,
            </li>
            <li>Geltendmachung eines Rechts vor Gericht.</li>
        </ul>
        Wir versichern Ihnen, dass das Unternehmen Ihre personenbezogenen Daten ohne ihre vorherige
        Einwilligung nicht an dritte Partner weitergeben wird, um geschäftliche Vorgänge
        auszuführen.
        <br />
        <br />
        <h3>
            <strong>7. Wie lange bleiben Ihre personenbezogenen Daten gespeichert?</strong>
        </h3>
        Das Unternehmen speichert Ihre personenbezogenen Daten in einer Form, die Ihre
        Identifizierung erlaubt, in aktiver Datenbank nur solange es für die Ausführung der
        verfolgten Zwecke und für die sie erhoben und verarbeitet werden, unbedingt nötig ist, das
        heißt höchstens 1 Jahr ab unserem letzten Austausch per Chat.
        <br />
        <br />
        Ihre personenbezogenen Daten können auch in einem Archiv gespeichert werden, zu dem es einen
        geregelten, begrenzten und berechtigten Zugang gibt, 1.) solange es für die Einhaltung der
        rechtlichen, steuerlichen, buchhalterischen und regulatorischen Pflichten des Unternehmens
        nötig ist und/oder 2.) um es uns zu ermöglichen, einen Rechtsanspruch geltend zu machen,
        bevor sie endgültig gelöscht werden.
        <br />
        <br />
        <h3>
            <strong>
                8. Wie gewährleisten wir die Sicherheit und die Vertraulichkeit Ihrer
                personenbezogenen Daten?
            </strong>
        </h3>
        Das Unternehmen verpflichtet sich, Ihre personenbezogenen Daten auf rechtmäßige,
        verhältnismäßige transparente und erhebliche Weise und nach Treu und Glauben ausschließlich
        im Rahmen der verfolgten und angekündigten Zwecke, für die Dauer, die für die eingerichteten
        Verarbeitungen notwendig ist, und auf gesicherte Weise zu verarbeiten.
        <br />
        <br /> Das Unternehmen trifft und aktualisiert die geeigneten technischen und
        organisatorischen Maßnahmen, um die Sicherheit und Vertraulichkeit Ihrer personenbezogenen
        Daten zu gewährleisten und zu verhindern, dass sie verändert, zerstört, beschädigt oder an
        unbefugte Dritte weitergegeben werden. Diesbezüglich hat das Unternehmen physische,
        elektronische und organisatorische Schutzmaßnahmen getroffen, um Verlust, missbräuchliche
        Verwendung, unerlaubten Zugang oder unerlaubte Weitergabe, Änderung oder Zerstörung von
        personenbezogenen Daten zu verhindern. Da alle personenbezogenen Daten vertraulich sind, ist
        der Zugang darauf auf die Mitarbeiter, Dienstleister und Auftragsverarbeiter des
        Unternehmens beschränkt, die sie im Rahmen ihrer Aufgaben unbedingt kennen müssen. Alle
        Personen, die Zugang zu Ihren personenbezogenen Daten haben, sind an eine Pflicht zur
        Geheimhaltung gebunden und ihnen drohen Disziplinarmaßnahmen und/oder Sanktionen, wenn sie
        diese Pflichten verletzen.
        <br />
        <br /> Allerdings ist es wichtig, dass Sie darauf achten, jeden unbefugten Zugang zu Ihren
        personenbezogenen Daten zu verhindern, indem Sie per Chat nur diejenigen Daten mitteilen,
        die für die Verarbeitung Ihres Antrags notwendig sind.
        <br />
        <br />
        <h3>
            <strong>9. Welche Rechte haben Sie in Bezug auf Ihre personenbezogenen Daten?</strong>
        </h3>
        Sie haben die Möglichkeit, auf einfachen schriftlichen Antrag, den Sie an die folgende
        Adresse senden: {data.dpo_address}, Auskunft über Ihre personenbezogenen Daten zu erhalten,
        ihre Berichtigung oder Einschränkung, ihre Löschung oder Übertragbarkeit zu verlangen oder
        zu beantragen, dass Sie nicht mehr in der Datenbank des Unternehmens verzeichnet sind.
        <br />
        <br /> Im Rahmen des <strong>Auskunftsrechts</strong> ist es Ihnen gemäß Artikel 15 DSGVO
        gestattet, von uns die Bestätigung einzuholen, ob Ihre personenbezogenen Daten nicht mehr
        verarbeitet werden, sowie die Mitteilung von Informationen zu der Verarbeitung (insbesondere
        ihre Zwecke, die Kategorien von verarbeiteten personenbezogenen Daten und die Empfänger,
        denen die personenbezogenen Daten offengelegt werden, die Dauer der Datenspeicherung oder
        die Kriterien, die für die Bestimmung dieser Dauer verwendet werden, Ihre Rechte in Bezug
        auf die personenbezogenen Daten und das Recht, eine Beschwerde bei einer Aufsichtsbehörde
        einzureichen).
        <br />
        <br />
        Nach Artikel 16 DSGVO verleiht Ihnen das <strong> Recht auf Berichtigung</strong> das Recht,
        von uns zu verlangen, dass die Sie betreffenden personenbezogene Daten berichtigt,
        vervollständigt oder aktualisiert werden, wenn sie unrichtig, unvollständig, mehrdeutig oder
        veraltet sind.
        <br />
        <br /> Unter den in Artikel 17 DSGVO vorgesehenen Bedingungen können Sie auch die Löschung
        der Sie betreffenden personenbezogenen Daten verlangen.
        <br />
        <br />
        Sie können ferner verlangen, dass die Verarbeitung Ihrer personenbezogenen Daten gemäß
        Artikel 18 DSGVO <strong>eingeschränkt</strong> wird, das heißt dass Ihre personenbezogenen
        Daten nur gespeichert werden zu folgenden Zwecken:
        <br />
        <ul>
            <li>
                um die Richtigkeit der personenbezogenen Daten, gegen die ein Einwand erhoben wird,
                zu überprüfen,
            </li>
            <li>
                um Ihnen im Rahmen der Geltendmachung, Ausübung oder Verteidigung Ihrer
                Rechtsansprüche zu dienen, obwohl das Unternehmen sie nicht länger benötigt;
            </li>
            <li>
                um zu überprüfen, ob die berechtigten Gründe des Unternehmens gegenüber Ihren
                Gründen überwiegen, falls Sie Widerspruch gegen die Verarbeitung auf der Grundlage
                des berechtigten Interesses des Unternehmens einlegen;
            </li>
            <li>
                um Ihren Wunsch nach Einschränkung der Verwendung Ihrer personenbezogenen Daten -
                anstatt einer Löschung - zu erfüllen, falls die Verarbeitung Ihrer Daten
                unrechtmäßig ist;
            </li>
        </ul>
        Aufgrund Ihres <strong>Rechts auf Datenübertragbarkeit</strong> und unter den in Artikel 20
        DSGVO vorgesehenen Umständen können Sie vom Unternehmen die personenbezogenen Daten, die Sie
        uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format
        erhalten, um sie einem anderen Verantwortlichen für die Datenverarbeitung übermitteln zu
        können.
        <br />
        <br />
        Nach Artikel 21 DSGVO haben Sie die Möglichkeit, jederzeit gegen die Verarbeitung Ihrer
        personenbezogenen Daten zum Zwecke der Direktwerbung <strong>Widerspruch</strong>{' '}
        einzulegen. Zur Ausübung Ihrer Rechte senden Sie Ihren Antrag:
        <br />
        <ul>
            <li>entweder per E-Mail an die E-Mail-Adresse: {data.dpo_email},</li>
            <li>oder per Post an die Adresse: {data.dpo_address}.</li>
        </ul>
        Das Unternehmen wird Ihren Antrag innerhalb von 30 Tagen nach Eingang beantworten.
        <br />
        <br /> Die Ausübung dieser Rechte ist mit keinen Kosten verbunden. Allerdings behält sich
        das Unternehmen im Falle eines offensichtlich unbegründeten oder überzogenen Antrags,
        insbesondere mit wiederholtem Charakter, die Möglichkeit vor, 1.) die Zahlung von Gebühren
        zu verlangen, die die Verwaltungskosten beinhalten, oder 2.) die Erfüllung des Antrags
        abzulehnen.
        <br />
        <br />
        <h3>
            <strong>
                10. Was passiert bei einer Beeinträchtigung Ihrer personenbezogenen Daten?
            </strong>
        </h3>
        Bei einer Verletzung Ihrer personenbezogenen Daten, durch die eine Gefahr für Ihre Rechte
        und Freiheiten entstehen könnte, meldet das Unternehmen die Verletzung umgehend und wenn
        möglich spätestens innerhalb von 72 Stunden nach Bekanntwerden der französischen
        Datenschutzbehörde CNIL. Wenn eine Verletzung Ihrer personenbezogenen Daten ein hohes Risiko
        für die persönlichen Rechte und Freiheiten eines Nutzers zur Folge haben könnte, wird das
        Unternehmen den Nutzer schnellstmöglich darüber informieren, vorbehaltlich der in Artikel 34
        DSGVO vorgesehenen Ausnahmen.
        <br />
        <br /> Unbeschadet jedes anderen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs
        können Sie, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten
        eine Verletzung der Bestimmungen des geltenden Rechts darstellt, eine Beschwerde bei einer
        zuständigen Aufsichtsbehörde einlegen. In Frankreich ist dies die Commission Nationale de
        l’Informatique et des Libertés (CNIL), die Sie unter folgender Adresse erreichen:
        <br /> Commission Nationale de l'Informatique et des Libertés, 3 Place de Fontenoy - TSA
        80715 - 75334 Paris Cedex 07.
        <br />
        <br />
        <h3>
            <strong>11. An wen können Sie Ihre Fragen richten? </strong>
        </h3>
        Wenn Sie Fragen zur Verarbeitung Ihrer personenbezogenen Daten haben, können Sie sich
        jederzeit an den Ansprechpartner für personenbezogene Daten oder den Datenschutzbeauftragten
        des Unternehmens wenden: {data.dpo_name}. Bei Fragen zur Verarbeitung Ihrer
        personenbezogenen Daten und zur Ausübung Ihrer Rechte wenden Sie sich an diese Adresse:{' '}
        {data.dpo_email}.
        <br />
        <br />
    </StyledContent>
);

export default PrivacyPolicyTemplateDe;
