import { Button, Stack } from '@partoohub/ui';

type Props = {
    onClick: () => void;
    text: string;
};

export const LoginLink = ({ onClick, text }: Props) => {
    return (
        <Stack alignItems="center">
            <Button
                dataTrackId="login-link"
                variant="secondary"
                onClick={onClick}
                appearance="text"
            >
                {text}
            </Button>
        </Stack>
    );
};
