import { Conversation } from '@partoohub/api-client';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { CONVERSATION_UPDATE } from 'app/common/data/websocket';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import useWebsocket from 'app/common/hooks/useWebsocket';
import { useInvalidateMessagingStatsQueries } from 'app/pages/Homepage/hooks/stats/statsItems/messages/useMessagingStats';
import {
    ConversationUpdate,
    ConversationUpdateEvents,
} from 'app/states/messaging/conversationUpdateTypes';
import { AssignFilterOption, StatusFilterOption, URLParams } from 'app/states/messaging/dataTypes';
import queryClient from 'app/states/queryClient';

import { useGetActiveConversation } from './useGetActiveConversation';
import { useGetConversations } from './useGetConversations';
import { useHasAssignConversationFeature } from './useHasAssignConversationFeature';

import { handleConversationAssignment } from '../services/conversationUpdateHandlers/handleConversationAssignment';
import { handleConversationIsAutomatedChange } from '../services/conversationUpdateHandlers/handleConversationIsAutomatedChange';
import { handleConversationStatusChange } from '../services/conversationUpdateHandlers/handleConversationStatusChange';
import { handleNewConversationMessage } from '../services/conversationUpdateHandlers/handleNewConversationMessage';
import { updateConversation } from '../services/handleConversationsCache';
import { addNewMessage, updateMessage } from '../services/handleMessagesCache';
import { isBusinessIdInFilteredBusinesses } from '../services/isBusinessIdInFilteredBusinesses';

export type ConversationsData = {
    conversations: Array<Conversation>;
    activeConversation: Conversation | undefined;
};

export const useConversationUpdate = () => {
    const { conversations } = useGetConversations();
    const activeConversation = useGetActiveConversation();
    const [statusFilter] = useStateQueryParams<StatusFilterOption>(URLParams.STATUS);
    const [assignFilter] = useStateQueryParams<AssignFilterOption>(URLParams.ASSIGN);
    const businessFilters = useBusinessModalFilters();
    const [, setConversationParamId] = useStateQueryParams(URLParams.CONVERSATION_ID);
    const { data: me } = useMe();
    const hasAssignConversationFeature = useHasAssignConversationFeature();
    const invalidateMessagingStatsQueries = useInvalidateMessagingStatsQueries();

    const conversationsData = {
        conversations,
        activeConversation,
    };
    const filtersData = {
        statusFilter,
        assignFilter,
        businessFilters,
    };

    return useWebsocket(
        CONVERSATION_UPDATE,
        ({
            conversation,
            event,
            message,
            status_message,
            assignment_message,
            conversation_read_user_ids,
        }: ConversationUpdate) => {
            // updates the sidebar unanswered conversations number
            // (when a message is sent or a conversation is closed/reopened)
            invalidateMessagingStatsQueries();
            if (!isBusinessIdInFilteredBusinesses(businessFilters, conversation.business.id) || !me)
                return;

            const is_read =
                (message && me?.id === message.user_id) ||
                conversation_read_user_ids.some(id => me?.id === id);
            conversation.is_read = is_read;

            switch (event) {
                case ConversationUpdateEvents.CONVERSATION_STATUS_CHANGE: {
                    const statusChangeData = {
                        conversation,
                        status_message: status_message!,
                        conversation_read_user_ids,
                    };

                    handleConversationStatusChange(
                        statusChangeData,
                        conversationsData,
                        filtersData,
                        setConversationParamId,
                        me.id,
                    );
                    break;
                }
                case ConversationUpdateEvents.CONVERSATION_ASSIGNMENT: {
                    if (!hasAssignConversationFeature) return;

                    const assignmentData = {
                        conversation,
                        assignment_message: assignment_message!,
                        conversation_read_user_ids,
                    };

                    handleConversationAssignment(
                        assignmentData,
                        conversationsData,
                        filtersData,
                        setConversationParamId,
                        me.id,
                    );
                    break;
                }
                case ConversationUpdateEvents.CONVERSATION_IS_AUTOMATED_CHANGE: {
                    handleConversationIsAutomatedChange(
                        conversation,
                        activeConversation,
                        filtersData,
                        setConversationParamId,
                    );
                    break;
                }
                case ConversationUpdateEvents.NEW_CONVERSATION_MESSAGE: {
                    const messageData = {
                        conversation,
                        message: message!,
                        status_message,
                        assignment_message,
                    };

                    handleNewConversationMessage(
                        messageData,
                        filtersData,
                        me.id,
                        setConversationParamId,
                        conversationsData,
                    );
                    break;
                }
                case ConversationUpdateEvents.NEW_MESSAGE_STATUS: {
                    updateMessage(queryClient, conversation.id, message!);
                    break;
                }
                case ConversationUpdateEvents.MESSAGE_DELETED: {
                    if (!message) return;

                    updateMessage(queryClient, conversation.id, message);
                    updateConversation(conversation, statusFilter, assignFilter, businessFilters);
                    break;
                }
                case ConversationUpdateEvents.CONVERSATION_HANDOVER: {
                    addNewMessage(queryClient, conversation.id, message!);
                    break;
                }
            }
        },
        [conversations, activeConversation, statusFilter, assignFilter, businessFilters, me],
    );
};
