import { useNavigate } from 'react-router-dom';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { LoginQueryKeys } from 'app/pages/Login/utils/enums';
import { ROOT_PATH } from 'app/routing/routeIds';
import { sdkBridge } from 'app/SDKBridge';
import queryClient from 'app/states/queryClient';

const useNextPageUrl = (): string => {
    const [nextPage] = useStateQueryParams(LoginQueryKeys.NEXT_PAGE);
    const uri = decodeURI(nextPage || ROOT_PATH);

    // Force absolute path if / is missing
    return uri.startsWith('/') ? uri : `/${uri}`;
};

/**
 * Redirects the user to the next page after login
 */
export const useLoginRedirect = () => {
    const nextPageUrl = useNextPageUrl();
    const navigate = useNavigate();

    return () => {
        // Invalidate all queries just in case
        queryClient.clear();
        sdkBridge.sendUserIsLoggedIn();
        navigate(nextPageUrl);
    };
};
