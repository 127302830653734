import { ReviewAnalyticsData } from 'app/api/types/review';

import * as constants from './constants';
import * as types from './types';

export const getReviewsPeriodTotalRequestAction = (
    isPeriod: boolean,
): types.GetReviewsPeriodTotalRequestType => ({
    type: constants.GET_REVIEWS_PERIOD_TOTAL_REQUEST,
    isPeriod,
});

export const getReviewsEvolutionRequestAction = (
    isQualitative: boolean,
    isPeriod: boolean,
): types.GetReviewsEvolutionRequestType => ({
    type: constants.GET_REVIEWS_EVOLUTION_REQUEST,
    isQualitative,
    isPeriod,
});

export const getReviewsEvolutionFailureAction = (
    error: Record<string, any>,
): types.GetReviewsEvolutionFailureType => ({
    type: constants.GET_REVIEWS_EVOLUTION_FAILURE,
    error,
});

export const getReviewsEvolutionSuccessAction = (
    analytics: Array<ReviewAnalyticsData>,
    isQualitative: boolean,
    isPeriod: boolean,
): types.GetReviewsEvolutionSuccessType => ({
    type: constants.GET_REVIEWS_EVOLUTION_SUCCESS,
    analytics,
    isQualitative,
    isPeriod,
});

export const updateIsQualitativeAction = (
    isQualitative: boolean,
): types.UpdateIsQualitativeType => ({
    type: constants.UPDATE_IS_QUALITATIVE,
    isQualitative,
});

export const updateBucketAction = (bucket: string): types.UpdateBucketType => ({
    type: constants.UPDATE_REVIEWS_EVOLUTION_BUCKET,
    bucket,
});

export const getReviewStatsTotalRequestAction = () => ({
    type: constants.GET_REVIEW_STATS_TOTAL_REQUEST,
});

export const getReviewStatsTotalSuccessAction = (
    average: number,
    satisfied: number,
    neutral: number,
    dissatisfied: number,
    answerTime: number,
    notTreated: number,
    total: number,
    treated: number,
    answerRate: number,
) => ({
    type: constants.GET_REVIEW_STATS_TOTAL_SUCCESS,
    average,
    satisfied,
    neutral,
    dissatisfied,
    answerTime,
    notTreated,
    total,
    treated,
    answerRate,
});

export const getReviewStatsTotalFailureAction = (
    error: Record<string, any>,
) => ({
    type: constants.GET_REVIEW_STATS_TOTAL_FAILURE,
    error,
});

export const getReviewStatsPeriodRequestAction = () => ({
    type: constants.GET_REVIEW_STATS_PERIOD_REQUEST,
});

export const getReviewStatsPeriodSuccessAction = (
    average: number,
    satisfied: number,
    neutral: number,
    dissatisfied: number,
    answerTime: number,
    notTreated: number,
    total: number,
    treated: number,
    answerRate: number,
) => ({
    type: constants.GET_REVIEW_STATS_PERIOD_SUCCESS,
    average,
    satisfied,
    neutral,
    dissatisfied,
    answerTime,
    notTreated,
    total,
    treated,
    answerRate,
});

export const getReviewStatsPeriodFailureAction = (
    error: Record<string, any>,
) => ({
    type: constants.GET_REVIEW_STATS_PERIOD_FAILURE,
    error,
});

export const resetEvolutionTotalAnalyticsAction =
    (): types.ResetEvolutionTotalAnalyticsType => ({
        type: constants.RESET_EVOLUTION_TOTAL_ANALYTICS,
    });

export const resetEvolutionPeriodAnalyticsAction =
    (): types.ResetEvolutionPeriodAnalyticsType => ({
        type: constants.RESET_EVOLUTION_PERIOD_ANALYTICS,
    });
