import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router';

import { CONNECTION_LOGIN_SSO_PATH } from 'app/routing/routeIds';

import { SsoSignInContainer } from './SsoSignInButton.styled';

export const SsoSignInButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <SsoSignInContainer onClick={() => navigate(CONNECTION_LOGIN_SSO_PATH)}>
            <i className="fa-solid fa-building" />
            <span>{t('sso_sign_in')}</span>
        </SsoSignInContainer>
    );
};
