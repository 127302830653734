import { useMutation } from 'react-query';

import { PasswordData } from 'app/api/types/connection';
import api from 'app/api/v2/api_calls';

/**
 * Used to complete the invitation process.
 * ⚠️ Will create a session cookie
 */
export const useCompleteInvitation = (
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) => {
    return useMutation((params: PasswordData) => api.connection.completeInvitation(params), {
        onSuccess,
        onError,
    });
};
