import { Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import {
    DescriptionContainer,
    LoginRightPartContentContainer,
} from 'app/pages/Login/LoginRightPartContent/LoginRightPartContent.styled';

import { MobileSvg } from './MobileSvg';

/**
 * Template for the right part of the login page
 * For now, it's only the mobile app download thing
 */
export const LoginRightPartContent = () => {
    const { t } = useTranslation();

    return (
        <LoginRightPartContentContainer>
            <Text variant="heading1">{t('login_mobile_title')}</Text>
            <DescriptionContainer>
                <Text variant="bodyLRegular" color="secondary">
                    {t('login_mobile_description')}
                </Text>
            </DescriptionContainer>
            <MobileSvg />
        </LoginRightPartContentContainer>
    );
};
