import { Button, ButtonVariants, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { LoginFormContainer, LoginFormMessage } from 'app/pages/Login/forms/loginForm.styled';
import { LoginLink } from 'app/pages/Login/forms/LoginLink';
import { LostPasswordSuccess } from 'app/pages/Login/forms/LostPassword/LostPasswordSuccess';
import { useLostPassword } from 'app/pages/Login/hooks/mutations/useLostPassword';
import { useLoginContext } from 'app/pages/Login/hooks/useLoginContext';
import { CONNECTION_PATH } from 'app/routing/routeIds';

const getStatusObj = (
    email: string,
    isError: boolean,
    isLoading: boolean,
): {
    variant: ButtonVariants;
    label: string;
} => {
    if (isLoading) {
        return {
            variant: 'secondary',
            label: 'loading',
        };
    }

    if (email === '') {
        return {
            variant: 'secondary',
            label: 'send_reset_password_link',
        };
    }

    if (isError) {
        return {
            variant: 'danger',
            label: 'connection_error',
        };
    }

    return {
        variant: 'primary',
        label: 'send_reset_password_link',
    };
};

export const LostPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const lostPassword = useLostPassword();

    const { email, setEmail } = useLoginContext();

    const { variant, label } = getStatusObj(email, lostPassword.isError, lostPassword.isLoading);

    const handleSubmit = () => {
        lostPassword.mutate(email);
    };

    if (lostPassword.isSuccess) {
        return <LostPasswordSuccess />;
    }

    // @ts-expect-error
    const emailError = lostPassword.error?.response?.data?.errors?.json?.email;

    return (
        <LoginFormContainer>
            <LoginFormMessage>
                <i className="fa-solid fa-info-circle" />
                {t('lost_password__explanation')}
            </LoginFormMessage>
            <TextInput
                dataTrackId="lost_password__email_input"
                label={t('email_extended')}
                onChange={setEmail}
                value={email}
                error={lostPassword.isError}
                notice={emailError && typeof emailError === 'string' ? emailError : undefined}
                type="email"
            />
            <Button
                variant={variant}
                shape="cube"
                size="large"
                full
                onClick={handleSubmit}
                dataTrackId="lost_password__submit_button"
                type="submit"
                disabled={variant === 'secondary'}
            >
                {t(label)}
            </Button>

            <LoginLink onClick={() => navigate(CONNECTION_PATH)} text={t('return_to_login')} />
        </LoginFormContainer>
    );
};
