import { put, select } from 'redux-saga/effects';

import { chooseBucket } from 'app/common/services/allowedSeasonalityOptions';
import { Saga } from 'app/common/types';
import {
    ReviewFiltersState,
    dateFilterSelector,
    reviewFiltersSelector,
} from 'app/states/reviews/filters';

import { updateBucketAction } from '../actions';
import { ReviewAnalyticsState } from '../reducers';
import {
    bucketSelector,
    isPeriodSelector,
    reviewAnalyticsSelector,
} from '../selectors';

function* updateBucketSaga(): Saga {
    const reviewStatsState = yield select(
        reviewAnalyticsSelector,
    ) as any as ReviewAnalyticsState;

    const reviewFiltersState = yield select(
        reviewFiltersSelector,
    ) as any as ReviewFiltersState;

    const isPeriod = isPeriodSelector(reviewStatsState); // evolution period button selected

    const dates = dateFilterSelector(reviewFiltersState);

    const bucket = bucketSelector(reviewStatsState);

    yield put(
        // @ts-ignore
        updateBucketAction(chooseBucket(bucket, isPeriod ? dates : undefined)),
    );
}

export default updateBucketSaga;
