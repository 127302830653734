import React from 'react';

const FaceMehIcon = React.forwardRef<SVGSVGElement>((_, ref) => (
    <svg
        ref={ref}
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M18.5 12C18.5 9.6875 17.25 7.5625 15.25 6.375C13.2188 5.21875 10.75 5.21875 8.75 6.375C6.71875 7.5625 5.5 9.6875 5.5 12C5.5 14.3438 6.71875 16.4688 8.75 17.6562C10.75 18.8125 13.2188 18.8125 15.25 17.6562C17.25 16.4688 18.5 14.3438 18.5 12ZM12 4C14.8438 4 17.4688 5.53125 18.9062 8C20.3438 10.5 20.3438 13.5312 18.9062 16C17.4688 18.5 14.8438 20 12 20C9.125 20 6.5 18.5 5.0625 16C3.625 13.5312 3.625 10.5 5.0625 8C6.5 5.53125 9.125 4 12 4ZM9.5 11.5C8.9375 11.5 8.5 11.0625 8.5 10.5C8.5 9.96875 8.9375 9.5 9.5 9.5C10.0625 9.5 10.5 9.96875 10.5 10.5C10.5 11.0625 10.0625 11.5 9.5 11.5ZM15.5 10.5C15.5 11.0625 15.0625 11.5 14.5 11.5C13.9375 11.5 13.5 11.0625 13.5 10.5C13.5 9.96875 13.9375 9.5 14.5 9.5C15.0625 9.5 15.5 9.96875 15.5 10.5ZM9.75 14.25H14.25C14.6562 14.25 15 14.5938 15 15C15 15.4375 14.6562 15.75 14.25 15.75H9.75C9.3125 15.75 9 15.4375 9 15C9 14.5938 9.3125 14.25 9.75 14.25Z" />
    </svg>
));

export default FaceMehIcon;
