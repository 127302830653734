import React from 'react';

const changePasswordImage = () => (
    <svg
        id="Calque_1"
        data-name="Calque 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 319 140"
        width="320"
        height="140"
    >
        <path
            d="M265.29,58.54l3.13-1.07-2.71-2,0-3.49L263.1,54.1,260,53,261,56.28l-2,2.84,3.3-.09,1.91,2.85ZM269.7,57a.76.76,0,0,1,.3.5.86.86,0,0,1-.08.57.75.75,0,0,1-.42.36l-3.32,1.11-1,3.52a.75.75,0,0,1-.33.45.62.62,0,0,1-.53.09.7.7,0,0,1-.45-.32l-2-3-3.48.05a.7.7,0,0,1-.51-.2.73.73,0,0,1-.25-.5.76.76,0,0,1,.15-.55l2.06-3-1.14-3.47a.8.8,0,0,1,0-.57.84.84,0,0,1,.38-.41.65.65,0,0,1,.56,0l3.29,1.16,2.76-2.21a.69.69,0,0,1,.54-.16.82.82,0,0,1,.49.26.79.79,0,0,1,.2.55l0,3.66Z"
            transform="translate(-0.04)"
            fill="#f7c200"
            fillRule="evenodd"
        />
        <path
            d="M252.5,87.08a1.07,1.07,0,0,1,.07-.15,2.51,2.51,0,0,1,1.81-1.22,4.37,4.37,0,0,1,2.88.49,4.2,4.2,0,0,1,2.15,2.57,1.16,1.16,0,0,0,.6.78.91.91,0,0,0,.3.11,4.87,4.87,0,0,1,1.19.45A4.54,4.54,0,0,1,263.39,92a2.69,2.69,0,0,1,.21,2.13,1.27,1.27,0,0,0,0,.84h0a2.34,2.34,0,0,1-1.89,3.3,4.35,4.35,0,0,1-2.87-.49,4.58,4.58,0,0,1-1.33-1,1.21,1.21,0,0,0-.74-.4,4.8,4.8,0,0,1-1.62-.52,4.71,4.71,0,0,1-1.57-1.36,1.17,1.17,0,0,0-.92-.49,4.71,4.71,0,0,1-2-.56,4.36,4.36,0,0,1-2-2.12,2.49,2.49,0,0,1,0-2.17,2.81,2.81,0,0,1,2.65-1.29A1.25,1.25,0,0,0,252.5,87.08Zm8.57,13.7a5.71,5.71,0,0,0,2.5-.59l.12-.06h0a5.26,5.26,0,0,0,.91-.63,4.69,4.69,0,0,0,1.05-1.3,4.87,4.87,0,0,0,.35-3.7,5.17,5.17,0,0,0-.4-3.52,6.8,6.8,0,0,0-2.95-3,8.15,8.15,0,0,0-1.1-.48A6.65,6.65,0,0,0,258.43,84a6.83,6.83,0,0,0-4.48-.73,5,5,0,0,0-3.27,2.09,5.8,5.8,0,0,0-2.07.58,4.76,4.76,0,0,0-2,2,4.6,4.6,0,0,0-.53,2.83v0A6.9,6.9,0,0,0,252,96.35,7.3,7.3,0,0,0,254,98a7.41,7.41,0,0,0,2,.72,8,8,0,0,0,.82.7h0a8.62,8.62,0,0,0,2.16,1.08h0l.49.13.1,0h.16l.12,0h0l.09,0h0l.14,0h.11"
            transform="translate(-0.04)"
            fill="#f7c200"
            fillRule="evenodd"
        />
        <path
            d="M298.85,63.32l-.08-.19a3.21,3.21,0,0,1,.25-2.65,5.16,5.16,0,0,1,2.59-2.31,4.71,4.71,0,0,1,3.91,0,1.33,1.33,0,0,0,1.15,0A1.08,1.08,0,0,0,307,58a5.57,5.57,0,0,1,1.29-.75,5,5,0,0,1,3.08-.31,3.17,3.17,0,0,1,2.07,1.48,1.49,1.49,0,0,0,.76.66h0a2.93,2.93,0,0,1,1.82,1.7,3.21,3.21,0,0,1-.25,2.65,5.14,5.14,0,0,1-2.59,2.3,5.51,5.51,0,0,1-1.93.43,1.34,1.34,0,0,0-.92.38,5.54,5.54,0,0,1-1.67,1.09,5.17,5.17,0,0,1-2.39.41,1.33,1.33,0,0,0-1.13.48,5.52,5.52,0,0,1-2,1.43,4.82,4.82,0,0,1-3.39.2,3,3,0,0,1-1.94-1.72,3.51,3.51,0,0,1,.82-3.49A1.57,1.57,0,0,0,298.85,63.32Zm18.69,2.74a7,7,0,0,0,1.35-2.81l0-.15v0a6.17,6.17,0,0,0,.12-1.35,5.82,5.82,0,0,0-3.45-5.23,6,6,0,0,0-3.46-2.4,7.65,7.65,0,0,0-4.9.4,8.28,8.28,0,0,0-1.25.66,7.42,7.42,0,0,0-5.43.23,8,8,0,0,0-4,3.61,6.34,6.34,0,0,0-.57,4.7,7.09,7.09,0,0,0-1,2.39,6,6,0,0,0,.25,3.47,5.76,5.76,0,0,0,2.14,2.71l0,0c4.74,2.78,8.6-.47,9.38-1.2a8.1,8.1,0,0,0,3-.63,8.63,8.63,0,0,0,2.13-1.3,7.68,7.68,0,0,0,1.24-.22h0a10.36,10.36,0,0,0,2.58-1.17h0l.49-.36.09-.07h0l0,0,.05,0,.05,0,.12-.1,0,0,.08-.08,0,0,.12-.12.09-.09"
            transform="translate(-0.04)"
            fill="#f7c200"
            fillRule="evenodd"
        />
        <path
            d="M305.25,18.12h-6.38V11.81a1.83,1.83,0,0,0-3.65,0v6.31h-6.39A1.82,1.82,0,0,0,287,19.93a1.8,1.8,0,0,0,.53,1.28,1.84,1.84,0,0,0,1.3.54h6.39v6.3a1.8,1.8,0,0,0,.53,1.28,1.86,1.86,0,0,0,1.29.53,1.82,1.82,0,0,0,1.83-1.81v-6.3h6.38a1.82,1.82,0,1,0,0-3.63Z"
            transform="translate(-0.04)"
            fill="#f7c200"
            fillRule="evenodd"
        />
        <path
            d="M255.74,26.68l-1.32-1.3,1.32-1.3a.52.52,0,0,0,0-.74.54.54,0,0,0-.75,0l-1.32,1.3-1.31-1.3a.56.56,0,0,0-.76,0,.53.53,0,0,0-.15.37.55.55,0,0,0,.15.38l1.32,1.3-1.32,1.29a.55.55,0,0,0-.15.38.55.55,0,0,0,.15.37.56.56,0,0,0,.76,0l1.31-1.3,1.31,1.3a.56.56,0,0,0,.76,0A.53.53,0,0,0,255.74,26.68Z"
            transform="translate(-0.04)"
            fill="#f7c200"
            fillRule="evenodd"
        />
        <path
            d="M296.29,112.5,295,111.2l1.31-1.3a.53.53,0,0,0,0-.75.54.54,0,0,0-.75,0l-1.31,1.3-1.32-1.3a.54.54,0,0,0-.75,0,.52.52,0,0,0-.16.38.53.53,0,0,0,.15.37l1.32,1.3-1.31,1.3a.49.49,0,0,0-.16.37.5.5,0,0,0,.16.37.53.53,0,0,0,.75,0l1.31-1.3,1.32,1.3a.53.53,0,0,0,.75,0A.51.51,0,0,0,296.29,112.5Z"
            transform="translate(-0.04)"
            fill="#f7c200"
            fillRule="evenodd"
        />
        <path
            d="M3.27,74.16a1.24,1.24,0,0,1,0-.16,2.55,2.55,0,0,1,.39-2,3.64,3.64,0,0,1,2-1.5,3.22,3.22,0,0,1,2.8.38.91.91,0,0,0,.81.15,1,1,0,0,0,.25-.12,4.16,4.16,0,0,1,1-.46,3.43,3.43,0,0,1,2.22.07,2.36,2.36,0,0,1,1.36,1.32,1.15,1.15,0,0,0,.48.58h0a2.27,2.27,0,0,1,1.16,1.47,2.6,2.6,0,0,1-.4,2,3.73,3.73,0,0,1-3.45,1.64.9.9,0,0,0-.68.2,3.83,3.83,0,0,1-1.28.67,3.34,3.34,0,0,1-1.74.08.94.94,0,0,0-.85.26,3.81,3.81,0,0,1-1.53.9,3.31,3.31,0,0,1-2.43-.17A2.25,2.25,0,0,1,2.12,78,2.75,2.75,0,0,1,3,75.4,1.26,1.26,0,0,0,3.27,74.16ZM16.37,78a5.43,5.43,0,0,0,1.19-2l0-.12h0a5,5,0,0,0,.2-1,4.82,4.82,0,0,0-.12-1.56,4.47,4.47,0,0,0-1.92-2.74,4.49,4.49,0,0,0-2.27-2.15A5.17,5.17,0,0,0,10,68.25a6.07,6.07,0,0,0-1,.38,5.06,5.06,0,0,0-3.89-.34A5.72,5.72,0,0,0,2,70.65a5.12,5.12,0,0,0-.79,3.52,5.59,5.59,0,0,0-.91,1.72,4.92,4.92,0,0,0-.11,2.66,4.49,4.49,0,0,0,1.31,2.27h0a5.28,5.28,0,0,0,6.79,0,5.37,5.37,0,0,0,2.17-.2,5.76,5.76,0,0,0,1.62-.78,5.43,5.43,0,0,0,.9,0h0a6.74,6.74,0,0,0,1.93-.64h0l.38-.23.06,0h0l0,0,0,0,0,0,.09-.07,0,0,.06,0,0,0,.09-.07.08-.06"
            transform="translate(-0.04)"
            fill="#f7c200"
            fillRule="evenodd"
        />
        <path
            d="M23.33,20l3-3.63-4.68-.16-2.51-4-1.61,4.42L13,17.86l3.67,2.9-.26,4.71,3.88-2.65,4.35,1.75Zm4.1-5.2a1,1,0,0,1,.72.32,1.16,1.16,0,0,1,.32.71,1.09,1.09,0,0,1-.24.73l-3.15,3.82,1.36,4.77a1.06,1.06,0,0,1-.07.76,1,1,0,0,1-.57.52,1,1,0,0,1-.77,0l-4.59-1.79-4.11,2.74a1,1,0,0,1-.75.18,1,1,0,0,1-.66-.38,1,1,0,0,1-.23-.73l.3-4.95L11.1,18.46a1,1,0,0,1-.39-.66,1.08,1.08,0,0,1,.16-.76,1,1,0,0,1,.63-.47l4.77-1.23L18,10.71a1.05,1.05,0,0,1,.53-.6,1.17,1.17,0,0,1,.76-.08,1,1,0,0,1,.64.47l2.63,4.16Z"
            transform="translate(-0.04)"
            fill="#f7c200"
            fillRule="evenodd"
        />
        <path
            d="M19,11.71l.28.43L21.75,16l4.55.16.5,0-.32.39L23.6,20.08l1.23,4.42L25,25l-.47-.19-4.23-1.7L16.5,25.67l-.42.29,0-.51.26-4.58L12.8,18.05l-.4-.31.49-.13,4.41-1.13,1.56-4.3Zm.13,1.12-1.43,3.94-.05.13-.13,0L13.5,18l3.28,2.59.1.08v.13L16.64,25l3.46-2.36.11-.07.12.05,3.89,1.56-1.13-4.06,0-.12.08-.1,2.65-3.24-4.18-.14h-.13l-.07-.11Zm0-2.56a1,1,0,0,0-.6.07.73.73,0,0,0-.39.46h0l-1.68,4.62-.05.13-.13,0-4.76,1.23a.77.77,0,0,0-.49.36h0a.88.88,0,0,0-.12.59h0a.76.76,0,0,0,.3.51l3.89,3,.1.08v.13l-.3,4.95a.81.81,0,0,0,.18.57.71.71,0,0,0,.5.28.74.74,0,0,0,.57-.14h0l4.1-2.74.11-.07.12.05,4.59,1.79h0a.78.78,0,0,0,.6,0,.73.73,0,0,0,.42-.39.81.81,0,0,0,.06-.59l-1.36-4.76,0-.13.09-.1L28,16.44h0a.81.81,0,0,0,.19-.56.9.9,0,0,0-.25-.55.73.73,0,0,0-.55-.25h0l-4.92-.17h-.13l-.07-.11-2.63-4.16h0A.79.79,0,0,0,19.18,10.27Zm-.81-.38a1.33,1.33,0,0,1,.92-.1,1.22,1.22,0,0,1,.8.57h0l2.56,4,4.79.17h0a1.3,1.3,0,0,1,.9.4h0a1.5,1.5,0,0,1,.38.86h0a1.27,1.27,0,0,1-.3.91h0l-3.06,3.72,1.32,4.64h0a1.28,1.28,0,0,1-.09.94h0a1.19,1.19,0,0,1-.7.64h0a1.25,1.25,0,0,1-.95,0l-4.47-1.74-4,2.66a1.29,1.29,0,0,1-.93.23h0a1.25,1.25,0,0,1-.83-.48h0a1.33,1.33,0,0,1-.28-.9h0l.3-4.82-3.79-3h0a1.25,1.25,0,0,1-.48-.83,1.36,1.36,0,0,1,.18-.92,1.31,1.31,0,0,1,.79-.59h0l4.64-1.19,1.64-4.51h0A1.24,1.24,0,0,1,18.37,9.89Z"
            transform="translate(-0.04)"
            fill="#f7c200"
            fillRule="evenodd"
        />
        <path
            d="M75.27,88.48l3.14-1.06-2.71-2,0-3.49L73.09,84,70,82.93,71,86.23l-2,2.83,3.3-.08,1.91,2.85Zm4.41-1.58a.79.79,0,0,1,.31.49.91.91,0,0,1-.09.57.71.71,0,0,1-.42.36l-3.32,1.11-1,3.52a.77.77,0,0,1-.33.45.67.67,0,0,1-.53.1.8.8,0,0,1-.46-.33l-2-3-3.47,0a.68.68,0,0,1-.52-.19.74.74,0,0,1-.25-.51.9.9,0,0,1,.15-.55l2.06-3-1.14-3.48a.85.85,0,0,1,0-.57.84.84,0,0,1,.38-.41.72.72,0,0,1,.56,0l3.29,1.16,2.76-2.2a.66.66,0,0,1,.54-.16.75.75,0,0,1,.48.25.81.81,0,0,1,.2.55l0,3.66Z"
            transform="translate(-0.04)"
            fill="#f7c200"
            fillRule="evenodd"
        />
        <path
            d="M76,81.41v.52l0,3.37,2.61,1.92.38.28-.45.16-3,1-1,3.22-.15.51-.3-.44-1.83-2.74-3.17.08h-.49l.28-.4,1.88-2.73-1-3.18-.16-.48.48.17,3,1.06,2.53-2Zm-.51,1-2.22,1.78-.11.08-.13,0-2.66-.94.91,2.81,0,.12-.07.1L69.56,88.8l2.81-.07h.14l.08.12,1.61,2.41L75,88.41l0-.12.12,0,2.68-.91-2.32-1.71-.11-.08v-.13Zm.32-1.77L73,82.9l-.11.09-.13,0-3.29-1.16a.41.41,0,0,0-.36,0h0a.63.63,0,0,0-.27.29.55.55,0,0,0,0,.4L70,86l0,.12-.07.1-2.07,3h0a.6.6,0,0,0-.1.38.49.49,0,0,0,.17.34h0a.44.44,0,0,0,.34.12h0l3.47,0h.14l.07.11,2,3h0a.48.48,0,0,0,.3.21h0a.39.39,0,0,0,.33-.06.53.53,0,0,0,.23-.31m0,0,1-3.52,0-.13.13,0,3.31-1.1a.51.51,0,0,0,.28-.24.64.64,0,0,0,.06-.41.5.5,0,0,0-.21-.34L76.69,85l-.11-.07V84.8l0-3.66a.54.54,0,0,0-.13-.38.6.6,0,0,0-.34-.18h0a.51.51,0,0,0-.35.11m.42-.6a.91.91,0,0,0-.72.21h0l-2.65,2.12-3.16-1.11h0a.89.89,0,0,0-.75,0,1.05,1.05,0,0,0-.53,1.28h0L69.5,86l-2,2.86h0a1,1,0,0,0-.18.71h0a.93.93,0,0,0,.32.67,1,1,0,0,0,.69.26l3.34,0,2,2.86a1,1,0,0,0,.6.43.87.87,0,0,0,.72-.13h0a1,1,0,0,0,.44-.59h0l1-3.39,3.19-1.07h0a1,1,0,0,0,.56-.48h0a1.09,1.09,0,0,0,.11-.73,1,1,0,0,0-.4-.65h0l-2.75-2,0-3.53h0a1,1,0,0,0-.26-.72h0A1,1,0,0,0,76.21,80.09Z"
            transform="translate(-0.04)"
            fill="#f7c200"
            fillRule="evenodd"
        />
        <path
            d="M63.6,31.57H59.08V27.11a1.29,1.29,0,0,0-2.58,0v4.47H52a1.28,1.28,0,1,0,0,2.56H56.5v4.47a1.26,1.26,0,0,0,1.29,1.28,1.28,1.28,0,0,0,1.29-1.28V34.14H63.6a1.29,1.29,0,1,0,0-2.57Z"
            transform="translate(-0.04)"
            fill="#f7c200"
            fillRule="evenodd"
        />
        <path
            d="M42.47,56.2 1l-1.84-1.82,1.84-1.82a.73.73,0,0,0,0-1,.75.75,0,0,0-1,0l-1.84,1.81-1.84-1.82a.77.77,0,0,0-1.06,0,.74.74,0,0,0,0,1l1.84,1.82-1.83,1.82a.7.7,0,0,0-.22.51.77.77,0,0,0,.21.53.76.76,0,0,0,1.06,0l1.83-1.81,1.84,1.82a.75.75,0,0,0,1.05,0A.73.73,0,0,0,42.47,56.21Z"
            transform="translate(-0.04)"
            fill="#f7c200"
            fillRule="evenodd"
        />
        <path
            d="M36.25,112.48l-1.84-1.82,1.84-1.82a.73.73,0,0,0,0-1,.74.74,0,0,0-1,0l-1.84,1.82-1.84-1.82a.74.74,0,0,0-1.05,0,.7.7,0,0,0-.22.52.73.73,0,0,0,.21.52l1.84,1.82-1.83,1.82a.72.72,0,0,0,0,1,.74.74,0,0,0,1.05,0l1.83-1.82,1.84,1.82a.75.75,0,0,0,1.06,0A.73.73,0,0,0,36.25,112.48Z"
            transform="translate(-0.04)"
            fill="#f7c200"
            fillRule="evenodd"
        />
        <path
            d="M160,140c27.61,0,50-2.69,50-6s-22.39-6-50-6-50,2.69-50,6S132.39,140,160,140Z"
            transform="translate(-0.04)"
            fill="#7f8ea4"
            fillOpacity="0.12"
            fillRule="evenodd"
        />
        <path
            d="M160,122A61,61,0,1,0,99,61,61.06,61.06,0,0,0,160,122ZM160,2a59,59,0,1,1-59,59A59.07,59.07,0,0,1,160,2ZM179,52.4V46.34a19,19,0,1,0-37.9,0V52.4A6.41,6.41,0,0,0,136,58.71V66a1.27,1.27,0,1,0,2.53,0V58.71a3.84,3.84,0,0,1,3.79-3.87h35.36a3.84,3.84,0,0,1,3.79,3.87V86.55A3.86,3.86,0,0,1,179,90.2V82.43a1.27,1.27,0,1,0-2.53,0v8h-34.1a3.83,3.83,0,0,1-3.79-3.87V76.37a1.27,1.27,0,1,0-2.53,0V86.55A6.39,6.39,0,0,0,142.32,93h35.36A6.39,6.39,0,0,0,184,86.55V58.71A6.41,6.41,0,0,0,179,52.4Zm-28.55-.13V46.34a9.6,9.6,0,1,1,19.2,0v5.93Zm21.73-5.93v5.93h4.29V46.34a16.42,16.42,0,1,0-32.84,0v5.93h4.29V46.34a12.13,12.13,0,1,1,24.26,0ZM137.5,72a1.5,1.5,0,1,0-1.06-.44A1.52,1.52,0,0,0,137.5,72ZM160,86a3.84,3.84,0,0,0,3.89-3.79V75.5A6.72,6.72,0,0,0,167,69.82a7,7,0,0,0-14,0,6.76,6.76,0,0,0,3.09,5.66v6.73A3.85,3.85,0,0,0,160,86Zm-4.39-16.18a4.41,4.41,0,0,1,8.82,0A4.26,4.26,0,0,1,162,73.65a1.26,1.26,0,0,0-.71,1.13v7.43a1.3,1.3,0,0,1-2.59,0V74.76a1.26,1.26,0,0,0-.7-1.12A4.26,4.26,0,0,1,155.59,69.82ZM177.5,78a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z"
            transform="translate(-0.04)"
            fill="#ff426e"
            fillRule="evenodd"
        />
    </svg>
);

export default changePasswordImage;
