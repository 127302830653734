import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconButton } from '@partoohub/ui';

type ConnectionPageBulbProps = {
    isDarkTheme: boolean;
};

export const ConnectionPageBulb = styled(IconButton)<ConnectionPageBulbProps>`
    position: absolute;
    top: 0;
    left: 0;
    margin: 30px;

    i {
        transition: all 0.3s ease !important;
    }

    ${({ isDarkTheme }) =>
        isDarkTheme &&
        css`
            i {
                color: #ffd700 !important;

                @keyframes glow-pulse {
                    0%,
                    100% {
                        text-shadow:
                            0 0 10px rgba(255, 215, 0, 0.4),
                            0 0 20px rgba(255, 215, 0, 0.2);
                    }
                    50% {
                        text-shadow:
                            0 0 30px rgba(255, 215, 0, 1),
                            0 0 50px rgba(255, 215, 0, 1);
                    }
                }

                animation: glow-pulse 2s infinite ease-in-out;
            }
        `}
`;

export const ConnectionPageContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
`;

type ConnectionPageContentProps = {
    hasBackgroundColor?: boolean;
};

export const ConnectionPageLeftPart = styled.div<ConnectionPageContentProps>`
    display: flex;
    flex-basis: 100%;
    justify-content: center;

    @media (max-width: 768px) {
        padding-top: 48px;
        align-items: start;
    }

    ${({ hasBackgroundColor, theme }) =>
        !hasBackgroundColor &&
        css`
            background-color: ${theme.colors.theme.initial};
        `}
`;

export const ConnectionPageLeftPartContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 430px;
    flex-grow: 1;
    padding: 0 24px;
    justify-content: center;
`;

export const ConnectionPageRightPart = styled.div`
    display: flex;
    flex-basis: 100%;
    overflow: hidden;
    position: relative;
`;
