import React, { useEffect, useRef, useState } from 'react';

import { ProductIconV2, ProductIconsAppV2Enum } from '@partoohub/iconography';
import { Text } from '@partoohub/ui';
import fuzzysort from 'fuzzysort';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';

import { businessApiClient } from 'app/api/v2/api_calls/apiResources';
import { BUSINESSES } from 'app/common/data/queryKeysConstants';
import { VISIBILITY_LOCATION_PATH } from 'app/routing/routeIds';

import { useGetAvailableActions } from './hooks/actions';
import FaceMehIcon from './icons/FaceMehIcon';

import {
    Action,
    ActionContent,
    ActionIcon,
    ActionTitle,
    Backdrop,
    Line,
    LoaderIcon,
    Modal,
    ModalContent,
    ModalSearch,
    SearchIcon,
    SelectedIcon,
} from './QuickActionModal.styled';
import { ActionDomainEnum, ActionTypeEnum, QuickActionLink } from '../links';

type Props = {
    opened: boolean;
    specialKeyPressed: boolean;
    closeModal: () => void;
};

const QuickActionModal: React.FC<Props> = ({ opened, specialKeyPressed, closeModal }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [search, setSearch] = useState<string>('');
    const [actions, setActions] = useState<Array<QuickActionLink>>([]);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [selectionIndex, setSelectionIndex] = useState<number>(0);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const linkList = useGetAvailableActions();
    const { data: businessSearch, isLoading } = useQuery(
        [BUSINESSES, { query: search, per_page: 10 }],
        () => businessApiClient.searchBusinesses({ query: search, per_page: 10 }),
        { enabled: !!search },
    );

    useEffect(() => {
        if (opened) searchInputRef.current?.focus();
        else {
            setTimeout(() => {
                searchInputRef.current?.blur();
                setSearch('');
                setSelectionIndex(0);
            }, 100);
        }
    }, [opened]);

    useEffect(() => {
        if (isLoading) return;

        if (search) {
            let actionList: QuickActionLink[] = fuzzysort
                .go(search, linkList, {
                    limit: 10,
                    key: 'title',
                    threshold: 0.5,
                })
                .map(result => ({
                    ...result.obj,
                }));

            if (businessSearch?.businesses) {
                const businessActions = businessSearch.businesses.map(business => ({
                    title: business.name,
                    subtitle: `${business.address_full}, ${business.zipcode} ${business.city}, ${business.country}`,
                    link: `${VISIBILITY_LOCATION_PATH}/${business.id}`,
                    domain: ActionDomainEnum.VISIBILITY,
                    type: ActionTypeEnum.APP_LINK,
                    score: fuzzysort.single(search, business.name)?.score || 0,
                }));
                actionList = actionList.concat(businessActions);
                setFirstLoad(false);
            }

            setActions(actionList);
        } else {
            setActions([]);
            setFirstLoad(true);
        }
        setSelectionIndex(0);
    }, [isLoading, businessSearch]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (['Enter', 'ArrowUp', 'ArrowDown'].includes(event.key)) event.preventDefault();

        if (event.key === 'Enter' && actions.length) triggerAction(actions[selectionIndex]);
        else if (event.key === 'ArrowUp') selectPreviousLink();
        else if (event.key === 'ArrowDown') selectNextLink();
    };

    const selectNextLink = () => {
        if (!actions || actions.length <= 1) return;
        if (selectionIndex === actions.length - 1) setSelectionIndex(0);
        else setSelectionIndex(selectionIndex + 1);
    };

    const selectPreviousLink = () => {
        if (!actions || actions.length <= 1) return;
        if (selectionIndex === 0) setSelectionIndex(actions.length - 1);
        else setSelectionIndex(selectionIndex - 1);
    };

    const onActionClick = (event: any, action: QuickActionLink) => {
        event.preventDefault();
        triggerAction(action);
    };

    const triggerAction = (action: QuickActionLink) => {
        if (specialKeyPressed) window.open(action.link, '_blank')?.focus();
        else if (action.type === ActionTypeEnum.APP_LINK) navigate(action.link);
        else window.location.href = action.link;
        closeModal();
    };

    const getActionIcon = (action: QuickActionLink) => {
        if (action.domain === ActionDomainEnum.VISIBILITY)
            return (
                <ActionIcon>
                    <ProductIconV2
                        name={ProductIconsAppV2Enum.Locations}
                        isHovered={false}
                        color="secondary"
                    />
                </ActionIcon>
            );
        if (action.domain === ActionDomainEnum.POSTS)
            return (
                <ActionIcon>
                    <ProductIconV2
                        name={ProductIconsAppV2Enum.Posts}
                        isHovered={false}
                        color="secondary"
                    />
                </ActionIcon>
            );
        if (action.domain === ActionDomainEnum.CONVERSATIONS)
            return (
                <ActionIcon>
                    <ProductIconV2
                        name={ProductIconsAppV2Enum.Messages}
                        isHovered={false}
                        color="secondary"
                    />
                </ActionIcon>
            );
        if (action.domain === ActionDomainEnum.ANALYTICS)
            return (
                <ActionIcon>
                    <ProductIconV2
                        name={ProductIconsAppV2Enum.Analytics}
                        isHovered={false}
                        color="secondary"
                    />
                </ActionIcon>
            );
        if (action.domain === ActionDomainEnum.SETTINGS)
            return (
                <ActionIcon>
                    <ProductIconV2
                        name={ProductIconsAppV2Enum.Settings}
                        isHovered={false}
                        color="secondary"
                    />
                </ActionIcon>
            );
    };

    if (!opened) return;

    return (
        <>
            <Backdrop onClick={closeModal} />
            <Modal>
                <ModalSearch>
                    <SearchIcon className="fa-light fa-xl fa-magnifying-glass" />

                    <Text variant="heading4">
                        <input
                            ref={searchInputRef}
                            type="text"
                            onKeyDown={handleKeyDown}
                            name="quick_action_input"
                            value={search}
                            placeholder={t('quick_action__modal__input_text')}
                            onChange={e => setSearch(e.target.value)}
                            autoComplete="off"
                            onBlur={() => searchInputRef.current?.focus()}
                        />
                    </Text>

                    {isLoading && (
                        <LoaderIcon className="fa-sharp fa-regular fa-xl fa-spinner-third fa-spin" />
                    )}
                </ModalSearch>

                {search && !firstLoad && !actions.length && (
                    <ModalContent>
                        <Line>
                            <ActionContent>
                                <ActionIcon>
                                    <FaceMehIcon />
                                </ActionIcon>
                                <div>
                                    <Text variant="bodyLRegular">
                                        {t('quick_action__modal__not_found_title')}
                                    </Text>
                                    <Text variant="bodySRegular" color={'secondary'}>
                                        {t('quick_action__modal__not_found_subtitle')}
                                    </Text>
                                </div>
                            </ActionContent>
                        </Line>
                    </ModalContent>
                )}

                {!!actions.length && (
                    <ModalContent>
                        {actions.map((action, index) => (
                            <Action
                                href={action.link}
                                onClick={event => onActionClick(event, action)}
                                key={`${action.title} - ${action.link}`}
                                className={selectionIndex === index ? 'selected' : ''}
                            >
                                <ActionContent>
                                    {getActionIcon(action)}
                                    <div>
                                        <Text variant="bodyLRegular">
                                            <ActionTitle
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        fuzzysort
                                                            .single(search, action.title)
                                                            ?.highlight('<b>', '</b>') ||
                                                        action.title,
                                                }}
                                            />
                                        </Text>
                                        <Text variant="bodySRegular" color={'secondary'}>
                                            {action.subtitle}
                                        </Text>
                                    </div>
                                </ActionContent>

                                <SelectedIcon className="fa-solid fa-arrow-turn-down-left" />
                            </Action>
                        ))}
                    </ModalContent>
                )}
            </Modal>
        </>
    );
};

export default QuickActionModal;
