import axios from 'axios';

import { LoginHeaders } from 'app/api/types/connection';
import { authTokenApiClient } from 'app/api/v2/api_calls/apiResources';
import { headers } from 'app/api/v2/headers';
import { useJwtLogin } from 'app/pages/Login/hooks/mutations/useJwtLogin';
import { useLoginContext } from 'app/pages/Login/hooks/useLoginContext';
import { useLoginRedirect } from 'app/pages/Login/hooks/useLoginRedirect';

// Define the duration to renew JWT token in milliseconds
const MINUTES_TO_RENEW_JWT_TOKEN = 15 * 60 * 1000;

// Function to renew the JWT token
const renewJwtToken = async () => {
    try {
        const { token } = await authTokenApiClient.getAuthToken();
        headers.jwtToken.injectJwtToken(token, axios);
    } catch (error) {
        console.error('Error renewing JWT token:', error);
    }
};

export const startJwtTokenRenewal = () => {
    const renewInterval = MINUTES_TO_RENEW_JWT_TOKEN;

    // Function to handle the timing and trigger renewals
    const scheduleRenewal = () => {
        setTimeout(async () => {
            await renewJwtToken();
            // Reschedule the next renewal
            scheduleRenewal();
        }, renewInterval);
    };

    // Initial scheduling of the JWT renewal
    scheduleRenewal();
};

/**
 * Only called from IFRAME
 */
export const useJwtLoginMethod = (jwtHeaders: LoginHeaders) => {
    const { setIsLoading } = useLoginContext();
    const loginRedirect = useLoginRedirect();

    const loginJwt = useJwtLogin(
        ({ token }) => {
            // Set the token directly in the headers on Axios level
            headers.jwtToken.injectJwtToken(token, axios);
            startJwtTokenRenewal();
            loginRedirect();
        },
        () => {
            setIsLoading(false);
        },
    );

    return () => {
        setIsLoading(true);

        // This one create a session cookie
        loginJwt.mutate(jwtHeaders);
    };
};
