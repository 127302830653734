export const openQuickAction = () => {
    const element = document.querySelector('body');
    const event = new KeyboardEvent('keydown', {
        key: 'k',
        ctrlKey: true,
        bubbles: true,
        metaKey: true,
    });
    element?.dispatchEvent(event);
};
