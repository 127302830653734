import {
    getSurvicateInstance,
    initSurvicate,
} from '@survicate/survicate-web-surveys-wrapper/widget_wrapper';

import { differenceInDays } from 'date-fns';

import { V2OrgData } from 'app/api/types/org';
import { V2UserData } from 'app/api/types/user';
import { NPS_WHITELIST_PROVIDERS } from 'app/common/data/providers';
import { useHasMessagePage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasMessagePage';
import { useHasDiffusionPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasDiffusionPage';
import { useHasReviewInvitePage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewInvitePage';
import { useHasReviewManagementPage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewManagementPage';
import env from 'app/common/services/getEnvironment';

export const useHandleSurvicate = (me: V2UserData, organization: V2OrgData) => {
    return () =>
        new Promise((resolve, reject) => {
            if (!NPS_WHITELIST_PROVIDERS.includes(me.provider)) return;

            const key = '6f1c1a6b82242e68067aaaf9407aebc1';
            const createdAt = (me.created_at && new Date(me.created_at * 1000)) || null;
            const subscriptionDate = createdAt?.toISOString().split('T')[0] ?? '';
            const daysSinceSub = (createdAt && differenceInDays(new Date(), createdAt)) || 0;
            const traits = {
                env: env.getEnv(),
                userId: me.id,
                email: me.email,
                name: `${me.first_name} ${me.last_name}`,
                orgType: organization.organization_type,
                role: me.role,
                hasPresenceManagement: String(useHasDiffusionPage()),
                hasReviewManagement: String(useHasReviewManagementPage()),
                hasReviewBooster: String(useHasReviewInvitePage()),
                hasBusinessMessages: String(useHasMessagePage()),
                subscriptionDate: subscriptionDate,
                daysSinceSubscription: daysSinceSub,
                language: me.lang,
                isShadowUser: !!me.shadow_user_id,
            };
            initSurvicate({ workspaceKey: key, traits: traits })
                .then(() => resolve('[Survicate] Initialized successfully.'))
                .catch(() => reject(new Error('[Survicate] Error initializing.')));
        });
};

export const removeSurvicateScript = (): void => {
    const survicate = getSurvicateInstance();
    if (survicate) survicate.destroyVisitor();
};
