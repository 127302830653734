import styled from '@emotion/styled';
import { Button } from '@partoohub/ui';

export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const LoginFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const LoginFormSuccessMessage = styled.div`
    display: flex;
    align-items: center;
    min-height: 50px;
    background-color: ${({ theme }) => theme.colors.success.alpha};
    color: ${({ theme }) => theme.colors.success.initial};
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 16px;

    > img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
`;

export const LoginFormMessage = styled.div`
    display: flex;
    align-items: first baseline;
    margin-bottom: 24px;
    color: ${({ theme }) => theme.colors.secondary.initial};

    > i {
        margin-right: 4px;
    }
`;

export const LoginFormButton = styled(Button)``;
