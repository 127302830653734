import { FC } from 'react';

import { Navigate, Route, Routes } from 'react-router';

import { AppContexts } from 'app/pages/app';
import { Login } from 'app/pages/Login';
import { InvitationPage } from 'app/pages/Login/forms/Invitation';
import { LoginSsoPage } from 'app/pages/Login/forms/LoginSso';
import { LostPasswordPage } from 'app/pages/Login/forms/LostPassword';
import { LoginPage } from 'app/pages/Login/forms/MainLogin';
import { ResetPasswordPage } from 'app/pages/Login/forms/ResetPassword';
import { LogoutPage } from 'app/pages/Logout';
import { NotFoundPage } from 'app/pages/notFoundPage/NotFoundPage';
import { MessagingPrivacyPolicy } from 'app/pages/privacyPolicy';
import {
    CONNECTION_INVITE_PATH,
    CONNECTION_PATH,
    CONNECTION_RESET_PASSWORD_TEMPLATE_PATH,
    ID_PARAM_TOKEN,
    INVITE_PATH_TOKEN,
    LOG_OUT_PATH,
    LOST_PASSWORD_PATH_TOKEN,
    MESSAGING_PATH_TOKEN,
    MESSAGING_PRIVACY_POLICY_PATH,
    NOT_FOUND_PATH,
    PATH_PARAM_TOKEN,
    PRIVACY_PATH,
    RESET_PASSWORD_PATH_TOKEN,
    SSO_PATH_TOKEN,
} from 'app/routing/routeIds';

export const RoutesComponent: FC = () => {
    return (
        <Routes>
            {/* PRIVACY ROUTES */}
            <Route path={PRIVACY_PATH}>
                <Route index element={<Navigate replace to={MESSAGING_PRIVACY_POLICY_PATH} />} />
                <Route path={MESSAGING_PATH_TOKEN}>
                    <Route
                        index
                        element={<Navigate replace to={MESSAGING_PRIVACY_POLICY_PATH} />}
                    />
                    <Route path={`:${ID_PARAM_TOKEN}`} element={<MessagingPrivacyPolicy />} />
                </Route>
            </Route>

            {/* CONNECTION ROUTES */}
            <Route path={CONNECTION_PATH} element={<Login />}>
                <Route index element={<LoginPage />} />
                <Route path={INVITE_PATH_TOKEN}>
                    <Route index element={<Navigate to={CONNECTION_INVITE_PATH} />} />
                    <Route path={`:${PATH_PARAM_TOKEN}`} element={<InvitationPage />} />
                </Route>
                <Route path={RESET_PASSWORD_PATH_TOKEN}>
                    <Route
                        index
                        element={<Navigate to={CONNECTION_RESET_PASSWORD_TEMPLATE_PATH} />}
                    />
                    <Route path={`:${PATH_PARAM_TOKEN}`} element={<ResetPasswordPage />} />
                </Route>
                <Route path={LOST_PASSWORD_PATH_TOKEN} element={<LostPasswordPage />} />
                <Route path={SSO_PATH_TOKEN} element={<LoginSsoPage />} />
            </Route>

            {/* LOGOUT ROUTE */}
            <Route path={LOG_OUT_PATH} element={<LogoutPage />} />

            {/* NOT FOUND ROUTE */}
            <Route path={NOT_FOUND_PATH} element={<NotFoundPage />} />

            {/* Routes accessible once logged in */}
            <Route path="*" element={<AppContexts />} />
        </Routes>
    );
};
