import { FormEvent } from 'react';

import { TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import isPasswordValid from 'app/common/services/passwordChecker';
import { LoginLink } from 'app/pages/Login/forms/LoginLink';
import { useLogin } from 'app/pages/Login/hooks/mutations/useLogin';
import { useLoginContext } from 'app/pages/Login/hooks/useLoginContext';
import { useLoginRedirect } from 'app/pages/Login/hooks/useLoginRedirect';

import { CONNECTION_LOST_PASSWORD_PATH } from 'app/routing/routeIds';

import { FormContainer } from './EmailPasswordForm.styled';
import LoginButton from './LoginButton';

export const EmailPasswordForm = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { email, setEmail, password, setPassword, setIsLoading, setPasswordModal } =
        useLoginContext();
    const handleSubmit = () => {
        setIsLoading(true);
        login.mutate({
            params: {
                email,
                password,
            },
        });
    };

    const handleSubmitForm = (e: FormEvent) => {
        e.preventDefault();
        handleSubmit();
    };

    const loginRedirect = useLoginRedirect();
    const login = useLogin(
        () => {
            if (isPasswordValid(password)) {
                loginRedirect();
            } else {
                setPasswordModal(true);
            }
        },
        () => {
            setIsLoading(false);
        },
    );

    // @ts-expect-error
    const fieldsErrors = login.error?.response?.data?.errors?.json;
    // @ts-expect-error
    const globalErrors = login.error?.response?.data?.errors?.authentication;

    return (
        <FormContainer onSubmit={handleSubmitForm}>
            <TextInput
                dataTrackId="email_input"
                label={t('email_extended')}
                onChange={setEmail}
                value={email}
                error={!!globalErrors || !!fieldsErrors?.email}
                notice={fieldsErrors?.email ? 'Email not valid' : ''}
                type="email"
            />

            <TextInput
                dataTrackId="password_input"
                label={t('password')}
                type="password"
                value={password}
                onChange={setPassword}
                error={!!globalErrors || !!fieldsErrors?.password}
                notice={fieldsErrors?.password ? 'Password too short' : ''}
            />

            <LoginLink
                onClick={() => navigate(CONNECTION_LOST_PASSWORD_PATH)}
                text={t('ask_forgotten_password')}
            />

            <LoginButton
                handleSubmit={handleSubmit}
                isSuccess={login.isSuccess}
                fieldsErrors={fieldsErrors}
                globalErrors={globalErrors}
            />
        </FormContainer>
    );
};
