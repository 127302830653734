import { FC } from 'react';

import { Navigate, Route, Routes } from 'react-router';

import LoadingView from 'app/common/components/LoadingView';
import useMe from 'app/common/hooks/queries/useMeUncamel';

import { WhatsappAccountImportPage } from 'app/pages/mobile/WhatsappAccountImport';

import { NOT_FOUND_PATH } from './routeIds';

/**
 * Routes used by the mobile app, in a WebView.
 * They do not have a sidebar, or the typical app layout.
 */
export const MobileRoutes: FC = () => {
    const { data: me } = useMe();

    if (!me) {
        return <LoadingView />;
    }

    return (
        <Routes>
            {/* Mobile routes */}
            <Route path="/whatsapp-account-import" element={<WhatsappAccountImportPage />} />
            <Route path="*" element={<Navigate replace to={NOT_FOUND_PATH} />} />
        </Routes>
    );
};
