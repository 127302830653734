import { useMutation } from 'react-query';

import { LoginSsoOidcData } from 'app/api/types/connection';
import api from 'app/api/v2/api_calls';

/**
 * Used to login the user using SSO OIDC using the oidc/{ssoAlias}/auth endpoint.
 * ⚠️ Will create a session cookie
 */
export const useSsoOidcLogin = (
    ssoAlias: string,
    onSuccess: (result: any) => void = () => undefined,
    onError: () => void = () => undefined,
) => {
    return useMutation((params: LoginSsoOidcData) => api.connection.oidcAuth(ssoAlias, params), {
        onSuccess,
        onError,
    });
};
