import { Stack } from '@partoohub/ui';

import Loader from 'app/common/designSystem/components/atoms/svg/Loader';
import { useHandleLogout } from 'app/pages/Logout/hooks/useHandleLogout';

export const LogoutPage = () => {
    // Empty the store and redirect to the connection page
    useHandleLogout();

    return (
        <Stack alignItems="center" justifyContent="center">
            <Loader />
        </Stack>
    );
};
