import { all, cancel, fork, take } from 'redux-saga/effects';

import { testPath } from 'app/common/data/routeIds';
import IntercomWrapper from 'app/common/services/intercomWrapper';
import { LOG_OUT_PATH } from 'app/routing/routeIds';
import { sdkBridge } from 'app/SDKBridge';
import { register as registerServiceWorker } from 'app/serviceWorkers';
import queryClient from 'app/states/queryClient';
import isEnvProduction from 'app/utils/isEnvProduction';

function* deletePageSagas(pageName, saga) {
    if (!isEnvProduction) {
        console.warn(`Killing ${pageName} sagas:`);
    }

    yield cancel(saga);
}

const createPageSagas = (pageName, ...sagas) =>
    function* () {
        if (!isEnvProduction) {
            console.warn(`Forking ${pageName} sagas:`);
            console.warn(sagas);
        }

        yield all([...sagas].map(saga => fork(saga)));
    };

const forkSharedSagas = (...sagas) =>
    function* () {
        if (!isEnvProduction) {
            console.warn('Spawning shared sagas:');
            console.warn(sagas);
        }

        yield all([...sagas].map(saga => fork(saga)));
    };

const createSharedSagas = (...sharedSagas) => {
    if (sharedSagas.length) {
        return fork(forkSharedSagas(...sharedSagas));
    }
    return undefined;
};

function* deleteSharedSagas(...sagas) {
    if (!isEnvProduction) {
        console.warn('Killing shared sagas');
    }

    yield all([...sagas].map(saga => cancel(saga)));
}

const getSagasByPage = (currentPage: string, pageSagas) => {
    for (const [path, sagas] of Object.entries(pageSagas)) {
        if (testPath(path, currentPage)) {
            // @ts-ignore
            return createPageSagas(currentPage, ...sagas);
        }
    }

    return undefined;
};

/**
 * 1- Starts all shared sagas in the root execution context
 *
 * 2- Listen for page changes and dynamically starts all the corresponding sagas for the current page
 * and cancels all sagas belonging to the previous page.
 */
const createSagaOrchestrator = (sharedSagas: any[], pageSagas: { [key in any]?: any[] }) => {
    function* sagaOrchestrator() {
        let currentSharedSagas;
        let currentPageSagas;
        let pathname;
        let prevPathname;

        /**
         * We need to fork shared sagas in order to cancel them at logout
         */
        currentSharedSagas = yield createSharedSagas(...sharedSagas);

        /**
         * We register the service workers
         */
        registerServiceWorker();

        // prettier-ignore

        while (({ payload: { location: { pathname } } } = yield take('@@router/ON_LOCATION_CHANGED'))) {
            if (pathname && (!prevPathname || prevPathname !== pathname)) {
                IntercomWrapper.update({
                    pathname,
                });
                sdkBridge.sendPageChanged(document.location.href);
                if (currentPageSagas) {
                    yield* deletePageSagas(prevPathname, currentPageSagas);
                }

                const newPageSagas = getSagasByPage(pathname, pageSagas);

                if (newPageSagas) {
                    currentPageSagas = yield fork(newPageSagas);
                }

                /**
                 * We need to reload shared sagas after logout
                 */
                if (prevPathname === LOG_OUT_PATH) {
                    // On logout, we need to clear query client to reset all backend calls
                    queryClient.clear();
                    if (currentSharedSagas) {
                        yield deleteSharedSagas(currentSharedSagas);
                        currentSharedSagas = yield createSharedSagas(...sharedSagas);
                    }
                }
                prevPathname = pathname;
            }
        }
    }
    return sagaOrchestrator;
};

export default createSagaOrchestrator;
