import 'regenerator-runtime/runtime';
import { createRouterMiddleware } from '@lagunovsky/redux-react-router';
import axios from 'axios';
import { applyMiddleware, compose, createStore } from 'redux';

import { SET_ANY_STATE, setAnyStateReducer } from 'app/common/hooks/useGetParamReduxSync';
import { axiosInterceptor401 } from 'app/pages/app/hooks/useHandleAxiosInterceptor';
import { browserHistory } from 'app/states/history';
import reducers from 'app/states/reducers';

// Used to reset all reducers data on logout
export const LOGOUT_RESET_REDUCERS_DATA = 'LOGOUT_RESET_REDUCERS_DATA';
export const logoutResetReducersData = () => ({
    type: LOGOUT_RESET_REDUCERS_DATA,
});

const configureStore = () => {
    const middlewares = [createRouterMiddleware(browserHistory)];
    let composeEnhancer = compose;

    // WHAT IS THIS?
    if (process.env['NODE_ENV'] !== 'production') {
        composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  actionCreators: {},
              })
            : compose; // eslint-enable
    }

    const appReducers = reducers(browserHistory);

    const rootReducer = (state, action) => {
        if (action.type === LOGOUT_RESET_REDUCERS_DATA) {
            state = undefined;
        } else if (action.type === SET_ANY_STATE) {
            state = setAnyStateReducer(state, action);
        }

        return appReducers(state, action);
    };

    const store = createStore(rootReducer, composeEnhancer(applyMiddleware(...middlewares)));
    axios.interceptors.response.use(undefined, error => {
        if (error.response?.status === 401) {
            store.dispatch(axiosInterceptor401());
        }
        throw error;
    });

    return store;
};

export default configureStore;
