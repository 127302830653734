import { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { STATIC_BASE_URL } from 'app/config';
import { useLogin } from 'app/pages/Login/hooks/mutations/useLogin';
import { useLoginContext } from 'app/pages/Login/hooks/useLoginContext';
import { useLoginRedirect } from 'app/pages/Login/hooks/useLoginRedirect';

import {
    GoogleSigninButton,
    GoogleSigninButtonContainer,
    GoogleSigninButtonFake,
    GoogleSigninError,
    GoogleSigninTextFake,
} from './GoogleSigninButton.styled';

const CLIENT_ID = '583584673263-n1gcbk83m86i3mg25lafb5pa4cdp6bii.apps.googleusercontent.com';

export const GoogleSignInButton = () => {
    const { t } = useTranslation();

    const { setIsLoading } = useLoginContext();

    const loginRedirect = useLoginRedirect();
    const login = useLogin(loginRedirect, () => {
        setIsLoading(false);
    });

    const googleSigninRef = useRef<HTMLDivElement>(null);
    const handleCallbackResponse = (response: any) => {
        if (!response?.credential) return;
        setIsLoading(true);
        login.mutate({
            params: {
                id_token: response.credential,
            },
        });
    };

    useEffect(() => {
        if (window.google?.accounts?.id) {
            window.google.accounts.id.initialize({
                client_id: CLIENT_ID,
                callback: handleCallbackResponse,
                prompt: true,
            });
        }
    }, [window.google?.accounts?.id]);

    useEffect(() => {
        if (googleSigninRef.current && window.google?.accounts?.id) {
            window.google.accounts.id.renderButton(googleSigninRef.current, {
                theme: 'outline',
                size: 'large',
                width: '400px', // Max width allowed by Google
            });
        }
    }, [googleSigninRef, window.google?.accounts?.id]);

    if (!window.google?.accounts?.id) {
        return null;
    }

    return (
        <div>
            <GoogleSigninButtonContainer>
                <GoogleSigninButtonFake>
                    <img
                        src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/google_white_background.svg`}
                        alt="google"
                    />
                    <GoogleSigninTextFake>{t('google_sign_in')}</GoogleSigninTextFake>
                </GoogleSigninButtonFake>
                <GoogleSigninButton ref={googleSigninRef} />
            </GoogleSigninButtonContainer>
            {login.isError && (
                <GoogleSigninError>
                    <img
                        src={`${STATIC_BASE_URL}/images/common/custom-svg/error.svg`}
                        alt="error_picto"
                    />
                    <span>{t('google_sign_in_error')}</span>
                </GoogleSigninError>
            )}
        </div>
    );
};
