import React, { useState } from 'react';

import { Button, Modal } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { SMB } from 'app/common/data/organization';
import { ME } from 'app/common/data/queryKeysConstants';
import { ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useOrganization from 'app/common/hooks/queries/useOrganization';

import { getTCLink } from 'app/common/services/languages';

import { STATIC_BASE_URL } from 'app/config';
import queryClient from 'app/states/queryClient';

import {
    ModalButtons,
    ModalDescription,
    ModalTitle,
    TCModalContainer,
    WarningImage,
} from './TCModal.styled';

const TCModal = () => {
    const { t } = useTranslation();

    const { data: me } = useMe();
    const { data: org } = useOrganization(me?.org_id ?? 0, !!me?.org_id);

    const [showModal, setShowModal] = useState<boolean>(true);

    const tcAccepted = !!me?.terms_and_conditions_accepted_at;
    const isSmb = org?.organization_type === SMB;
    const isAdmin = me?.role === ADMIN;
    const isShadowUser = !!me?.shadow_user_id && me?.shadow_user_role === ADMIN;

    const displayModal = isSmb && showModal && !tcAccepted && !isAdmin && !isShadowUser;

    const tcLink = getTCLink(me?.lang ?? 'en');

    // This one is not defined in its own custom hook because it will never be used anywhere else
    const acceptTC = useMutation(() => api.user.acceptTermsAndConditions(), {
        onSuccess: () => {
            queryClient.invalidateQueries(invalidatesAny(ME)).then(() => setShowModal(false));
        },
    });

    return (
        <Modal isOpen={displayModal} closeModal={() => setShowModal(false)}>
            <TCModalContainer>
                <WarningImage
                    src={`${STATIC_BASE_URL}/images/app/confirm_category_remap.svg`}
                    alt="warning_image"
                />
                <ModalTitle>{t('terms_and_conditions_modal_title')}</ModalTitle>
                <ModalDescription href={tcLink} target="_blank">
                    {t('terms_and_conditions_modal_subtitle')}
                </ModalDescription>
                <ModalButtons>
                    <Button
                        shape="cube"
                        onClick={() => acceptTC.mutate()}
                        dataTrackId="terms_and_conditions_accept"
                        size="large"
                        variant="primary"
                    >
                        {t('terms_and_conditions_modal_accept_button')}
                    </Button>
                </ModalButtons>
            </TCModalContainer>
        </Modal>
    );
};

export default TCModal;
