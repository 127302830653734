import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';
import {
    BACKDROP_QUICK_ACTION_MODAL_Z_INDEX,
    MODAL_QUICK_ACTION_MODAL_Z_INDEX,
} from '@partoohub/ui';

export const Backdrop = styled.div`
    z-index: ${BACKDROP_QUICK_ACTION_MODAL_Z_INDEX};
    opacity: 0.75;

    position: fixed;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    background-color: rgb(15, 22, 31);

    transition:
        0.1s z-index,
        0.1s opacity;
`;

export const Modal = styled.div`
    z-index: ${MODAL_QUICK_ACTION_MODAL_Z_INDEX};
    opacity: 1;

    position: fixed;
    top: 75px;
    left: 50%;

    border-radius: 16px;

    width: 600px;
    max-height: calc(100vh - 150px);

    background: var(
        --theme-gradient,
        linear-gradient(
            135deg,
            #e6f1f7 0%,
            #f3f3fc 23.96%,
            #f6f4f1 50%,
            #f6f2ec 73.96%,
            #f6f8fc 100%
        )
    );
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(12px);

    transform: translateX(-50%);
    transition:
        0.1s z-index,
        0.1s opacity;
`;

export const ModalSearch = styled.div`
    display: flex;
    align-items: center;

    padding: 24px;

    min-height: 74px;

    div {
        width: 100%;
    }

    input {
        border: 0;
        outline: none;

        width: 100%;

        background-color: transparent;

        &:placeholder-shown {
            opacity: 0.12;
        }
    }
`;

export const SearchIcon = styled.i`
    margin-right: 16px;

    color: rgba(0, 0, 0, 0.75);
    font-size: 24px;
`;

export const LoaderIcon = styled.i`
    margin-left: 16px;

    color: rgba(0, 0, 0, 0.75);
    font-size: 24px;
`;

export const ModalContent = styled.div`
    overflow-y: scroll;

    padding: 8px;

    max-height: calc(100vh - 234px);

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const SelectedIcon = styled.div`
    opacity: 0;

    margin-left: 8px;

    fill: rgba(0, 0, 0, 0.5);
`;

export const Line = styled.a`
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 4px;
    padding: 8px;

    &:hover {
        text-decoration: none;
    }
`;

export const Action = styled(Line)`
    &:hover {
        background-color: rgba(0, 133, 242, 0.12);
    }

    &.selected {
        background-color: rgba(0, 133, 242, 0.12);

        ${SelectedIcon} {
            opacity: 1;
        }
    }
`;

export const ActionContent = styled.div`
    display: flex;
    align-items: center;
`;

export const ActionTitle = styled.div`
    b {
        background-color: #bad9f8;
    }
`;

export const ActionIcon = styled.div`
    margin-right: 8px;

    height: 24px;
    width: 24px;

    svg {
        width: 100%;
        height: 100%;

        path {
            fill: ${({ theme }) => theme.colors.secondary.initial};
        }
    }
`;

export const NoResult = styled.div`
    padding: 8px 16px;

    color: #333333;
    font-size: 16px;
    line-height: 24px;
`;

export const ModalFooter = styled.div`
    display: flex;
    gap: 24px;

    border-top: 1px solid ${greyColorObject.dark};
    padding: 4px 16px;

    height: 28px;
`;

export const FooterElement = styled.div`
    display: flex;
`;

export const FooterIcon = styled.i`
    display: flex;
    align-items: center;
    gap: 8px;

    margin-right: 12px;

    color: #979693;
    font-size: 12px;
`;

export const FooterText = styled.div`
    color: #979693;
    font-size: 12px;
`;
