import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { LOW_PRIORITY_COMPONENT_Z_INDEX, Link } from '@partoohub/ui';

export const NewReactContentContainer = styled.div`
    height: 100dvh;

    overflow: auto;
    overflow-anchor: none;
    -webkit-overflow-scrolling: touch;
`;

type ReactPageContentProps = {
    applyOldBackground?: boolean;
};

export const ReactPageContainer = styled.div<ReactPageContentProps>`
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    ${({ theme, applyOldBackground }) =>
        applyOldBackground &&
        css`
            // TODO : To delete as soon as we apply new linear-gradient background on all website
            background: ${theme.colors.theme.background};
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            box-shadow: -8px 0 10px 0 rgba(0, 0, 0, 0.05);
        `};
`;

export const FlexRow = styled.div`
    display: flex;
    flex-flow: row nowrap;
`;

export const SkipLink = styled(Link)`
    position: absolute;
    top: -40px;
    right: 10px;
    padding: 8px;
    z-index: ${LOW_PRIORITY_COMPONENT_Z_INDEX};
    :focus {
        top: 2px;
        right: 48px;
        outline: none;
    }
`;
