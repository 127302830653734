import { useState } from 'react';

import { Button, ButtonVariants, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import api from 'app/api/v2/api_calls';
import { FormContainer, LoginFormContainer } from 'app/pages/Login/forms/loginForm.styled';
import { LoginLink } from 'app/pages/Login/forms/LoginLink';
import { LoginLoadingView } from 'app/pages/Login/forms/MainLogin/LoginLoadingView';
import { useLoginContext } from 'app/pages/Login/hooks/useLoginContext';
import { CONNECTION_PATH } from 'app/routing/routeIds';

import { OverrideSubtitle } from './LoginSso.styled';

const getStatefulButtonProps = (email: string, error: string): [ButtonVariants, string] => {
    let status: ButtonVariants = 'primary';

    if (!email) {
        status = 'secondary';
    } else if (error) {
        status = 'danger';
    }

    return [status, 'connection_to_app'];
};

export const LoginSso = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { email, setEmail, isLoading, setIsLoading } = useLoginContext();

    const [error, setError] = useState('');

    const [variant, label] = getStatefulButtonProps(email, error);

    const onEmailChange = (newEmail: string) => {
        setError('');
        setEmail(newEmail);
    };

    const ssoLogin = () => {
        setIsLoading(true);
        api.user
            .getSsoInfo(email)
            .then(data => {
                const [ssoType, ssoAlias] = [data.sso_type, data.sso_alias];

                if (!ssoType || !ssoAlias) {
                    setError(t('sso_sign_in_error'));
                    setIsLoading(false);
                } else {
                    window.location = api.connection.generateSsoLoginUrl(ssoType, ssoAlias) as any;
                }
            })
            .catch(() => {
                setError(t('email_invalid'));
                setIsLoading(false);
            });
    };

    if (isLoading) {
        return <LoginLoadingView />;
    }

    return (
        <LoginFormContainer isLoading={false}>
            <OverrideSubtitle variant="heading6">{t('sso_sign_in_title')}</OverrideSubtitle>
            <FormContainer onSubmit={ssoLogin}>
                <TextInput
                    dataTrackId="sso_login__email_input"
                    label={t('email_extended')}
                    onChange={onEmailChange}
                    value={email}
                    error={!!error}
                    notice={error}
                    type="email"
                />
                <Button
                    variant={variant}
                    shape="cube"
                    size="large"
                    full
                    onClick={ssoLogin}
                    dataTrackId="login_sso__submit_button"
                    type="submit"
                    disabled={variant === 'secondary'}
                >
                    {t(label)}
                </Button>
            </FormContainer>

            <LoginLink onClick={() => navigate(CONNECTION_PATH)} text={t('return_to_login')} />
        </LoginFormContainer>
    );
};
