import { WHATSAPP_APP_ID } from 'app/config';

export const handleWhatsappEmbeddedSignup = () => {
    return new Promise((resolve, reject) => {
        window.fbAsyncInit = function () {
            try {
                FB.init({
                    appId: WHATSAPP_APP_ID,
                    cookie: true,
                    xfbml: true,
                    version: 'v18.0',
                });
                resolve('[Whatsapp] Script loaded successfully');
            } catch (error) {
                reject(new Error('[Whatsapp] Error loading the script'));
            }
        };

        // Load the JavaScript SDK asynchronously
        (function (d, s, id) {
            let js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    });
};
