import styled from '@emotion/styled';

export const ConnectionPageContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const ConnectionPageLeftPart = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;

    @media (max-width: 768px) {
        margin-top: 48px;
        align-items: start;
    }
`;

/*
    The media query: (min-height: 520px) is used to avoid having the logo cropped when 
    the height of the screen is too small.
*/
export const ConnectionPageLeftPartContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 430px;
    padding: 0 24px;

    @media (min-height: 520px) {
        justify-content: center;
    }
`;

export const ConnectionPageRightPart = styled.div`
    display: flex;
    flex: 1.3;
    overflow: hidden;
    position: relative;
    align-items: center;

    @media (max-width: 768px) {
        align-items: start;
        justify-content: center;
    }

    > img {
        position: absolute;
        height: 170%;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;

        @media (max-width: 768px) {
            height: auto;
            width: 110vw;
        }
    }
`;
