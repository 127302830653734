import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const NewReactContentContainer = styled.div`
    height: 100vh;

    overflow: auto;
    overflow-anchor: none;
    -webkit-overflow-scrolling: touch;
`;

export const ReactPageContainer = styled.div<{ applyNewBackground?: boolean }>`
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    background: ${({ theme }) => theme.colors.theme.backgroundTheme};
    ${({ theme, applyNewBackground }) =>
        !applyNewBackground &&
        css`
            // TODO : To delete as soon as we apply new linear-gradient background oon all website
            background: ${theme.colors.theme.background};
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            box-shadow: -8px 0 10px 0 rgba(0, 0, 0, 0.05);
        `};
`;

export const FlexRow = styled.div`
    display: flex;
    flex-flow: row nowrap;
`;
