import { LoginSsoOidcData } from 'app/api/types/connection';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { useSsoOidcLogin } from 'app/pages/Login/hooks/mutations/useSsoOidcLogin';
import { useLoginContext } from 'app/pages/Login/hooks/useLoginContext';
import { useLoginRedirect } from 'app/pages/Login/hooks/useLoginRedirect';
import { LoginQueryKeys } from 'app/pages/Login/utils/enums';

export const useSsoOidcLoginMethod = (ssoAlias: string) => {
    const loginRedirect = useLoginRedirect();

    const { setIsLoading } = useLoginContext();

    const [code] = useStateQueryParams(LoginQueryKeys.SSO_CODE);
    const [state] = useStateQueryParams(LoginQueryKeys.SSO_STATE);
    const [error] = useStateQueryParams(LoginQueryKeys.SSO_ERROR);
    const [errorDescription] = useStateQueryParams(LoginQueryKeys.SSO_ERROR_DESCRIPTION);

    const params: LoginSsoOidcData = {
        code,
        state,
        ...(error && { error }),
        ...(errorDescription && { error_description: errorDescription }),
    };

    const login = useSsoOidcLogin(
        ssoAlias,
        ({ redirect_url }) => {
            if (redirect_url) {
                // Another redirection needed for mobile sso
                window.location = redirect_url;
            } else {
                loginRedirect();
            }
        },
        () => {
            setIsLoading(false);
        },
    );

    return () => {
        setIsLoading(true);
        login.mutate(params);
    };
};
