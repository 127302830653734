import { useNavigate } from 'react-router';

import { useReduxEffect } from 'app/common/hooks/useReduxEffect';
import { LOG_OUT_PATH } from 'app/routing/routeIds';

const AXIOS_INTERCEPTOR_401 = 'AXIOS_INTERCEPTOR_401';
export const axiosInterceptor401 = () => ({ type: AXIOS_INTERCEPTOR_401 });

export const useHandleAxiosInterceptor = () => {
    const navigate = useNavigate();

    // Redirect to logout page on 401 error
    useReduxEffect(() => {
        navigate(LOG_OUT_PATH);
    }, [AXIOS_INTERCEPTOR_401]);
};
