import { put, select, takeEvery } from 'redux-saga/effects';

import { APPLY_FILTER_BY_BUSINESS_FILTERS } from 'app/common/components/businessModalDeprecated/reducers/filterByBusiness';
import { testPath } from 'app/common/data/routeIds';
import { Saga } from 'app/common/types';
import { ANALYTICS_REVIEW_PATH } from 'app/routing/routeIds';
import {
    APPLY_FILTERS,
    RESET_REVIEW_FILTER,
    SET_INPUT_DATE_FILTER,
} from 'app/states/reviews/filters';
import { locationSelector } from 'app/states/router';

import updateBucketSaga from './updateBucketSaga';
import {
    getReviewStatsPeriodRequestAction,
    getReviewStatsTotalRequestAction,
    getReviewsEvolutionRequestAction,
    resetEvolutionPeriodAnalyticsAction,
    resetEvolutionTotalAnalyticsAction,
} from '../actions';
import { ReviewAnalyticsState } from '../reducers';
import { isPeriodSelector, isQualitativeSelector, reviewAnalyticsSelector } from '../selectors';

function* applyDataFilters({ input, type, name }) {
    const { pathname } = yield select(locationSelector);

    if (testPath(ANALYTICS_REVIEW_PATH, pathname)) {
        if (input == null) {
            const reviewAnalyticsState = (yield select(
                reviewAnalyticsSelector,
            )) as ReviewAnalyticsState;

            // evolution average selected
            const isQualitative = isQualitativeSelector(reviewAnalyticsState);

            // evolution period button selected
            const isPeriod = isPeriodSelector(reviewAnalyticsState);

            // if any filter has changed, re-fetch all periods and reset periodEvolutions
            yield put(getReviewStatsPeriodRequestAction());
            yield put(resetEvolutionPeriodAnalyticsAction());

            // if a date filter has changed
            if (type === SET_INPUT_DATE_FILTER || name === 'date') {
                // and "In the period" is selected in the evolution graph
                // we re-evaluate the seasonality
                if (isPeriod) {
                    yield* updateBucketSaga();
                }
            } else {
                // if a nonDate filter has changed, we re-fetch the "Totals"
                yield put(getReviewStatsTotalRequestAction());
                yield put(resetEvolutionTotalAnalyticsAction());
                yield put(getReviewsEvolutionRequestAction(isQualitative, isPeriod));
            }
        }
    }
}

function* applyDataFiltersSaga(): Saga {
    yield takeEvery(SET_INPUT_DATE_FILTER, applyDataFilters);
    yield takeEvery(APPLY_FILTERS, applyDataFilters);
    yield takeEvery(APPLY_FILTER_BY_BUSINESS_FILTERS, applyDataFilters);
    yield takeEvery(RESET_REVIEW_FILTER, applyDataFilters);
}

export default applyDataFiltersSaga;
