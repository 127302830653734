import { Conversation } from '@partoohub/api-client';

import { MESSAGING_CONVERSATION_STATS } from 'app/common/data/queryKeysConstants';
import { AssignFilterOption, MessagingFilters } from 'app/states/messaging';

import queryClient from 'app/states/queryClient';

import { removeConversation, updateConversation } from '../../services/handleConversationsCache';

export const handleConversationIsAutomatedChange = (
    conversation: Conversation,
    activeConversation: Conversation,
    { statusFilter, assignFilter, businessFilters }: MessagingFilters,
    setConversationParamId: (conversationParam: string) => void,
) => {
    // Avoid override of assigned_user_id
    if (
        activeConversation.id === conversation.id &&
        activeConversation.assigned_user_id !== AssignFilterOption.AUTOMATED
    ) {
        conversation.assigned_user_id = activeConversation.assigned_user_id;
    }

    updateConversation(conversation, statusFilter, assignFilter, businessFilters);

    if (assignFilter === AssignFilterOption.AUTOMATED && !conversation.is_automated) {
        removeConversation(conversation.id, statusFilter, assignFilter, businessFilters);
        setConversationParamId('');
    }

    queryClient.invalidateQueries([MESSAGING_CONVERSATION_STATS, businessFilters]);
};
