import WHITELIST_PROVIDERS from 'app/common/data/providers';
import { EmailPasswordForm } from 'app/pages/Login/forms/MainLogin/EmailPasswordForm';
import { useLoginContext } from 'app/pages/Login/hooks/useLoginContext';
import { useProviderFromUrl } from 'app/pages/Login/hooks/useProviderFromUrl';

import { GoogleSignInButton } from './GoogleSignInButton';
import { SsoSignInButton } from './SsoSignInButton';
import { LoginFormContainer } from '../loginForm.styled';

export const MainLogin = () => {
    const provider = useProviderFromUrl();
    const { isLoading } = useLoginContext();

    return (
        <LoginFormContainer isLoading={isLoading}>
            <EmailPasswordForm />
            <GoogleSignInButton />
            {WHITELIST_PROVIDERS.includes(provider) && <SsoSignInButton />}
        </LoginFormContainer>
    );
};
