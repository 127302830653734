import { FixedTopbarProvider } from 'app/common/contexts/fixedTopbar';
import { SidebarProvider } from 'app/common/contexts/sidebar';

import { LanguageContextProvider } from 'app/common/services/getLang';
import { AppDataProvider } from 'app/pages/app/AppDataProvider/appData';

import { useHandleAxiosInterceptor } from './hooks/useHandleAxiosInterceptor';
import { AppPage } from './page';

export const AppContexts = () => {
    useHandleAxiosInterceptor();

    // Routes accessible once logged in
    return (
        <AppDataProvider>
            <LanguageContextProvider>
                <SidebarProvider>
                    <FixedTopbarProvider>
                        <AppPage />
                    </FixedTopbarProvider>
                </SidebarProvider>
            </LanguageContextProvider>
        </AppDataProvider>
    );
};
