import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';

export const useCheckLogin = (
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) => {
    return useMutation(() => api.user.getMe(), {
        onSuccess,
        onError,
    });
};
