import qs from 'query-string';
import { useQuery } from 'react-query';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import api from 'app/api/v2/api_calls';
import { StyledContent } from 'app/pages/privacyPolicy/PrivacyPolicy.styled';
import PrivacyPolicyTemplateDe from 'app/pages/privacyPolicy/templates/PrivacyPolicyTemplateDe';
import PrivacyPolicyTemplateEn from 'app/pages/privacyPolicy/templates/PrivacyPolicyTemplateEn';
import PrivacyPolicyTemplateEs from 'app/pages/privacyPolicy/templates/PrivacyPolicyTemplateEs';
import PrivacyPolicyTemplateFr from 'app/pages/privacyPolicy/templates/PrivacyPolicyTemplateFr';
import PrivacyPolicyTemplateIt from 'app/pages/privacyPolicy/templates/PrivacyPolicyTemplateIt';
import { MessagingPrivacyPolicyData, PrivacyPolicyUrlParams } from 'app/pages/privacyPolicy/types';

import { MessageLoader } from 'app/reviewManagement/messaging/MessageLoader';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

const getTransaltedPrivacyPolicy = (
    data: MessagingPrivacyPolicyData,
    lang: string | (string | null)[] | null | undefined,
) => {
    switch (lang) {
        case 'fr':
            return <PrivacyPolicyTemplateFr data={data} />;
        case 'es':
            return <PrivacyPolicyTemplateEs data={data} />;
        case 'it':
            return <PrivacyPolicyTemplateIt data={data} />;
        case 'de':
            return <PrivacyPolicyTemplateDe data={data} />;
        default:
            return <PrivacyPolicyTemplateEn data={data} />;
    }
};

export const MessagingPrivacyPolicy = () => {
    const { id } = useParams<PrivacyPolicyUrlParams>();
    const location = useLocation();
    const { lang } = qs.parse(location.search);
    const { isError, data } = useQuery(
        ['privacyPolicyInfo', id],
        () => api.privacyPolicy.getMessagingPrivacyPolicy(id),
        // Retries are prevented in order to avoid a delay for 404 redirection
        { retry: false },
    );
    if (isError) {
        return <Navigate replace to={NOT_FOUND_PATH} />;
    }

    if (data) {
        return getTransaltedPrivacyPolicy(data, lang);
    }
    return (
        <StyledContent>
            <MessageLoader />
        </StyledContent>
    );
};
