import { useAutoLogin } from 'app/pages/Login/hooks/useAutoLogin';
import { useLoginContext } from 'app/pages/Login/hooks/useLoginContext';

import { ChangePasswordModal } from './ChangePasswordModal';
import { LoginLoadingView } from './LoginLoadingView';
import { MainLogin } from './MainLogin';

const LoginView = () => {
    // Try to log in automatically
    useAutoLogin();

    const { isLoading, passwordModal } = useLoginContext();

    return (
        <>
            {/* LoginForm always in the react DOM, the div will be hidden if isLoading */}
            <MainLogin />
            {isLoading && <LoginLoadingView />}

            {<ChangePasswordModal show={passwordModal} />}
        </>
    );
};

export default LoginView;
